import { Component, OnInit, Input } from '@angular/core';
import { Department } from 'src/app/Models/department';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { DepartmentworkingDetails } from 'src/app/Models/departmentworking-details';
import { DatePipe } from '@angular/common';
import { NgForm } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.css'],
  providers: [DatePipe]
})

export class DepartmentComponent implements OnInit {
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  loadingRecords = true;
  dataList = [];
  sortValue: string = "desc";
  sortKey: string = "id";
  @Input() drawerClose: Function;
  @Input() data: Department;
  isSpinning = false
  @Input() listOfData: DepartmentworkingDetails[] = [];
  OPEN_TIME
  CLOSE_TIME
  @Input() OPEN_TIME2
  @Input() CLOSE_TIME2
  fKey = ""
  listdata2 = []
  listdata1 = []
  date = new Date();
  date1 = this.datePipe.transform(this.date, 'yyyyMMddHHmmss')
  fileDataLOGO_URL: File = null
  folderName = "departmentIcon"
  @Input() DAYS = false;
  isOk = true;
  regexp = /^\S/
  spatt = /^[a-zA-Z0-9 ]+$/
  org = [];
  orgId = this.cookie.get('orgId');

  constructor(private api: ApiService, private datePipe: DatePipe, private message: NzNotificationService, private cookie: CookieService) {
  }

  ngOnInit() {
    this.CreateData();
    this.getallorg1();

    this.api.getAllOrganizations(this.pageIndex, this.pageSize, this.sortKey, '', '').subscribe(data => {
      this.loadingRecords = false;
      this.org = data['data'];

    }, err => {
      console.log(err);
    });
  }

  close(accountMasterPage: NgForm) {
    this.drawerClose();
    this.resetDrawer(accountMasterPage);
  }

  resetDrawer(accountMasterPage: NgForm) {
    accountMasterPage.form.reset();
    this.add();
  }

  add(): void {
    this.api.getAllDepartments(1, 1, 'SEQUENCE_NO', 'desc', ' AND  ORG_ID =' + this.orgId).subscribe(data => {
      if (data['count'] == 0) {
        this.data.SEQUENCE_NO = 1;

      } else {
        this.data.SEQUENCE_NO = Number(data['data'][0]['SEQUENCE_NO']) + 1;
        this.data.STATUS = true;
      }

    }, err => {
      console.log(err);
    })
  }

  departments() {
    this.api.getAllDepartments(this.pageIndex, this.pageSize, this.sortKey, '', ' and STATUS=1').subscribe(data => {
      console.log(data['data'])
      this.loadingRecords = false;
      this.dataList = data['data'];

    }, err => {
      console.log(err);
    });
  }

  setAllDays(event) {
    this.DAYS = event;
  }

  setOpenTime(event) {
    this.OPEN_TIME2 = event;
    console.log(this.OPEN_TIME2);
    for (let i = 0; i < 7; i++) {
      if (this.listOfData[i]['IS_HOLIDAY'] == false) {
        this.listOfData[i]['OPEN_TIME'] = this.OPEN_TIME2;
        this.listOfData[i]['CLOSE_TIME'] = this.CLOSE_TIME2;
      }
      if (this.listOfData[i]['IS_HOLIDAY'] == true) {
        this.listOfData[i]['OPEN_TIME'] = this.OPEN_TIME;
        this.listOfData[i]['CLOSE_TIME'] = this.CLOSE_TIME;
      }
    }
  }

  setCloseTime(event) {
    this.CLOSE_TIME2 = event;
    for (let i = 0; i < 7; i++) {
      if (this.listOfData[i]['IS_HOLIDAY'] == false) {
        this.listOfData[i]['OPEN_TIME'] = this.OPEN_TIME2;
        this.listOfData[i]['CLOSE_TIME'] = this.CLOSE_TIME2;
      }
      if (this.listOfData[i]['IS_HOLIDAY'] == true) {
        this.listOfData[i]['OPEN_TIME'] = this.OPEN_TIME;
        this.listOfData[i]['CLOSE_TIME'] = this.CLOSE_TIME;
      }
    }
  }

  CreateData() {
    for (let i = 0; i < 7; i++) {
      this.listOfData.push({
        ID: 0,
        DAY: i,
        IS_HOLIDAY: false,
        OPEN_TIME: this.OPEN_TIME,
        CLIENT_ID: this.api.clientId,
        DEPARTMENT_ID: 0,
        DATE: "",
        CLOSE_TIME: this.CLOSE_TIME
      });
    }
  }

  getDay(day: number) {
    switch (day) {
      case 0:
        return "Sunday"
        break;
      case 1:
        return "Monday"
        break;
      case 2:
        return "Tuesday"
        break;
      case 3:
        return "Wednesday"
        break;
      case 4:
        return "Thursday"
        break;
      case 5:
        return "Friday"
        break;
      case 6:
        return "Saturday"
        break;
    }
  }

  onFileSelectedLOGO_URL(event) {
    this.fileDataLOGO_URL = <File>event.target.files[0];
    console.log(this.fileDataLOGO_URL)
    var fileExt = this.fileDataLOGO_URL.name.split('.').pop();
  }

  getallorg1() {
    this.api.getAllDepartments(0, 0, 'ID', 'desc', "AND ORG_ID =" + this.orgId).subscribe(data => {
      if (data['code'] == 200) {
        this.listdata1 = data['data'];
        console.log(this.listdata1);
      }

    }, err => {
      console.log(err);
    });
  }

  getallorg2(deptID) {
    this.api.getAllDepartments(0, 0, 'ID', 'desc', " AND ORG_ID =" + this.orgId + " AND ID!=" + deptID).subscribe(data => {
      if (data['code'] == 200) {
        this.listdata2 = data['data'];
        console.log(this.listdata2);
      }

    }, err => {
      console.log(err);
    });
  }

  save(addNew: boolean, accountMasterPage: NgForm): void {
    this.isOk = true;
    this.data.ORG_ID = Number(this.cookie.get('orgId'));

    if (this.data.NAME != undefined) {
      if (this.data.NAME.trim() != '') {
        if (!this.api.checkTextBoxIsValid(this.data.NAME)) {
          this.isOk = false;
          this.message.error("Please Enter Valid Name", "");
        }

      } else {
        this.isOk = false;
        this.message.error("Please Enter Valid Name", "");
      }

    } else {
      this.isOk = false;
      this.message.error("Please Enter Valid Name", "");
    }

    if (this.data.SHORT_CODE == null || this.data.SHORT_CODE.trim() == '') {
      this.isOk = false;
      this.message.error("Please Enter Short Code", '');

    } else if (!this.spatt.test(this.data.SHORT_CODE.toString())) {
      this.isOk = false;
      this.message.error("please Enter Valid Short Code", '');

    } else if (this.isOk) {
      this.isSpinning = true;
      this.orgId = this.cookie.get('orgId');

      if (this.data.ID) {
        var emailData2 = this.listdata2.filter(obj => {
          return obj.SHORT_CODE == this.data.SHORT_CODE;
        });

        if (emailData2.length == 0) {
          this.api.updateDepartment(this.data).subscribe(successCode => {
            if (successCode['code'] == 200) {
              this.message.success("Department Information Updated Successfully", "");

              if (!addNew)
                this.drawerClose();

              this.resetDrawer(accountMasterPage);
              this.isSpinning = false;

            } else {
              this.message.error("Department Information Updation Failed", "");
              this.isSpinning = false;
            }
          });

        } else {
          this.message.error("Shortcode Exist Please Enter Other Shortcode", "");
          this.isSpinning = false;
        }

      } else {
        var emailData = this.listdata1.filter(obj => {
          return obj.SHORT_CODE == this.data.SHORT_CODE;
        });

        if (emailData.length == 0) {
          this.api.createDepartment(this.data).subscribe(successCode => {
            if (successCode['code'] == 200) {
              this.isSpinning = false;
              this.message.success("Department Information Created Successfully", "");
              this.departments();

              if (!addNew) {
                this.drawerClose();
                this.resetDrawer(accountMasterPage);

              } else {
                this.data = new Department();
                this.resetDrawer(accountMasterPage);
              }

            } else {
              this.message.error("Department Information Creation Failed", "");
              this.isSpinning = false;
            }
          });

        } else {
          this.message.error("Shortcode Exist Please Enter Other Shortcode", "");
          this.isSpinning = false;
        }
      }
    }
  }
}
