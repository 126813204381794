import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HolidayMaster } from 'src/app/Models/holiday-master';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';

@Component({
  selector: 'app-holidays',
  templateUrl: './holidays.component.html',
  styleUrls: ['./holidays.component.css'],
  providers: [DatePipe]
})

export class HolidaysComponent implements OnInit {
  formTitle = "Manage Holiday";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["DATE", "Date"], ["IS_HOLIDAY", "Holiday Status"], ["REASON", "Reason"]]

  monthFormat = "MMM-yyyy";
  today = new Date().getFullYear().toString() + "-" + (new Date().getMonth() + 1).toString()
  filterClass: string = "filter-visible";
  initFilter = true;
  month = this.today;
  isSpinning = false;

  data: HolidayMaster = new HolidayMaster();

  constructor(private api: ApiService, private datePipe: DatePipe, private message: NzNotificationService) { }

  ngOnInit() {
    this.search();

    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    console.log("search text:" + this.searchText);

    if (this.searchText != "") {
      this.filterQuery += " AND (";
      var likeQuery = " ";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like ('%" + this.searchText + "%') OR";
      });

      this.filterQuery += likeQuery.substring(0, likeQuery.length - 3) + " ) ";

    } else {
      this.filterQuery = ""

      if (this.isFilterApplied == "primary")
        this.applyFilter();
    }

    this.api.getAllHolidays(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  applyFilter() {
    this.isSpinning = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    if (this.month == undefined) {
      this.message.error("Please Select Month", "");

    } else {
      this.month = this.datePipe.transform(this.month, "yyyy-MM-dd");
      var splitmonth = this.month.split("-", -1);
      this.filterQuery = " AND MONTH(DATE) = '" + splitmonth[1] + "' AND YEAR(DATE) = '" + splitmonth[0] + "' AND ";

      this.filterQuery = this.filterQuery.substring(0, this.filterQuery.length - 5);
      console.log(this.month.split("-", -1));
      console.log(this.filterQuery);

      this.isSpinning = true;
      this.api.getAllHolidays(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery).subscribe(data => {
        if (data['code'] == 200) {
          console.log(data);
          this.loadingRecords = false;
          this.isFilterApplied = "primary";
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.filterClass = "filter-invisible";
          this.isSpinning = false;
        }

      }, err => {
        console.log(err);
      });

      this.filterClass = "filter-invisible";
    }
  }

  clearFilter() {
    this.month = this.today;

    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.filterQuery = "";

    this.search();
  }

  change(data): void {
    console.log("data :  " + JSON.stringify(data));

    this.api.updateHoliday(data).subscribe(successCode => {
    }, errorCode => {
      console.log("error");
    });
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
      
    else
      this.filterClass = "filter-visible";
  }
}
