import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { Expenses } from 'src/app/Models/Expenses';
import { ApiService } from 'src/app/Service/api.service';
import { differenceInCalendarDays, setHours } from 'date-fns';

@Component({
  selector: 'app-head-expenses-approve-drawer',
  templateUrl: './head-expenses-approve-drawer.component.html',
  styleUrls: ['./head-expenses-approve-drawer.component.css']
})

export class HeadExpensesApproveDrawerComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: Expenses;
  @Input() drawerVisible: boolean;
  @Input() splitData1 = [];
  @Input() splitData2 = [];
  isSpinning = false;

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private cookie: CookieService) { }

  expenseTypes = [];
  customers = [];
  employees = [];

  userId = Number(this.cookie.get('userId'));
  userName = String(this.cookie.get('userName'));
  roleId = Number(this.cookie.get('roleId'));
  orgId = Number(this.cookie.get('orgId'));
  deptId = Number(this.cookie.get('deptId'));
  branchId = Number(this.cookie.get('branchId'));
  designationId = Number(this.cookie.get('designationId'));

  ngOnInit() {
    this.splitData1 = [];
    this.splitData2 = [];

    this.getExpenseHead();
    this.getCustomers();
    this.getEmployees();
    this.PEOPLES_ID = [];
    this.createTable(null);
  }

  getExpenseHead() {
    this.expenseTypes = [];

    this.api.getAllExpenseHead(0, 0, '', '', ' and IS_ACTIVE=1').subscribe(data => {
      if (data['code'] == 200) {
        this.expenseTypes = data['data'];
        console.log(this.expenseTypes);
      }

    }, err => {
      console.log(err);
    });
  }

  getCustomers() {
    this.customers = [];
    var filter = ' and ID in (select COLLEGE_ID from employee_college_mapping where employee_id=' + this.userId + ' and IS_ALLOWED=1)';

    this.api.getAllCustomers(0, 0, 'NAME', 'asc', filter).subscribe(data => {
      if (data['code'] == 200) {
        this.customers = data['data'];
        console.log(this.customers);
      }

    }, err => {
      console.log(err);
    });
  }

  getEmployees() {
    this.employees = [];

    this.api.getAllEmployeesSplitBill(this.orgId, this.userId, this.designationId).subscribe(data => {
      if (data['code'] == 200) {
        this.employees = data['data'];
        console.log(this.employees);
      }

    }, err => {
      console.log(err);
    });
  }

  empList: any = [];

  onEmployeeSelected(empID: any) {
    this.createTable(empID);
  }

  createTable(empID) {
    this.empList = [];
    var arry = [];
    var currentEmp = new Object();

    currentEmp['ID'] = this.userId;
    currentEmp['NAME'] = this.userName;
    currentEmp['DEPARTMENT_ID'] = this.deptId;
    currentEmp["AMT"] = 0;

    if (empID != null) {
      for (var i = 0; i < empID.length; i++) {
        var empData = this.employees.filter(data => {
          if (data.ID == empID[i]) {
            data["AMT"] = 0;
            arry.push(data);
          }
        });
      }

      this.empList = arry;
      console.log(this.empList);
    }

    this.empList.push(Object.assign({}, currentEmp));

    for (var j = 0; j < this.empList.length; j++) {
      if (this.data.AMOUNT != undefined && this.data.AMOUNT != null) {
        this.empList[j]["AMT"] = parseFloat(String(Number(this.data.AMOUNT) / (this.empList.length))).toFixed(2);
      }
    }
  }

  onAmtChange(amt) {
    this.data.AMOUNT = amt;
    this.PEOPLES_ID = [];
    this.createTable(null);
  }

  close(myForm: NgForm): void {
    this.drawerClose();
    this.reset(myForm);
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  PEOPLES_ID = [];

  save(addNew: boolean, myForm: NgForm, status: string): void {
    var isOk = true;

    if (status == 'R') {
      if (this.REMARK == undefined || this.REMARK.trim() == "") {
        isOk = false;
        this.message.error("Please Enter Valid Remark", "");
      }
    }

    if (isOk) {
      this.isSpinning = true;

      this.splitData2.map(item => {
        item["AMT"] = 0;
      });

      if (this.REMARK == undefined || this.REMARK.trim() == "")
        this.REMARK = "No Remark";

      this.data.STATUS = status;
      this.data.HEAD_STATUS = status;
      this.data.APPROVER_ID = this.userId;
      this.data.HEAD_REMARK = this.REMARK;
      this.data.HEAD_TIME = this.datePipe.transform(new Date(), "yyyy-MM-dd HH:mm:ss");
      this.data.DETAILS = this.splitData2;

      console.log(this.data);

      if (this.data.ID) {
        this.api.updateEmployeeExpenses(this.data).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Expenses Details Saved Successfully", "");

            if (!addNew)
              this.close(myForm);
            this.isSpinning = false;

          } else {
            this.message.error("Failed to Save Expenses Details", "");
            this.isSpinning = false;
          }
        });
      }
    }
  }

  fileURL: File = null;
  photoURL: any;

  onFileSelected(event) {
    console.log(event.target.files[0].type);
    console.log(Number(parseFloat(String((event.target.files[0].size / 1024) / 1024)).toFixed(0)));

    if (event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/png") {
      if (Number(parseFloat(String((event.target.files[0].size / 1024) / 1024)).toFixed(0)) < 2) {
        this.fileURL = <File>event.target.files[0];

      } else {
        this.fileURL = null;
        this.message.error("File size Must be Less than or Equal to '2 MB'", "");
      }

    } else {
      this.fileURL = null;
      this.message.error("File Type Must be JPEG/ JPG/ PNG", "");
    }
  }

  clear() {
    this.fileURL = null;
  }

  today = new Date().setDate(new Date().getDate());

  disabledStartDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) > 0;

  folderName = "expenses"
  uploadedAttachmentStr: string;

  imageUpload() {
    this.uploadedAttachmentStr = "";

    if (this.fileURL) {
      var number = Math.floor(100000 + Math.random() * 900000);
      var fileExt = this.fileURL.name.split('.').pop();
      var url = "E" + number + "." + fileExt;

      this.api.onUpload2(this.folderName, this.fileURL, url).subscribe(res => {
        if (res["code"] == 200) {
          console.log("Uploaded");

        } else {
          console.log("Not Uploaded");
        }
      });

      this.uploadedAttachmentStr = this.api.retriveimgUrl + "expenses/" + url;

    } else {
      this.uploadedAttachmentStr = "";
    }
  }

  omit(event: any) {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }

  getInitial(empName) {
    let initial: string = empName.charAt(0);
    return initial.trim();
  }

  APPROVE: boolean = true;
  REMARK: string;
  isSplitTableLoading: boolean = false;

  onImageSelected(photoURL) {
    window.open(photoURL);
  }
}
