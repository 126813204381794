import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Branchmaster } from 'src/app/Models/Branchmaster';
import { ApiService } from 'src/app/Service/api.service';
import { OrganizationMaster } from '../../../Models/organization-master';
import { EmmService } from '../../../Service/emm.service';

@Component({
  selector: 'app-branches',
  templateUrl: './branches.component.html',
  styleUrls: ['./branches.component.css']
})

export class BranchesComponent implements OnInit {
  formTitle = "Manage Branches";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["NAME", "Branch Name"], ["CLUSTER_NAME", "Cluster Name"], ["CITY", "City"], ["TEHSIL", "Tehsil"], ["DISTRICT", "District"], ["STATE", "State"], ["COUNTRY", "Country"]];
  columns1: string[][] = [["NAME", "Branch Name"], ["CLUSTER_NAME", "Cluster Name"], ["CITY", "City"], ["TEHSIL", "Tehsil"], ["DISTRICT", "District"], ["STATE", "State"], ["COUNTRY", "Country"], ["PINCODE", "Pincode"], ["SEQUENCE_NO", "Sequence no"]];
  time = new Date()
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Branchmaster = new Branchmaster();

  constructor(private api: ApiService, private cookie: CookieService,) { }

  ngOnInit() {
    this.search();
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "id";
      this.sortValue = "descend";
    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns1.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')'
      console.log("likeQuery" + likeQuery);
    }

    if (likeQuery)
      filter += likeQuery

    this.api.getAllBranch(this.pageIndex, this.pageSize, this.sortKey, sort, filter + ' AND  ORG_ID =' + this.cookie.get('orgId')).subscribe(data => {
      console.log(data['data'])
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];

    }, err => {
      console.log(err);
    });
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  add(): void {
    this.drawerTitle = "Create New Branch";
    this.drawerData = new Branchmaster();
    this.drawerData.ORG_ID = Number(this.cookie.get('orgId'));
    this.drawerData.STATUS = true;

    this.api.getAllBranch(1, 1, 'SEQUENCE_NO', 'desc', '' + ' AND  ORG_ID =' + this.cookie.get('orgId')).subscribe(data => {
      if (data['count'] == 0) {
        this.drawerData.SEQUENCE_NO = 1;

      } else {
        this.drawerData.SEQUENCE_NO = data['data'][0]['SEQUENCE_NO'] + 1;
      }

    }, err => {
      console.log(err);
    })

    this.drawerVisible = true;
  }

  edit(data: Branchmaster): void {
    console.log(data);
    this.drawerTitle = "Update Branch Details";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }
}
