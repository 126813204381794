import { Component, OnInit } from '@angular/core';
import { AnnualTrainingPoint } from 'src/app/Models/annualtrainingpoint';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-annualtrainpointlist',
  templateUrl: './annualtrainpointlist.component.html',
  styleUrls: ['./annualtrainpointlist.component.css']
})

export class AnnualtrainpointlistComponent implements OnInit {
  formTitle = "Manage Annual Training Plan";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["SUBJECT", "Subject Name"], ["MONTH", "Month"], ["YEAR", "Year"], ["PERIOD", "Period(Hours)"]]
  traintrainermap: any = [{ SCHEDULE_ID: 1, TRAINER_ID: 1, IS_ACTIVE: 0 }];
  scheduleId = 0;
  drawerVisible!: boolean;
  drawerTitle!: string;
  drawerData: AnnualTrainingPoint = new AnnualTrainingPoint();
  drawerVisible1: boolean = false;
  drawerTitle1: string = '';
  drawerData1: AnnualTrainingPoint = new AnnualTrainingPoint();
  drawerData2: string[] = [];

  constructor(private api: ApiService) { }

  ngOnInit() {
    //this.traintrainermap = [];
    // this.api.trainingTrainerMapping(Number(this.scheduleId)).subscribe(data => {
    //   if (data['code'] == '200') {
    //     this.traintrainermap = Object.assign([], data['data']);
    //   } else {
    //     // this.message.error('Something Went wrong.','')
    //   }
    // }, err => {
    //   // this.message.error('Something Went wrong.','')
    // });
    this.search();
  }

  keyup(event: any) {
    this.search();
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "id";
      this.sortValue = "desc"
    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      console.log("likeQuery" + likeQuery);
    }

    this.api.getAllannualTraniningPoints(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
      
    }, err => {
      console.log(err);
    });
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  add(): void {
    this.drawerTitle = "Create New Annual Training Plan";
    this.drawerData = new AnnualTrainingPoint();
    this.drawerVisible = true;
  }

  edit(data: AnnualTrainingPoint): void {
    this.drawerTitle = "Update Annual Training Plan";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }

  drawerClose1(): void {
    this.drawerVisible1 = false;
  }
}