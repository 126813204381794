export class PayrollItemMaster {
    ID:number;
    CLIENT_ID:number;
    NAME:string="";
    PARENT_ID:number
    TYPE:any
    AMOUNT:number
    BASED_ON:number
    STATUS:boolean=true;
    IS_ACTIVE:boolean=true;
    PERCENT:number;

}
