import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';
import { Ticketgroup } from 'src/app/Models/ticketgroup';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-dashboardreport',
  templateUrl: './dashboardreport.component.html',
  styleUrls: ['./dashboardreport.component.css'],
  providers: [DatePipe]
})
export class DashboardreportComponent implements OnInit {
  formTitle = "Dashboard";
  screenwidth = 800
  dataList = [];
  dataList2 = [];
  dataList4 = [];
  userId = this.cookie.get('userId')
  roleId = this.cookie.get('roleId')

  DEPARTMENT = 'AL';
  isSpinning = false
  applicationId = 1
  departmentId = Number(this.cookie.get('departmentId'))
  departments = []
  filterQuery = ""
  TOTAL = 0
  OPENED = 0
  PENDING = 0
  ANSWERED = 0
  CLIENT_REPLIED = 0
  CLOSED = 0
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  departmentwise = [];
  columns: string[][] = [["DEPARTMENT_NAME", "Department"], ["TOTAL", "Total"], ["OPENED", "Open"],
  ["CLOSED", "Closed"]];
  loadingRecords = true;
  loadingRecords3 = true;
  loadingRecords4 = true;
  sortValue: string = "desc";
  sortKey: string = "ID";

  pageIndex2 = 1;
  pageSize2 = 10;
  totalRecords2 = 1;
  columns2: string[][] = [["USER_NAME", "User Name"], ["TOTAL", "Total"], ["OPENED", "Open"],
  ["CLOSED", "Closed"]];
  sortValue2: string = "desc";
  sortKey2: string = "ID";
  userwiselist = [];
  selectedDate: Date[] = []
  dateFormat = 'dd/MM/yyyy';
  value1: string = ""
  value2: string = ""
  pageIndex3 = 1;
  pageSize3 = 10;
  totalRecords3 = 1;
  columns3: string[][] = [["TICKET_NO", "Ticket No"], ["DEPARTMENT_NAME", "Department"], ["MOBILE_NO", "Mobile No"], ["EMAIL_ID", "Email Id"], ["QUESTION", "Question"], ["IS_TAKEN_STATUS", "Is Taken"], ["DATE", "Created On"], ["LAST_RESPONDED", "Last Responded Date"], ['PRIORITY', 'Priority'], ["STATUS", "Status"]]
  
  pageIndex4 = 1;
  pageSize4 = 10;
  totalRecords4 = 1;
  columns4: string[][] = [["DATE", "Date & Time"], ["ACTION","Action"]]

  ticketData = [];
  ticketNO = ''
  searchLoading = false;

  HIGH = 0
  MEDIUM = 0
  LOW = 0
  CREATED = 0
  ASSIGNED = 0
  ANSWERED1 = 0
  REOPEN = 0
  CLOSED1 = 0
  ONHOLD = 0
  TOTALP = 0
  TOTALS = 0
  BANNED = 0

  constructor(private api: ApiService, private datePipe: DatePipe, private cookie: CookieService) { }

  ngOnInit() {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    this.selectedDate[0]= firstDay
    this.selectedDate[1]= lastDay

    this.value1 = this.datePipe.transform(this.selectedDate[0], "yyyy-MM-dd")
    this.value2 = this.datePipe.transform(this.selectedDate[1], "yyyy-MM-dd");

    this.applyFilter()
    // if (this.userId == '6')
      this.api.getAllDepartments(0, 0, 'NAME', 'ASC', '').subscribe(data => {
        this.departments = data['data'];
      }, err => {
        console.log(err);
      });
    // if (this.roleId == '6')
      this.getDepartmentwise(true);

    // if (this.roleId == '11')
      this.getUserwise(true);

    this.applyFilter2()
     
  }

  changeDate(value) {
    this.selectedDate = value;
    if(value == undefined || value == null || value.length == 0){
      this.value1 = '';
      this.value2 = ''
    }else{
      this.value1 = this.datePipe.transform(value[0], "yyyy-MM-dd")
      this.value2 = this.datePipe.transform(value[1], "yyyy-MM-dd");
    }
    
  }

  //ticket search

searchTickets() {
  this.searchLoading = true;
  this.ticketData = []
  this.api.getAllTickets(0, 0, 'TICKET_NO', 'DESC', " AND TICKET_NO = '" + this.ticketNO + "'").subscribe(data => {
    if (data['code'] == '200') {
      this.ticketData = data['data'];
    }
    this.searchLoading = false;
  }, err => {
    console.log(err);
  });
}
//ticketserachend

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.getDepartmentwise(true);
  }
  sort2(sort: { key: string; value: string }): void {
    this.sortKey2 = sort.key;
    this.sortValue2 = sort.value;
    this.getUserwise(true);
  }
  getDepartmentwise(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    this.api.getDepartmentwiseReport(this.pageIndex, this.pageSize, this.sortKey, sort, '', this.applicationId, 0).subscribe(data => {
      console.log(data)
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.departmentwise = data['data'];
    }, err => {
      console.log(err);
    });
  }
  getUserwise(reset: boolean = false) {
    if (reset) {
      this.pageIndex2 = 1;
    }
    this.loadingRecords = true;
    var sort2: string;
    try {
      sort2 = this.sortValue2.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort2 = "";
    }
    this.api.getUserwiseReport(this.pageIndex2, this.pageSize2, this.sortKey2, sort2, '', this.applicationId, this.departmentId).subscribe(data => {
      console.log(data)
      this.loadingRecords = false;
      this.totalRecords2 = data['count'];
      this.userwiselist = data['data'];

    }, err => {
      console.log(err);
    });
  }
  applyFilter2() {
    

    if (this.roleId == "6")
      if (this.DEPARTMENT == 'AL') {
        this.filterQuery = this.filterQuery
      }
      else {
        this.filterQuery += " AND DEPARTMENT_ID='" + this.DEPARTMENT + "'"
      }

    var filterQuery = this.filterQuery +   " AND ( TAKEN_BY_USER_ID = 0 OR STATUS in ('P','R') ) "
    if (this.roleId == "15") {
      filterQuery = filterQuery + " AND TAKEN_BY_USER_ID=" + this.userId + " AND DEPARTMENT_ID=" + this.departmentId;
    } else if (this.roleId == "11") {
      filterQuery = filterQuery + " AND DEPARTMENT_ID=" + this.departmentId;
    } else if (this.roleId == "6") {
      filterQuery = filterQuery
    }

    this.api.getAllTickets(0, 0, 'DATEDIFF(CURRENT_TIMESTAMP,LAST_RESPONDED)', 'DESC', filterQuery).subscribe(data => {
   
      this.loadingRecords3 = false;
      this.totalRecords3 = data['count'];
      this.dataList2 = data['data']; 
    }, err => {
      console.log(err);
    });
  }

//   this.api.getAllActivity(0, 0, 'DATEDIFF(CURRENT_TIMESTAMP,LAST_RESPONDED)', 'DESC', filterQuery).subscribe(data => {
   
//     this.loadingRecords4 = false;
//     this.totalRecords4 = data['count'];
//     this.dataList4 = data['data']; 
//   }, err => {
//     console.log(err);
//   });
// }

  calcuLate(data) {
    this.TOTAL = data['data'][0]['TOTAL']
    this.OPENED = data['data'][0]['OPENED']
    this.PENDING = data['data'][0]['PENDING']
    this.ANSWERED = data['data'][0]['ANSWERED']
    this.CLIENT_REPLIED = data['data'][0]['CLIENT_REPLIED']
    this.CLOSED = data['data'][0]['CLOSED']
  }

  setDepartment(event) {
    this.DEPARTMENT = event;
    this.applyFilter()
  }

  applyFilter() {
    this.isSpinning = true;
    var DEPARTMENT = 0
    if (this.userId == '6') {

      if (this.DEPARTMENT == 'AL') {
        DEPARTMENT = 0
      }
      else {
        DEPARTMENT = Number(this.DEPARTMENT);
      }
    } else {
      DEPARTMENT = Number(this.departmentId);
    }

    var USER_ID = 0
    if (this.roleId != '1') {
      USER_ID = Number(this.userId);
    } else {
      USER_ID = 1
    }

    if(this.selectedDate == undefined || this.selectedDate == null || this.selectedDate.length == 0){
      this.value1 = '';
      this.value2 = ''
    }else{
      this.value1 = this.datePipe.transform(this.selectedDate[0], "yyyy-MM-dd")
      this.value2 = this.datePipe.transform(this.selectedDate[1], "yyyy-MM-dd");
    }

    this.api.getDashboardReport(this.applicationId.toString(), DEPARTMENT, USER_ID,this.value1,this.value2).subscribe(data => {
      this.isSpinning = false;
      if (data['code'] == 200) {
        this.TOTAL = data['data'][0]['TOTAL']
        this.OPENED = data['data'][0]['OPENED']
        this.PENDING = data['data'][0]['PENDING']
        this.ANSWERED = data['data'][0]['ANSWERED']
        this.CLIENT_REPLIED = data['data'][0]['CLIENT_REPLIED']
        this.CLOSED = data['data'][0]['CLOSED']
      }

    }, err => {
      console.log(err);
    });
  }

  applyFilter4(){

  }

}


