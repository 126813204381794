import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';
import { Ticketgroup } from 'src/app/Models/ticketgroup';
import { DatePipe } from '@angular/common';
import { Ticket } from 'src/app/Models/ticket';
import * as XLSX from 'xlsx';
import { ChattdetailsicketComponent } from '../chattdetailsicket/chattdetailsicket.component';

@Component({
  selector: 'app-ticketreport',
  templateUrl: './ticketreport.component.html',
  styleUrls: ['./ticketreport.component.css'],
  providers: [DatePipe]
})
export class TicketreportComponent implements OnInit {
  formTitle = "Tickets Report";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  userId = this.cookie.get('userId')
  roleId = this.cookie.get('roleId')
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["TICKET_NO", "Ticket No"], ["DEPARTMENT_NAME", "Department"], ["MOBILE_NO", "Mobile No"], ["EMAIL_ID", "Email Id"], ["QUESTION", "Question"], ["IS_TAKEN_STATUS", "Is Taken"], ["DATE", "Created On"], ["LAST_RESPONDED", "Last Responded Date"], ['PRIORITY', 'Priority'], ["STATUS", "Status"]]
  STATUS = "AL";
  DEPARTMENT = 'AL';
  SUPPORT_USER = 'AL';
  PRIORITY = 'AL';

  employeelist: any = [];
  EMPLOYEE: string = 'AL';

  isSpinning = false
  filterClass: string = "filter-visible";
  applicationId = Number(this.cookie.get('applicationId'))
  departmentId = Number(this.cookie.get('departmentId'))

  selectedDate: Date[] = []
  dateFormat = 'dd/MM/yyyy';

  data1 = [];
  ticketGroups: Ticketgroup[] = []
  index = 0;
  ticketQuestion = {};
  value1: string = ""
  value2: string = ""
  departments = []
  fileName= 'Tickets.xlsx';
  supportusers = [];
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Ticket = new Ticket();
  uniqueDateArry = [];
  newData2 = [];
  @ViewChild(ChattdetailsicketComponent, { static: false }) ViewchatticketComponentVar: ChattdetailsicketComponent;

  constructor(private api: ApiService, private datePipe: DatePipe, private cookie: CookieService) { }

  ngOnInit() {
    this.search();


    this.api.getAllDepartments(0, 0, 'NAME', 'ASC', ' AND ORG_ID  = ' + this.cookie.get('orgId')).subscribe(data => {
      this.departments = data['data'];
    }, err => {
      console.log(err);
    });
    var data2:any[] = []
    this.api.getAllEmpRoleMap(0, 0, '', '', ' AND ROLE_ID= 4').subscribe(data => {
      data2 = []
      if (data['code'] == 200) {
        for (var i = 0; i < data['data'].length; i++) {
          data2.push(data['data'][i]['EMPLOYEE_ID'])
          if (data['data'].length == i + 1) {
            if (data2.length > 0)
              this.api.getAllemployeeMaster(0, 0, '', '', ' AND ORG_ID  = ' + this.cookie.get('orgId') + ' AND ID in(' + data2.toString() + ')').subscribe(data => {

                this.supportusers = data['data'];
              }, err => {
                console.log(err);
              });
          }
        }

      }
    });
    this.api.getAllemployeeMaster(0, 0, '', '', ' AND ORG_ID  = ' + this.cookie.get('orgId')).subscribe(data => {

      this.employeelist = data['data'];
    }, err => {
      console.log(err);
    });

  }
  exportexcel(): void
  {
    // pass here the table id /
    let element = document.getElementById('summer');
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
 
    // generate workbook and add the worksheet /
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
 
   // save to file /  
    XLSX.writeFile(wb, this.fileName);
 
  }  
  getUsers(event) {
    this.DEPARTMENT = event;
    var filterQuery = ""
    if (this.DEPARTMENT == 'AL') {
      filterQuery = "  AND ROLE_ID= 4"
    }
    else {
      filterQuery = "  AND ROLE_ID= 4  AND DEPARTMENT_ID=" + this.DEPARTMENT;
    }
    this.supportusers = [];
    this.SUPPORT_USER = 'AL'
     var data2:any[] = []
    this.api.getAllEmpRoleMap(0, 0, '', '', ' AND ROLE_ID= 4').subscribe(data => {
      data2 = []
      if (data['code'] == 200) {
        for (var i = 0; i < data['data'].length; i++) {
          data2.push(data['data'][i]['EMPLOYEE_ID'])
          if (data['data'].length == i + 1) {
            if (data2.length > 0)
              this.api.getAllemployeeMaster(0, 0, '', '', ' AND ORG_ID  = ' + this.cookie.get('orgId') + ' AND ID in(' + data2.toString() + ')').subscribe(data => {

                this.supportusers = data['data'];
              }, err => {
                console.log(err);
              });
          }
        }

      }
    });
  }
  // Basic Methods
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }
  clearFilter() {
    this.STATUS = "AL";
    this.DEPARTMENT = "AL";
    this.SUPPORT_USER = "AL";
    this.PRIORITY = "AL";
    this.EMPLOYEE = 'AL';
    this.filterQuery = ""
    this.selectedDate = null;
    this.value1 = ''
    this.value2 = ''
    this.filterClass = "filter-invisible";
    this.applyFilter()
  }

  changeDate(value) {
    this.value1 = this.datePipe.transform(value[0], "yyyy-MM-dd")
    this.value2 = this.datePipe.transform(value[1], "yyyy-MM-dd");
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")"

      var filterQuery = this.filterQuery + likeQuery

      // console.log(filterQuery)
      // if (this.roleId == "15") {
      //   filterQuery = filterQuery + " AND TAKEN_BY_USER_ID=" + this.userId + " AND DEPARTMENT_ID=" + this.departmentId;
      // } else if (this.roleId == "11") {
      //   filterQuery = filterQuery + " AND DEPARTMENT_ID=" + this.departmentId;
      // } else if (this.roleId == "6") {
      //   filterQuery = filterQuery;
      // }
      this.api.getAllTickets(this.pageIndex, this.pageSize, this.sortKey, sort, filterQuery + ' AND  ORG_ID =' + this.cookie.get('orgId')).subscribe(data => {
        console.log(data)
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }, err => {
        console.log(err);
      });
    }
    else {
      this.applyFilter()
    }
  }

  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";

  }

  applyFilter() {
    this.isSpinning = true
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    this.filterQuery = ""
    //var filterQuery="AND IS_TAKEN='0' AND STATUS = '"+this.STATUS+"' AND APPLICATION_ID="+this.applicationId + " AND DEPARTMENT_ID="+this.departmentId
    if (this.selectedDate == undefined || this.selectedDate.length == 0) {
      this.filterQuery = ""
    }
    else {
      this.filterQuery = " AND ( DATE BETWEEN '" + this.value1 + ":00:00:00" + "' AND '" + this.value2 + ":23:59:59" + "' ) "
    }


    if (this.STATUS == 'AL') {
      this.filterQuery = this.filterQuery
    }
    else {
      this.filterQuery += " AND STATUS='" + this.STATUS + "'"
    }

    if (this.PRIORITY == 'AL') {
      this.filterQuery = this.filterQuery
    }
    else {
      this.filterQuery += " AND PRIORITY='" + this.PRIORITY + "'"
    }

    
      if (this.SUPPORT_USER == 'AL') {
        this.filterQuery = this.filterQuery
      }
      else {
        this.filterQuery += " AND TAKEN_BY_USER_ID='" + this.SUPPORT_USER + "'"
      }

   
      if (this.DEPARTMENT == 'AL') {
        this.filterQuery = this.filterQuery
      }
      else {
        this.filterQuery += " AND DEPARTMENT_ID='" + this.DEPARTMENT + "'"
      }

    if (this.EMPLOYEE == 'AL') {
      this.filterQuery = this.filterQuery
    }
    else {  
      this.filterQuery += " AND USER_ID='" + this.EMPLOYEE+"'"
    }


    var filterQuery = this.filterQuery
    if (this.roleId == "15") {
      filterQuery = filterQuery + " AND TAKEN_BY_USER_ID=" + this.userId + " AND DEPARTMENT_ID=" + this.departmentId;
    } else if (this.roleId == "11") {
      filterQuery = filterQuery + " AND DEPARTMENT_ID=" + this.departmentId;
    } else if (this.roleId == "6") {
      filterQuery = filterQuery
    }

    this.api.getAllTickets(this.pageIndex, this.pageSize, this.sortKey, sort, filterQuery + ' AND  ORG_ID =' + this.cookie.get('orgId')).subscribe(data => {
      console.log(data)
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
      this.isSpinning = false
      this.isFilterApplied = "primary";
      this.filterClass = "filter-invisible";
    }, err => {
      console.log(err);
    });
  }

  // viewTicketData(data) {
  //   this.drawerTitle = "Ticket No." + data.TICKET_NO;
  //   this.drawerData = Object.assign({}, data);

  //   var filterQuery1 = "AND TICKET_MASTER_ID = " + data.ID + ""
  //   this.api.getAllTicketDetails(0, 0, "CREATED_MODIFIED_DATE", "asc", filterQuery1).subscribe(data => {
  //     console.log(data)
  //     this.totalRecords = data['count'];
  //     this.data1 = data['data'];
  //     this.drawerVisible = true;
  //   }, err => {
  //     console.log(err);
  //   });

  // }
  viewTicketData(data: Ticket) {
    this.newData2 = [];
    this.data1 = [];
    this.ViewchatticketComponentVar.isSpinning = true;

    this.drawerTitle = "Ticket No. " + data.TICKET_NO;
    this.drawerData = Object.assign({}, data);

    var filterQuery1 = "AND TICKET_MASTER_ID = " + data.ID + ""
    this.api.getAllTicketDetails(0, 0, "CREATED_MODIFIED_DATE", "asc", filterQuery1).subscribe(data => {
      if (data['code'] == 200) {
        this.ViewchatticketComponentVar.isSpinning = false;
        console.log(data);
        this.totalRecords = data['count'];
        this.data1 = data['data'];

        // Getting Unique dates
        for (var i = 0; i < this.data1.length; i++) {
          this.uniqueDateArry.push(this.datePipe.transform(this.data1[i]['CREATED_MODIFIED_DATE'], "yyyy-MM-dd"));
        }

        this.uniqueDateArry = [...new Set(this.uniqueDateArry)];
        this.uniqueDateArry.sort();
        console.log(this.uniqueDateArry);

        this.uniqueDateArry.forEach(d1 => {
          this.newData2.push({
            key: d1, data: this.data1.filter(data =>
              this.datePipe.transform(data.CREATED_MODIFIED_DATE, "yyyy-MM-dd") == d1
            )
          });
        });

        this.data1 = this.newData2;
        console.log(this.data1);
      }

    }, err => {
      console.log(err);
    });

    this.drawerVisible = true;
  }

  drawerClose(): void {

    this.drawerVisible = false;
  }

  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }

}

