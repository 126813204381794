import { Component, OnInit, Input } from '@angular/core';
import { OrganizationMaster } from '../../../Models/organization-master'
import { EmmService } from '../../../Service/emm.service'
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { DatePipe } from '@angular/common';
import { ApiService } from 'src/app/Service/api.service';
import { NgForm } from '@angular/forms';
import { trimTrailingNulls } from '@angular/compiler/src/render3/view/util';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.css'],
  providers: [DatePipe]
})

export class OrganizationComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: OrganizationMaster;
  @Input() drawerVisible: boolean;

  listdata1 = [];
  listdata2 = [];
  orgId = this.cookie.get('orgId');
  loadingRecords = true;
  isSpinning = false;
  fileData: File = null;
  isOk = true;
  emailpattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  namepatt = /^[a-zA-Z \-\']+/
  mobpattern = /^[6-9]\d{9}$/
  onlynum = /^[0-9]*$/
  onlychar = /^[a-zA-Z ]*$/
  longitudepattern = /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/
  latitudepattern = /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/
  pat1 = /^\d{6}$/;
  namepattern = /[a-zA-Z][a-zA-Z ]+/
  cityRegex = /^[a-zA-z] ?([a-zA-z]|[a-zA-z] )*[a-zA-z]$/;
  aaddpattern = /^[a-zA-Z0-9\s,'-]*$/
  namepatte = /[ .a-zA-Z]+/
  passpattern = /^\S*$/
  imgUrl
  time
  time1
  time2
  date
  defaultOpenValue = new Date(0, 0, 0, 0, 0, 0);

  constructor(private api: ApiService, private cookie: CookieService, private datePipe: DatePipe, private message: NzNotificationService) {
  }

  ngOnInit() {
    this.getallorg1();
  }

  omit(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  close(accountMasterPage: NgForm) {
    this.drawerClose();
    this.resetDrawer(accountMasterPage);
  }

  resetDrawer(accountMasterPage: NgForm) {
    accountMasterPage.form.reset();
    this.add();
  }

  add(): void {
   // this.api.getAllDesignation(1,1,'STATUS','desc', ' AND  ORG_ID ='+ this.orgId).subscribe (data =>{
    //   if (data['count']==0){
    //     this.data.STATUS=true;
    //   }else
    //   {
    //     this.data.STATUS=true;
    //   }
    // },err=>{
    //   console.log(err);
    // })
  } 

  emailExist = false;

  getallorg1() {
    this.api.getAllOrganizations(0, 0, 'ID', 'desc', "").subscribe(data => {
      if (data['code'] == 200) {
        this.listdata1 = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  getallorg2(id) {
    this.api.getAllOrganizations(0, 0, 'ID', 'desc', " and ID !=" + id).subscribe(data => {
      if (data['code'] == 200) {
        this.listdata2 = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  save(addNew: boolean, accountMasterPage: NgForm): void {
    this.isOk = true;

    if (this.data.NAME != undefined && this.data.NAME.trim() != '') {
      if (!this.namepatte.test(this.data.NAME.toString())) {
        this.isOk = false;
        this.message.error("Please Enter Valid Name", '');
      }

    } else {
      this.isOk = false;
      this.message.error("Please Enter Name", '');
    }

    if (this.data.ADMIN_NAME != undefined && this.data.ADMIN_NAME.trim() != '') {
      if (!this.namepatte.test(this.data.ADMIN_NAME.toString())) {
        this.isOk = false;
        this.message.error("Please Enter Valid Admin Name", '');
      }

    } else {
      this.isOk = false;
      this.message.error("Please Enter Admin Name", '');
    }

    if (this.data.MOBILE_NUMBER != null && this.data.MOBILE_NUMBER != undefined) {
      if (!this.mobpattern.test(this.data.MOBILE_NUMBER.toString())) {
        this.isOk = false;
        this.message.error("Please Enter Valid Mobile Number", '');
      }

    } else {
      this.isOk = false;
      this.message.error("Please Enter Mobile Number", '');
    }

    if (this.data.EMAIL_ID != null && this.data.EMAIL_ID != undefined) {
      if (!this.emailpattern.test(this.data.EMAIL_ID)) {
        this.isOk = false;
        this.message.error("Please Enter Valid Email-Id", '');
      }

    } else {
      this.isOk = false;
      this.message.error("Please Enter Email-Id", '');
    }

    if (this.data.PASSWORD != null && this.data.PASSWORD != undefined) {
      if (!this.passpattern.test(this.data.PASSWORD.toString())) {
        this.isOk = false;
        this.message.error("Please Enter valid Password", '');
      }

    } else {
      this.isOk = false;
      this.message.error("Please Enter Password", '');
    }

    if (this.data.SHORT_CODE != null && this.data.SHORT_CODE != undefined) {
      if (!this.namepatte.test(this.data.SHORT_CODE.toString())) {
        this.isOk = false;
        this.message.error("Please Enter valid Short Code", '');
      }

    } else {
      this.isOk = false;
      this.message.error("Please Enter Valid Short Code", '');
    }

    if (this.data.ADDRESS_LINE_1 != undefined && this.data.ADDRESS_LINE_1.trim() != '') {
      if (!this.namepatte.test(this.data.ADDRESS_LINE_1)) {
        this.isOk = false;
        this.message.error("Please Enter Valid Address", '');
      }

    } else {
      this.isOk = false;
      this.message.error("Please Enter Address 1 ", '');
    }

    if (this.data.ADDRESS_LINE_2 != undefined && this.data.ADDRESS_LINE_2.trim() != '') {
      if (!this.namepatte.test(this.data.ADDRESS_LINE_2)) {
        this.isOk = false;
        this.message.error("Please Enter Valid Address", '');
      }

    } else {
      this.isOk = false;
      this.message.error("Please Enter Address 2", '');
    }

    if (this.data.CITY != null && this.data.CITY.trim() != '') {
      if (!this.onlychar.test(this.data.CITY.toString())) {
        this.isOk = false;
        this.message.error("Please Enter Valid City Name", '');
      }

    } else {
      this.isOk = false;
      this.message.error("Please Enter City", '');
    }

    if (this.data.TEHSIL != null && this.data.TEHSIL.trim() != '') {
      if (!this.onlychar.test(this.data.TEHSIL.toString())) {
        this.isOk = false;
        this.message.error("Please Enter Valid Tehsil Name", '');
      }

    } else {
      this.isOk = false;
      this.message.error("Please Enter Tehsil ", '');
    }

    if (this.data.DISTRICT != null && this.data.DISTRICT.trim() != '') {
      if (!this.onlychar.test(this.data.DISTRICT.toString())) {
        this.isOk = false;
        this.message.error("Please Enter Valid District Name", '');
      }

    } else {
      this.isOk = false;
      this.message.error("Please Enter District", '');
    }

    if (this.data.STATE != null && this.data.STATE.trim() != '') {
      if (!this.onlychar.test(this.data.STATE.toString())) {
        this.isOk = false;
        this.message.error("Please Enter Valid State Name", '');
      }

    } else {
      this.isOk = false;
      this.message.error("Please Enter State ", '');
    }

    if (this.data.COUNTRY != null && this.data.COUNTRY.trim() != '') {
      if (!this.onlychar.test(this.data.COUNTRY.toString())) {
        this.isOk = false;
        this.message.error("Please Enter Valid Country Name", '');
      }

    } else {
      this.isOk = false;
      this.message.error("Please Enter Country", '');
    }

    if (this.data.PINCODE != null && this.data.PINCODE != undefined) {
      if (!this.pat1.test(this.data.PINCODE.toString())) {
        this.isOk = false;
        this.message.error("Please Enter Valid Pincode", '');
      }

    } else {
      this.isOk = false;
      this.message.error("Please Enter Pincode ", '');
    }

    if (this.data.LONGITUTE != null && this.data.LONGITUTE != undefined) {
      if (!this.longitudepattern.test(this.data.LONGITUTE.toString())) {
        this.isOk = false;
        this.message.error("Please Enter Valid Longitude", '');
      }

    } else {
      this.isOk = false;
      this.message.error("Please Enter Longitude", '');
    }

    if (this.data.LATITUTE != null && this.data.LATITUTE != undefined) {
      if (!this.latitudepattern.test(this.data.LATITUTE.toString())) {
        this.isOk = false;
        this.message.error("Please Enter Valid Latitude", '')
      }

    } else {
      this.isOk = false;
      this.message.error("Please Enter Latitude", '');
    }

    if (this.data.MAX_LATEMARK_TIME == null || this.data.MAX_LATEMARK_TIME == undefined) {
      this.isOk = false;
      this.message.error("Please Select Max Latemark Time", '');
    }

    if (this.data.MAX_EARLYMARK_TIME == null || this.data.MAX_EARLYMARK_TIME == undefined) {
      this.isOk = false;
      this.message.error("Please Select Min Earlymark Time", '');
    }

    if (this.data.MAX_APPLIED_LATEMARK_TIME == null || this.data.MAX_APPLIED_LATEMARK_TIME == undefined) {
      this.isOk = false;
      this.message.error("Please Select Latemark Applied Max Time", '');
    }

    if (this.data.MIN_EARLYMARK_APPLIED_TIME == null || this.data.MIN_EARLYMARK_APPLIED_TIME == undefined) {
      this.isOk = false;
      this.message.error("Please Select Latemark Applied Min Time", '');
    }

    if (this.data.MAX_LATEMARK_COUNT == undefined || this.data.MAX_LATEMARK_COUNT.trim() == "") {
      this.isOk = false;
      this.message.error("Please Enter Max Latemark Count", '');
    }

    if (this.data.MAX_EARLYMARK_COUNT == undefined || this.data.MAX_EARLYMARK_COUNT.trim() == "") {
      this.isOk = false;
      this.message.error("Please Enter Max Earlymark Count", '');
    }

    if (this.data.MAX_LATECOMING_COUNT == undefined || this.data.MAX_LATECOMING_COUNT.trim() == "") {
      this.isOk = false;
      this.message.error("Please Enter Max Late Comming Count", '');
    }

    if (this.data.HALFDAY_TIME == null || this.data.HALFDAY_TIME == undefined) {
      this.isOk = false;
      this.message.error("Please Select Half Day Time ", '');
    }

    if (this.data.DAY_START_TIME == null || this.data.DAY_START_TIME == undefined) {
      this.isOk = false;
      this.message.error("Please Select Day Start Time ", '');
    }

    if (this.data.DAY_END_TIME == null || this.data.DAY_END_TIME == undefined) {
      this.isOk = false;
      this.message.error("Please Select Day End Time ", '');
    }

    if (this.isOk) {
      this.isSpinning = true;
      this.data.USER_ID = this.api.userId;
      this.data.ROLE_ID = this.api.roleId;
      this.orgId = this.cookie.get('orgId');
      this.data.MAX_LATEMARK_TIME = this.datePipe.transform(this.data.MAX_LATEMARK_TIME, "HH:mm:ss");
      this.data.MAX_EARLYMARK_TIME = this.datePipe.transform(this.data.MAX_EARLYMARK_TIME, "HH:mm:ss");
      this.data.MAX_APPLIED_LATEMARK_TIME = this.datePipe.transform(this.data.MAX_APPLIED_LATEMARK_TIME, "HH:mm:ss");
      this.data.MIN_EARLYMARK_APPLIED_TIME = this.datePipe.transform(this.data.MIN_EARLYMARK_APPLIED_TIME, "HH:mm:ss");
      this.data.HALFDAY_TIME = this.datePipe.transform(this.data.HALFDAY_TIME, "HH:mm:ss");
      this.data.DAY_START_TIME = this.datePipe.transform(this.data.DAY_START_TIME, "HH:mm:ss");
      this.data.DAY_END_TIME = this.datePipe.transform(this.data.DAY_END_TIME, "HH:mm:ss");

      if (this.data.ID) {
        var emailData2 = this.listdata2.filter(obj => {
          return obj.EMAIL_ID == this.data.EMAIL_ID;
        });

        if (emailData2.length == 0) {
          this.api.updateOrganization(this.data).subscribe(successCode => {
            if (successCode['code'] == 200) {
              this.message.success("Oraganization Updated Successfully", "");

              if (!addNew)
                this.drawerClose();

              this.isSpinning = false;
              this.resetDrawer(accountMasterPage);

            } else {
              this.message.error("Oraganization Updation Failed", "");
              this.isSpinning = false;
            }
          });

        } else {
          this.message.error("E-Mail Already Exist. Please Enter Other E-Mail", "");
          this.isSpinning = false;
        }

      } else {
        var emailData = this.listdata1.filter(obj => {
          return obj.EMAIL_ID == this.data.EMAIL_ID;
        });

        if (emailData.length == 0) {
          this.api.createOrganization(this.data).subscribe(successCode => {
            if (successCode['code'] == 200) {
              this.isSpinning = false;
              this.message.success("Oraganization Created Successfully", "");

              if (!addNew) {
                this.drawerClose();
                this.resetDrawer(accountMasterPage);

              } else {
                this.data = new OrganizationMaster();
                this.resetDrawer(accountMasterPage);
              }

            } else {
              this.message.error("Oraganization Creation Failed", "");
              this.isSpinning = false;
            }
          });

        } else {
          this.message.error("E-Mail Already Exist. Please Enter Other E-Mail", "");
          this.isSpinning = false;
        }
      }
    }
  }

  onFileSelected(event) {
    this.imgUrl = event.target.value;
    this.fileData = <File>event.target.files[0];
    console.log(this.fileData);
    var fileExt = this.fileData.name.split('.').pop();
    console.log("extesion: " + fileExt);
  }
}
