import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';
import { Ticket } from 'src/app/Models/ticket';
import { Ticketdetails } from 'src/app/Models/ticketdetails';
import { Ticketgroup } from 'src/app/Models/ticketgroup';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-createticket',
  templateUrl: './createticket.component.html',
  styleUrls: ['./createticket.component.css'],
  providers: [DatePipe]
})

export class CreateticketComponent implements OnInit {
  filterQuery = "";
  applicationId = Number(this.cookie.get('applicationId'))
  email = this.cookie.get('emailId')
  @Input() drawerClose: Function;
  @Input() data: Ticket;
  @Input() ticketGroups = []
  @Input() ticketQuestion = {}
  isSpinning = false;
  backPressed = false;
  @Input() index = 0;
  @Input() parentId = 0;
  isAddTicket = false;
  ticketDetailsData: Ticketdetails
  DESCRIPTION
  fileDataLOGO_URL: File = null
  ID
  date = new Date();
  date1 = this.datePipe.transform(this.date, 'yyyy-MM-dd HH:mm:ss')
  folderName = "ticket"
  userId = Number(this.cookie.get('userId'));
  item = {}
  isLast = false;
  loadingRecordsFaqs = false;
  faqs = []

  constructor(private datePipe: DatePipe, private message: NzNotificationService, private api: ApiService, private cookie: CookieService) { }

  ngOnInit() { }

  backToPrevious() {
    this.isAddTicket = false;
    this.isLast = false;
  }

  openTicketWindow() {
    this.isAddTicket = false;
  }

  cancel() {
    this.isAddTicket = true;
  }

  nextData(item) {
    this.item = item;
    if (item.IS_LAST == 0) {
      this.index++;
      this.parentId = item.ID;
      this.backPressed = false;
      this.isAddTicket = false;
      this.isLast = false;
      this.filterQuery = " AND PARENT_ID=" + item.ID + " AND TYPE='Q' "
      //AND APPLICATION_ID=" + this.applicationId
      this.getQuestions();
    } else {
      this.isAddTicket = true;
      this.isLast = true;
      this.getMappedFaq();
    }
  }

  getMappedFaq() {
    this.loadingRecordsFaqs = true;
    console.log(this.item['ID']);

    this.api.getMappingFaqs2(this.item['ID']).subscribe(data => {
      if (data['code'] = "200") {
        this.faqs = data['data'];
      } else {
        this.loadingRecordsFaqs = false;
      }

    }, err => {
      this.loadingRecordsFaqs = false;
    });
  }

  prevData() {
    this.isAddTicket = false;
    this.backPressed = true;
    this.index--;
    this.filterQuery = " and PARENT_ID= (select PARENT_ID from TICKET_GROUP_MASTER where ID=((select PARENT_ID from TICKET_GROUP_MASTER where ID=" + this.parentId + "))) and TYPE='Q' "
    this.getQuestions();
  }

  getGroups(id) {
    this.filterQuery = " AND PARENT_ID=" + id + "  AND TYPE='O' "
    // AND APPLICATION_ID=" + this.applicationId
    this.api.getAllTicketGroups(0, 0, 'SEQ_NO', 'ASC', this.filterQuery + ' AND  ORG_ID =' + this.cookie.get('orgId')).subscribe(ticketGroups => {

      if (ticketGroups['code'] == 200) {
        this.ticketGroups = ticketGroups['data']
        this.isSpinning = false
      } else {
        this.isSpinning = false
      }

    }, err => {
      this.isSpinning = false
      this.message.error(JSON.stringify(err), "");
    });
  }

  getAllParents(id) {
    this.filterQuery = " AND PARENT_ID=" + id;

    // AND APPLICATION_ID=" + this.applicationId
    this.api.getTicketGroupParent(0, 0, 'SEQ_NO', 'ASC', this.filterQuery + ' AND  ORG_ID =' + this.cookie.get('orgId'))
      .subscribe(data => {
        if (data['code'] == 200) {
          this.ticketGroups = data['data']
        } else {
        }
      }, err => {
        this.message.error(JSON.stringify(err), "");
      });
  }

  getQuestions() {
    this.isSpinning = true;
    this.ticketGroups = [];

    this.api.getAllTicketGroups(0, 0, 'SEQ_NO', 'ASC', this.filterQuery + ' AND  ORG_ID =' + this.cookie.get('orgId') + ' and STATUS=1').subscribe(ticketGroups => {
      if (ticketGroups['code'] == 200) {
        if (ticketGroups['data'].length == 0) {
          this.ticketQuestion = {};
          this.isSpinning = false;

        } else {
          this.ticketQuestion = ticketGroups['data'][0];

          if (this.backPressed)
            this.parentId = (ticketGroups['data'][0].PARENT_ID);

          this.getGroups(ticketGroups['data'][0].ID)
        }

      } else {
        this.isSpinning = false
      }

    }, err => {
      this.isSpinning = false
      this.message.error(JSON.stringify(err), "");
    });
  }

  getUrl(url) {
    if (url)
      return this.api.baseUrl + "static/ticket/" + url
    else
      return ""
  }

  urlClick(url) {
    console.log("called Click")
    window.open(this.api.baseUrl + "static/ticket/" + url);
  }

  clearImg() {
    this.fileDataLOGO_URL = null
  }

  onFileSelectedLOGO_URL(event) {
    this.fileDataLOGO_URL = event.target.files[0];
  }

  genarateKeyLOGO_URL() {
    if (this.fileDataLOGO_URL) {
      var number = Math.floor(100000 + Math.random() * 900000)
      var fileExt = this.fileDataLOGO_URL.name.split('.').pop();
      var Date = new Date();
      var formatedDate = this.datePipe.transform(Date, 'yyyyMMddHHmmss')
      var url = formatedDate + number + "." + fileExt
      console.log(this.fileDataLOGO_URL)
      this.api.onUpload(this.folderName, this.fileDataLOGO_URL, url)
      var LOGO_URL = this.api.retriveimgUrl + this.folderName + "/" + url;
      return LOGO_URL
    }
    else {
      return ""
    }
  }

  getFormatedDate() {
    var date_ob = new Date();
    let date = ("0" + date_ob.getDate()).slice(-2);
    let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
    let year = date_ob.getFullYear();
    let hours = ("0" + date_ob.getHours()).slice(-2);
    let minutes = ("0" + date_ob.getMinutes()).slice(-2);
    let seconds = ("0" + date_ob.getSeconds()).slice(-2);

    return (year.toString()).slice(2, 4) + month + date + hours + minutes + seconds;
  }

  send() {
    var d = this.getFormatedDate();
    var random = Math.floor(Math.random() * 10000) + 1;
    var LOGO_URL = '';

    if (this.DESCRIPTION != undefined && this.DESCRIPTION.trim() != '') {
      this.isSpinning = true;

      if (this.fileDataLOGO_URL) {
        var number = Math.floor(100000 + Math.random() * 900000)
        var fileExt = this.fileDataLOGO_URL.name.split('.').pop();
        var Dates = new Date();
        var formatedDate = this.datePipe.transform(Dates, 'yyyyMMddHHmmss')
        var url = formatedDate + number + "." + fileExt

        console.log(this.fileDataLOGO_URL, url);

        this.api.onUpload2(this.folderName, this.fileDataLOGO_URL, url).subscribe(successCode => {
          if (successCode['code'] == "200") {
            LOGO_URL = url;
            this.fileDataLOGO_URL = null;
            var data = {
              URL: LOGO_URL,
              TICKET_GROUP_ID: this.item['ID'],
              TICKET_NO: d + "" + random,
              USER_ID: this.userId,
              SUBJECT: this.ticketQuestion['VALUE'],
              MOBILE_NO: this.cookie.get('mobile'),
              EMAIL_ID: this.email,
              CLOUD_ID: 1,
              QUESTION: this.DESCRIPTION,
              STATUS: 'P',
              CLIENT_ID: 1,
              DEPARTMENT_ID: this.item['DEPARTMENT_ID'],
              DEPARTMENT_NAME: this.item['DEPARTMENT_NAME']
            }

            this.api.createTicket(data).subscribe(successCode => {
              // this.isSpinning = false;
              if (successCode['code'] == "200") {
                console.log("Ticket Created");

                this.drawerClose();
                this.isSpinning = false;
                // this.DESCRIPTION = "";
                // this.fileDataLOGO_URL = null;
                this.isAddTicket = false;
                this.isLast = false;
                this.index = 0;
                this.fileDataLOGO_URL = null;
                this.DESCRIPTION = '';
                this.message.success("Ticket created successfully", "");

              } else {
                this.message.error("Failed to create ticket", "");
              }
            });

          } else {
            this.isSpinning = false;
            this.message.error("Failed to upload file", "");
          }
        });

      } else {
        var data = {
          URL: '',
          TICKET_GROUP_ID: this.item['ID'],
          TICKET_NO: d + "" + random,
          USER_ID: this.userId,
          SUBJECT: this.ticketQuestion['VALUE'],
          MOBILE_NO: this.cookie.get('mobile'),
          EMAIL_ID: this.email,
          CLOUD_ID: 1,
          QUESTION: this.DESCRIPTION,
          STATUS: 'P',
          CLIENT_ID: 1,
          DEPARTMENT_ID: this.item['DEPARTMENT_ID'],
          DEPARTMENT_NAME: this.item['DEPARTMENT_NAME']
        }

        this.api.createTicket(data).subscribe(successCode => {
          // this.isSpinning = false;
          if (successCode['code'] == "200") {
            console.log("Ticket Created");

            this.drawerClose();
            this.isSpinning = false;
            // this.DESCRIPTION = "";
            // this.fileDataLOGO_URL = null;
            this.isAddTicket = false;
            this.isLast = false;
            this.index = 0;
            this.fileDataLOGO_URL = null;
            this.DESCRIPTION = '';
            this.message.success("Ticket created successfully", "");

          } else {
            this.isSpinning = false;
            this.message.error("Failed to create ticket", "");
          }
        });
      }

    } else {
      this.message.error("Please mention your problem", "");
    }
  }
}
