import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { TrainingTypeMaster } from 'src/app/Models/trainingtypemaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-addtrainingtype',
  templateUrl: './addtrainingtype.component.html',
  styleUrls: ['./addtrainingtype.component.css']
})

export class AddtrainingtypeComponent implements OnInit {
  @Input()
  drawerClose!: Function;
  @Input()
  data: TrainingTypeMaster = new TrainingTypeMaster;
  @Input()
  drawerVisible: boolean = false;
  isSpinning = false;
  // organizations: OrganizationMaster[];
  // roles: RoleMaster[];
  // selectedRole:RoleMaster;
  isOk = true;
  emailpattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  namepatt = /^[a-zA-Z \-\']+/
  mobpattern = /^[6-9]\d{9}$/

  constructor(private api: ApiService, private message: NzNotificationService) { }

  ngOnInit() {
    // this.selectedRole=new RoleMaster();
    // this.loadOrganizations();
    // this.loadRoles();
  }

  loadOrganizations() {
    this.isSpinning = false;
    // this.api.getAllOrganizations(0,0,'','','').subscribe(organizations => {
    //   this.organizations = organizations['data'];
    //   this.isSpinning = false;
    // }, err => {
    //   console.log(err);
    //   this.isSpinning = false;
    // });
  }

  loadRoles() {
    this.isSpinning = false;
    // this.api.getAllRoles(0,0,'','','').subscribe(roles => {
    //   this.roles = roles['data'];
    //   this.isSpinning = false;
    // }, err => {
    //   console.log(err);
    //   this.isSpinning = false;
    // });
  }

  close(): void {
    this.drawerClose();
  }

  save(addNew: boolean): void {
    this.isSpinning = false;
    if (this.data.TITLE.trim() == "" && this.data.SEQ_NO != undefined) {
      this.isOk = false
      this.message.error("Please Fill All Required Fields", "");
    } else
      if (this.data.TITLE == null || this.data.TITLE.trim() == '') {
        this.isOk = false
        this.message.error('Please Enter Title', '')
      } else
        if (!this.namepatt.test(this.data.TITLE)) {
          this.message.error('Please Enter Valid Title', '')
        } else
          if (this.data.SEQ_NO == undefined || this.data.SEQ_NO <= 0) {
            this.isOk = false
            this.message.error('Please Enter Seq. No.', '')
          }

    if (this.isOk) {
      this.isSpinning = true;

      if (this.data.ID) {
        this.api.updatetrainingType(this.data).subscribe(successCode => {
          if (successCode.code == "200") {
            this.message.success("Training Type Updated Successfully...", "");

            if (!addNew)
              this.drawerClose();
            this.isSpinning = false;

          } else {
            this.message.error("Training Type Updation Failed...", "");
            this.isSpinning = false;
          }
        });

      } else {
        this.api.createtrainingType(this.data).subscribe(successCode => {
          if (successCode.code == "200") {
            this.message.success("Training Type Created Successfully...", "");

            if (!addNew)
              this.drawerClose();
            else {
              this.data = new TrainingTypeMaster();
            }

            this.isSpinning = false;

          } else {
            this.message.error("Training Type Creation Failed...", "");
            this.isSpinning = false;
          }
        });
      }
    }
    // else
    // {
    //   this.message.error("Please Fill All Required Fields...","");
    //   this.isSpinning = false;
  }
}
