export class TrainerMaster {
    ID: number = 0;
    NAME: string = '';
    MOBILE_NO: number = 0;
    EMAIL_ID: string = '';
    SUBJECT_ID: number = 1;
    IS_ACTIVE: Boolean = true;

    POSITION_ID: number = 0;
    PASSWORD: string = '';
    ROLE_ID: number = 0;
    ROLE_NAME: string = '';
}