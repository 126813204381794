import { Component, Input, OnInit } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd";
import { ApiService } from "src/app/Service/api.service";

@Component({
  selector: "app-lp-add-drawer",
  templateUrl: "./lp-add-drawer.component.html",
  styleUrls: ["./lp-add-drawer.component.css"],
})
export class LpAddDrawerComponent implements OnInit {
  @Input() drawerClose: Function;
  // @Input() data: LeaveType;
  @Input() drawerVisible: boolean;

  constructor(
    private api: ApiService,
    private message: NzNotificationService
  ) { }

  ngOnInit() { }
}
