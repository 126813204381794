import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NzNotificationService } from 'ng-zorro-antd';
import { EmployeeMaster } from 'src/app/Models/employeemaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-evaluationreport',
  templateUrl: './evaluationreport.component.html',
  styleUrls: ['./evaluationreport.component.css'],
  providers: [DatePipe]
})

export class EvaluationreportComponent implements OnInit {
  monthFormat = "yyyy-MMM";
  formTitle = "Evaluation Report";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["CRITERIA_NAME", "Criteria"], ["RATING", "Ratings"], ["WEIGHTAGE", "Weightage"], ["POINTS", "Points"], ["REMARK", "Remark"], ["REPORTING_HEAD", "Reporting Head"], ["EVALUATION_DATE", "Evaluation Date"]]
  today = new Date().getFullYear().toString() + "-" + (new Date().getMonth() + 1).toString()

  loadingFilterEmployees = true;
  filterClass: string = "filter-visible";
  filterEmployees: EmployeeMaster[];
  employeeFilter
  isSpinning = false

  month = this.today
  month1

  constructor(private api: ApiService, private datePipe: DatePipe, private message: NzNotificationService) {
  }

  ngOnInit() {
    this.search();
    console.log(this.today)
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    if (this.searchText != "") {
      this.filterQuery += " AND (";
      var likeQuery = " ";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like ('%" + this.searchText + "%') OR";
      });

      this.filterQuery += likeQuery.substring(0, likeQuery.length - 3) + ")";

    } else {
      this.filterQuery = "";

      if (this.isFilterApplied == "primary")
        this.applyFilter();
    }

    this.api.getEvaluationReport(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery).subscribe(data => {
      if (data['code'] == 200) {
        console.log(data);
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  drawerClose(): void {
    this.search();
  }

  loadDataFilter() {
    this.loadingFilterEmployees = true;
    this.api.getAllEmployees(0, 0, '', '', '').subscribe(employees => {
      if (employees['code'] == 200) {
        this.filterEmployees = employees['data'];
        this.loadingFilterEmployees = false;
      }

    }, err => {
      console.log(err);
    });
  }

  clearFilter() {
    this.filterClass = "filter-invisible";
    this.isFilterApplied = "default";
    this.filterQuery = "";
    this.month = this.today;

    this.search();
  }

  applyFilter() {
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    if (this.month == undefined || this.employeeFilter == undefined) {
      this.message.error("Please Select Feilds", "");

    } else {
      this.month = this.datePipe.transform(this.month, "yyyy")
      this.month1 = this.datePipe.transform(this.month, "MM")

      this.filterQuery = " AND MONTH = '" + this.month1 + "' AND YEAR = '" + this.month + "' AND"
      this.filterQuery += " EMPLOYEE_ID = " + this.employeeFilter + " AND ";

      this.filterQuery = this.filterQuery.substring(0, this.filterQuery.length - 5);
      console.log(this.filterQuery);

      this.isSpinning = true;
      this.api.getEvaluationReport(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery).subscribe(data => {
        if (data['code'] == 200) {
          console.log(data);

          this.loadingRecords = false;
          this.isSpinning = false;
          this.isFilterApplied = "primary";
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.filterClass = "filter-invisible";
        }

      }, err => {
        console.log(err);
      });
    }
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }
}
