import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ThoughMaster } from 'src/app/Models/though-master';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-though',
  templateUrl: './though.component.html',
  styleUrls: ['./though.component.css']
})

export class ThoughComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: ThoughMaster;
  @Input() drawerVisible: boolean;

  isSpinning = false;

  constructor(private api: ApiService, private message: NzNotificationService) { }

  ngOnInit() { }

  close(myForm: NgForm) {
    this.drawerClose();
    this.reset(myForm);
    this.clear();
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  save(addNew: boolean, myForm: NgForm): void {
    var isOk = true;

    if (this.data.NAME != undefined) {
      if (this.data.NAME.trim() != '') {
        if (!this.api.checkTextBoxIsValid(this.data.NAME)) {
          isOk = false;
          this.message.error("Please Enter Valid Thought Title", "");
        }
      } else {
        isOk = false;
        this.message.error("Please Enter Valid Thought Title", "");
      }
    } else {
      isOk = false;
      this.message.error("Please Enter Valid Thought Title", "");
    }

    if (isOk) {
      this.isSpinning = true;

      this.imageUpload();
      this.data.FILE_URL = this.uploadedAttachmentStr;
      console.log(this.data);

      if (this.data.ID) {
        this.api.updateThough(this.data).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Though Master Updated Successfully", "");

            if (!addNew)
              this.close(myForm);
            this.isSpinning = false;

          } else {
            this.message.error("Though Master Updation Failed", "");
            this.isSpinning = false;
          }
        });

      } else {
        this.api.createThough(this.data).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Though Master Created Successfully", "");

            if (!addNew)
              this.close(myForm);

            else {
              this.data = new ThoughMaster();
              this.data.IS_ACTIVE = true;
            }

            this.isSpinning = false;

          } else {
            this.message.error("Though Master Creation Failed", "");
            this.isSpinning = false;
          }
        });
      }
    }
  }

  fileURL: File = null;
  photoURL: any;
  imageSrc1: string;

  onFileSelected(event) {
    if (event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/png") {
      this.fileURL = <File>event.target.files[0];

      const reader = new FileReader();

      if (event.target.files && event.target.files.length) {
        const [file] = event.target.files;
        reader.readAsDataURL(file);

        reader.onload = () => {
          this.imageSrc1 = reader.result as string;
          this.photoURL = this.imageSrc1;
        };
      }

    } else {
      this.message.error("Please Select only JPEG/ JPG/ PNG File", "");
      this.fileURL = null;
    }
  }

  clear() {
    this.fileURL = null;
    this.photoURL = null;
  }

  clear1() {
    this.photoURL = null;
  }

  folderName = "thoughts";
  uploadedAttachmentStr: string;

  imageUpload() {
    this.uploadedAttachmentStr = "";

    if (!this.data.ID) {
      if (this.fileURL) {
        var number = Math.floor(100000 + Math.random() * 900000);
        var fileExt = this.fileURL.name.split('.').pop();
        var url = "T" + number + "." + fileExt;

        this.api.onUpload2(this.folderName, this.fileURL, url).subscribe(res => {
          if (res["code"] == 200) {
            console.log("Uploaded");

          } else {
            console.log("Not Uploaded");
          }
        });

        this.uploadedAttachmentStr = this.api.retriveimgUrl + "thoughts/" + url;

      } else {
        this.uploadedAttachmentStr = "";
      }

    } else {
      if (this.fileURL) {
        var number = Math.floor(100000 + Math.random() * 900000);
        var fileExt = this.fileURL.name.split('.').pop();
        var url = "T" + number + "." + fileExt;

        this.api.onUpload2(this.folderName, this.fileURL, url).subscribe(res => {
          if (res["code"] == 200) {
            console.log("Uploaded");

          } else {
            console.log("Not Uploaded");
          }
        });

        this.uploadedAttachmentStr = this.api.retriveimgUrl + "thoughts/" + url;

      } else {
        if (this.photoURL)
          this.uploadedAttachmentStr = this.data.FILE_URL;
        else
          this.uploadedAttachmentStr = "";
      }
    }
  }
}
