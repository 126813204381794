import { Component, OnInit, ViewChild } from '@angular/core';
import { Department } from 'src/app/Models/department';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';
import { DepartmentworkingDetails } from 'src/app/Models/departmentworking-details';
import { DepartmentComponent } from '../department/department.component';

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.css']
})

export class DepartmentsComponent implements OnInit {
  formTitle = "Manage Departments";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["NAME", "Department Name"], ["SHORT_CODE", "Short Code"], ["SEQUENCE_NO", "Sequence no"]]
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Department = new Department();
  applicationId = Number(this.cookie.get('applicationId'))
  drawerVisible1: boolean;
  drawerTitle1: string;
  drawerData1: Department = new Department();
  listOfData: DepartmentworkingDetails[] = [];
  OPEN_TIME2 = null
  CLOSE_TIME2 = null
  DAYS = false;
  USER_ID = this.cookie.get('userId');
  org = [];
  orgId = this.cookie.get('orgId')

  constructor(private api: ApiService, private cookie: CookieService) { }

  ngOnInit() {
    this.search();
    this.orgId = this.cookie.get('orgId')
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')'
    }

    if (likeQuery)
      filter += likeQuery

    this.api.getAllDepartments(this.pageIndex, this.pageSize, this.sortKey, sort, filter + ' AND  ORG_ID=' + this.orgId).subscribe(data => {
      console.log(data['data'])
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];

    }, err => {
      console.log(err);
    });
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }

  add(): void {
    this.drawerTitle = "Create New Department";
    this.drawerData = new Department();
    this.drawerData.ORG_ID = Number(this.orgId);
    this.listOfData = [];

    this.api.getAllDepartments(1, 1, 'SEQUENCE_NO', 'desc', ' AND  ORG_ID =' + this.orgId).subscribe(data => {
      if (data['count'] == 0) {
        this.drawerData.SEQUENCE_NO = 1;

      } else {
        this.drawerData.SEQUENCE_NO = Number(data['data'][0]['SEQUENCE_NO']) + 1;
      }

    }, err => {
      console.log(err);
    })

    for (let i = 0; i < 7; i++) {
      this.listOfData.push({
        ID: 0,
        DAY: i,
        IS_HOLIDAY: false,
        OPEN_TIME: null,
        CLIENT_ID: this.api.clientId,
        DEPARTMENT_ID: 0,
        DATE: "",
        CLOSE_TIME: null
      });
    }

    this.OPEN_TIME2 = null
    this.CLOSE_TIME2 = null
    this.DAYS = false;
    this.drawerVisible = true;
  }

  @ViewChild(DepartmentComponent, { static: false }) DepartmentComponentVar: DepartmentComponent;

  edit(data: Department): void {
    this.drawerTitle = "Update Department";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;

    this.DepartmentComponentVar.getallorg2(data.ID);
  }

  getdepartments() {
    this.api.getAllDepartments(1, 1, 'SEQUENCE_NO', 'asc', + ' AND  ORG_ID =' + this.org[0]['ID']).subscribe(data => {
      if (data['count'] == 0) {
        this.drawerData.SEQUENCE_NO = 1;

      } else {
        this.drawerData.SEQUENCE_NO = Number(data['data'][0]['SEQUENCE_NO']) + 1;
      }
      console.log(data['data'])

      this.totalRecords = data['count'];
      this.dataList = data['data'];

    }, err => {
      console.log(err);
    });
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  drawerClose1(): void {
    this.drawerVisible1 = false;
  }
}