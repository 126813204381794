import { Component, OnInit, Input } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { DatePipe } from '@angular/common';
import { ApiService } from 'src/app/Service/api.service';
import { UpdateAttendanceRegister } from 'src/app/Models/UpdateAttendanceRegister';

@Component({
  selector: 'app-update-attendance-register',
  templateUrl: './update-attendance-register.component.html',
  styleUrls: ['./update-attendance-register.component.css']
})

export class UpdateAttendanceRegisterComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: UpdateAttendanceRegister;
  isSpinning = false;
  dateFormat = "dd/MMM/yyyy";

  constructor(private api: ApiService, private datePipe: DatePipe, private message: NzNotificationService) { }

  ngOnInit() { }

  save() {
    this.isSpinning = true;

    if (this.data.DATE != undefined && this.data.STATUS != undefined) {
      this.data.DATE = this.datePipe.transform(this.data.DATE, 'yyyy-MM-dd')
      console.log(this.data.DATE);

      this.api.updateAttendanceRegister(this.data).subscribe(successCode => {
        if (successCode['code'] == "200") {
          this.message.success("Status Updated Successfully", "");
          this.drawerClose();
          this.isSpinning = false;

        } else {
          this.message.error("Status Updation Failed", "");
          this.isSpinning = false;
        }
      });

    } else {
      this.message.error("Please Fill All Required Fields", "");
      this.isSpinning = false;
    }
  }

  close(): void {
    this.drawerClose();
  }
}
