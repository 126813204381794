import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { TrainingSchedule } from 'src/app/Models/trainingschedule';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-attendcard',
  templateUrl: './attendcard.component.html',
  styleUrls: ['./attendcard.component.css']
})

export class AttendcardComponent implements OnInit {
  @Input() drawerClose!: Function;
  @Input() data: TrainingSchedule = new TrainingSchedule();
  @Input() trainattendiesData: any[] = [];
  @Input() drawerVisible: boolean = false;
  isSpinning = false;
  formTitle = "Training Attendies Mapping";
  loadingRecords = true;
  isOk = true;
  attendmap: any = [{ SCHEDULE_ID: 1, EMP_ID: 1, DEPARTMENT_ID: 1, ATTENDANCE: 0, FEEDBACK: "valid", RATINGS: 1, PERFORMANCE: 1, IS_ACTIVE: 0 }];
  column = [['EMP_ID', 'Employee Name'], ['SCHEDULE_ID', 'Schedule'], ['ATTENDANCE', 'Is Attending']];

  constructor(private api: ApiService, private message: NzNotificationService) { }

  ngOnInit() { }

  close(): void {
    this.drawerClose();
  }

  save() {
    this.isSpinning = false;
    this.isOk = true;

    for (var i = 0; i < this.trainattendiesData.length; i++) {
      if (this.trainattendiesData[i].ATTENDANCE == true) {
        if (this.trainattendiesData[i].RATINGS == undefined || this.trainattendiesData[i].RATINGS <= 0) {
          this.isOk = false;
          this.message.error('Please Enter Valid Ratings of ' + this.trainattendiesData[i].EMAP_NAME, '')
        } else
          if (this.trainattendiesData[i].PERFORMANCE == undefined || this.trainattendiesData[i].PERFORMANCE < 0 || this.trainattendiesData[i].PERFORMANCE > 100) {
            this.isOk = false;
            this.message.error('Please Enter Valid Performance of ' + this.trainattendiesData[i].EMAP_NAME, '')
          } else
            if (this.trainattendiesData[i].FEEDBACK == null || this.trainattendiesData[i].FEEDBACK.trim() == '') {
              this.isOk = false;
              this.message.error('Please Enter Feedback of ' + this.trainattendiesData[i].EMAP_NAME, '')
            }
      }
    }

    if (this.isOk) {
      this.api.addBulktrainingAttendiedMapping(Number(this.data.ID), this.trainattendiesData).subscribe(data => {
        if (data['code'] == '200') {
          this.message.success("Training Attendance Created Successfully...", "");

          // if(!addNew2)
          // this.drawerClose();
          this.drawerClose();
          this.isSpinning = false;
          // this.drawerVisible = false;

        } else {
          this.message.error("Training Attendance Creation Failed...", "");
          // this.message.error('Something Went wrong.','')
          this.isSpinning = false;
        }
        // this.isSpinning = false;

      }, err => {
        this.isSpinning = false;
        // this.message.error('Something Went wrong.','')
      });
    }
  }
}
