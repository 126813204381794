import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { AssetCategory } from 'src/app/Models/AssetCategory';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-asset-category-drawer',
  templateUrl: './asset-category-drawer.component.html',
  styleUrls: ['./asset-category-drawer.component.css']
})

export class AssetCategoryDrawerComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: AssetCategory;
  @Input() drawerVisible: boolean;

  constructor(private api: ApiService, private message: NzNotificationService) { }

  ngOnInit() {
    this.getParentAssetCategory();
  }

  categories = [];

  getParentAssetCategory() {
    this.categories = [];

    this.api.getAllAssetCategory(0, 0, 'NAME', 'asc', ' AND IS_LAST=0 AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.categories = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  close(myForm: NgForm) {
    this.drawerClose();
    this.reset(myForm);
    this.getParentAssetCategory();
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
    this.assetCategoryYesNo = false;
    this.assetCategoryShortCodeYesNo = false;
  }

  isSpinning = false;

  save(addNew: boolean, myForm: NgForm): void {
    var isOk = true;

    if (this.data.PARENT_ID == undefined) {
      isOk = false;
      this.message.error("Please Select Valid Parent Category", "");
    }

    if (this.data.NAME == undefined || this.data.NAME.trim() == '') {
      isOk = false;
      this.message.error("Please Enter Valid Category Name", "");
    }

    if (this.data.SHORT_CODE == undefined || this.data.SHORT_CODE.trim() == '') {
      isOk = false;
      this.message.error("Please Enter Valid Short Code", "");
    }

    if (isOk) {
      this.isSpinning = true;

      if (this.data.PARENT_ID == "0")
        this.data.PARENT_ID = "0";

      if (this.data.ID) {
        this.api.updateAssetCategory(this.data).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.message.success("Asset Category Updated Successfully", "");

            if (!addNew)
              this.close(myForm);

            this.isSpinning = false;

          } else {
            this.message.error("Asset Category Updation Failed", "");
            this.isSpinning = false;
          }
        });

      } else {
        this.api.createAssetCategory(this.data).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.message.success("Asset Category Created Successfully", "");

            if (!addNew)
              this.close(myForm);

            else {
              this.reset(myForm);
              this.getParentAssetCategory();
            }

            this.isSpinning = false;

          } else {
            this.message.error("Asset Category Creation Failed", "");
            this.isSpinning = false;
          }
        });
      }
    }
  }

  assetCategoryYesNo: boolean = false;
  assetCategoryShortCodeYesNo: boolean = false;
}
