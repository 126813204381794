import { Component, Input, OnInit } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd";
import { ApiService } from "src/app/Service/api.service";

@Component({
  selector: "app-add-drawer",
  templateUrl: "./add-drawer.component.html",
  styleUrls: ["./add-drawer.component.css"],
})

export class AddDrawerComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() drawerVisible: boolean;

  constructor(
    private api: ApiService,
    private message: NzNotificationService
  ) { }

  ngOnInit() { }
}
