import { Component, Input, OnInit } from '@angular/core';
import { TrainingAttendanceCard } from 'src/app/Models/TrainingAttendanceCard';

@Component({
  selector: 'app-addtrainattendancecard',
  templateUrl: './addtrainattendancecard.component.html',
  styleUrls: ['./addtrainattendancecard.component.css']
})

export class AddtrainattendancecardComponent implements OnInit {
  @Input()
  drawerClose!: Function;
  @Input()
  data: TrainingAttendanceCard = new TrainingAttendanceCard;
  @Input()
  drawerVisible: boolean = false;

  constructor() { }

  ngOnInit(): void { }
}
