import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { TrainingSubMaster } from 'src/app/Models/trainingsubmaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-addtrainingsub',
  templateUrl: './addtrainingsub.component.html',
  styleUrls: ['./addtrainingsub.component.css']
})

export class AddtrainingsubComponent implements OnInit {
  @Input()
  drawerClose!: Function;
  @Input()
  data: TrainingSubMaster = new TrainingSubMaster;
  @Input()
  drawerVisible: boolean = false;

  @Input()
  drawerClose1!: Function;
  @Input()
  data1: TrainingSubMaster = new TrainingSubMaster;
  @Input()
  drawerVisible1: boolean = false;

  isSpinning = false;
  // organizations: OrganizationMaster[];
  // roles: RoleMaster[];
  // selectedRole:RoleMaster;
  isOk = true;
  emailpattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  namepatt = /^[a-zA-Z \-\']+/
  mobpattern = /^[6-9]\d{9}$/

  trainpoint: any = [{ POINTS_ID: 1, POINTS_NAME: "ONE", CLIENT_ID: 1, IS_ACTIVE: 0, PERIOD: "", SEQ_NO: 1 }];
  column1 = [['POINTS_ID', 'Points'], ['PERIOD', 'Period'], ['SEQ_NO', 'Seq. No.']];

  constructor(private api: ApiService, private message: NzNotificationService) {

  }

  ngOnInit() {
    // this.selectedRole=new RoleMaster();
    // this.loadOrganizations();
    // this.loadRoles();
  }

  omit_special_char(event: any) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  loadOrganizations() {
    this.isSpinning = false;
    // this.api.getAllOrganizations(0,0,'','','').subscribe(organizations => {
    //   this.organizations = organizations['data'];
    //   this.isSpinning = false;
    // }, err => {
    //   console.log(err);
    //   this.isSpinning = false;
    // });
  }

  loadRoles() {
    this.isSpinning = false;
    // this.api.getAllRoles(0,0,'','','').subscribe(roles => {
    //   this.roles = roles['data'];
    //   this.isSpinning = false;
    // }, err => {
    //   console.log(err);
    //   this.isSpinning = false;
    // });
  }

  getSequenceNo() {
    this.api.getAlltrainingSubject(1, 1, 'SEQ_NO', 'desc', '').subscribe(data => {
      if (data['code'] == 200 && data['count'] > 0) {
        var seqno = data['data'][0]['SEQ_NO'];
        this.data.SEQ_NO = Number(seqno) + 1;
      } else {
        this.data.SEQ_NO = 1;
      }
    }, err => {
      console.log(err);
    });
  }

  close(myForm: NgForm): void {
    this.drawerClose();
    this.reset(myForm);
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  save(addNew: boolean, myForm: NgForm): void {
    this.isOk = true;
    this.isSpinning = false;

    if (this.data.TITLE.trim() == "" && this.data.SHORT_CODE.trim() == "" && this.data.IS_ACTIVE && this.data.SEQ_NO != undefined) {
      this.isOk = false
      this.message.error("Please Fill All Required Fields", "");
    } else
      if (this.data.TITLE == null || this.data.TITLE.trim() == '') {
        this.isOk = false
        this.message.error('Please Enter Title', '')
      } else
        if (!this.namepatt.test(this.data.TITLE)) {
          this.isOk = false
          this.message.error('Please Enter Valid Title', '')
        } else
          if (this.data.SHORT_CODE == null || this.data.SHORT_CODE.trim() == '') {
            this.isOk = false
            this.message.error('Please Enter Short Code', '')
          } else
            // if(this.data.DESCRIPTION==null || this.data.DESCRIPTION.trim()==''){
            //   this.message.error('Please Enter Short Code','')
            // }else
            if (this.data.SEQ_NO == null || this.data.SEQ_NO <= 0) {
              this.isOk = false
              this.message.error('Please Enter Seq. No.', '')
            }

    if (this.isOk) {
      this.isSpinning = true;

      if (this.data.ID) {
        this.api.updatetrainingSubject(this.data).subscribe(successCode => {
          if (successCode.code == 200) {
            this.message.success("Training Subject Updated Successfully...", "");
            this.isSpinning = false;

            if (!addNew)
              this.close(myForm);

          } else {
            this.message.error("Training Subject Updation Failed...", "");
            this.isSpinning = false;
          }
        });

      } else {
        this.api.createtrainingSubject(this.data).subscribe(successCode => {
          if (successCode.code == 200) {
            this.message.success("Training Subject Created Successfully...", "");
            this.isSpinning = false;

            if (!addNew)
              this.close(myForm);

            else {
              this.data = new TrainingSubMaster();
              this.getSequenceNo();
            }

          } else {
            this.message.error("Training Subject Creation Failed...", "");
            this.isSpinning = false;
          }
        });
      }
    }
  }


  save1() {
    this.isSpinning = true;

    this.api.addBulktrainingPointsMapping(Number(this.data1.ID), this.data1).subscribe(data => {
      if (this.data1['code'] == '200') {
        this.drawerVisible1 = false;
        this.ngOnInit()
      } else {
        // this.message.error('Something Went wrong.','')
      }
      this.isSpinning = false;
    }, err => {
      this.isSpinning = false;
      // this.message.error('Something Went wrong.','')
    });
  }

  close1(): void {
    this.drawerClose1();
  }
}
