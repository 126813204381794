import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { Latemark } from 'src/app/Models/Latemark';
import { ApiService } from 'src/app/Service/api.service';
import { differenceInCalendarDays, setHours } from 'date-fns';

@Component({
  selector: 'app-head-latemark-approve-drawer',
  templateUrl: './head-latemark-approve-drawer.component.html',
  styleUrls: ['./head-latemark-approve-drawer.component.css']
})

export class HeadLatemarkApproveDrawerComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input()
  data: Latemark = new Latemark();
  orgId = sessionStorage.getItem('orgId');
  isSpinning = false;
  @Input() listOfData: Latemark[] = [];

  listdata2 = [];
  listdata1 = [];
  acctype = [];
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  @Input() DAYS = false;
  isOk = true;

  constructor(private api: ApiService, private datePipe: DatePipe, private message: NzNotificationService) { }

  ngOnInit() { }

  close(myForm: NgForm) {
    this.drawerClose();
    this.resetDrawer(myForm);
  }

  resetDrawer(myForm: NgForm) {
    myForm.form.reset();
  }

  save(addNew: boolean, myForm: NgForm): void {
    var isOk = true;

    if (!this.APPROVE) {
      if (this.REMARK == undefined || this.REMARK.trim() == "") {
        isOk = false;
        this.message.error("Please Enter Valid Remark", "");
      }
    }

    if (isOk) {
      this.isSpinning = true;

      if (this.REMARK == undefined || this.REMARK.trim() == "")
        this.REMARK = "No Remark";

      this.data.APPROVER_ID = this.api.userId;
      this.data.REMARK = this.REMARK;

      if (this.APPROVE) {
        this.data.STATUS = "A";

      } else {
        this.data.STATUS = "R";
      }

      if (this.data.ID) {
        this.api.updatelatemark(this.data).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Latemark Details Saved Successfully", "");

            if (!addNew)
              this.close(myForm);
            this.isSpinning = false;

          } else {
            this.message.error("Failed to Save Latemark Details", "");
            this.isSpinning = false;
          }
        });

      } else {
        // this.api.createlatemark(this.data).subscribe(successCode => {
        //   if (successCode['code'] == "200") {
        //     this.message.success("Latemark Created Successfully", "");

        //     if (!addNew)
        //       this.close(myForm);

        //     else {
        //       this.data = new Latemark();
        //     }

        //     this.isSpinning = false;

        //   } else {
        //     this.message.error("Latemark Creation Failed", "");
        //     this.isSpinning = false;
        //   }
        // });
      }
    }
  }

  today = new Date().setDate(new Date().getDate());

  disabledStartDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) < 0;

  disabledHours(): number[] {
    return [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
  }

  disabledMinutes(hour: number): number[] {
    if (hour === 12) {
      return [31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59];

    } else {
      return [];
    }
  }

  APPROVE: boolean = true;
  REMARK: string;
}
