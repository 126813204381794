import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { Asset } from 'src/app/Models/Asset';
import { ApiService } from 'src/app/Service/api.service';
import { AssetDrawerComponent } from '../../asset/asset-drawer/asset-drawer.component';
import { AssetMaintenanceRegisterComponent } from '../asset-maintenance-register/asset-maintenance-register.component';

@Component({
  selector: 'app-asset-register',
  templateUrl: './asset-register.component.html',
  styleUrls: ['./asset-register.component.css']
})

export class AssetRegisterComponent implements OnInit {
  formTitle = "Asset Register";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  assetData = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  columns: string[][] = [["ASSET_CATEGORY_NAME", "Category"], ["NAME", "Asset Name"], ["DESCRIPTION", "Description"], ["PURCHASE_DATE", "Purchase Date"], ["EXPIRY_DATE", "Expiry Date"], ["MAINTENANCE_FREQUENCY", "Maintenance Frequency"], ["LAST_MAINTENANCE_DATE", "Last Maintenance Date Time"], ["NEXT_MAINTENANCE_DATE", "Next Maintenance Date Time"], ["ALLOCATED_EMPLOYEE_NAME", "Allocated To"], ["ALLOCATION_DATETIME", "Allocation Date Time"]];
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Asset = new Asset();

  constructor(private api: ApiService, private cookie: CookieService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.getAssetCategory();
    this.getAllocatedEmployeeList();
    this.search(true);
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    console.log("search text : " + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND (";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')'
    }

    var categoryFilterStr = "";
    if (this.CATEGORY_ID.length > 0) {
      categoryFilterStr = " AND CATEGORY_ID IN (" + this.CATEGORY_ID + ")";
    }

    var statusFilterStr = "";
    if (this.STATUS.length > 0) {
      statusFilterStr = " AND STATUS IN (" + this.STATUS + ")";
    }

    var allocatedToFilterStr = "";
    if (this.ALLOCATED_EMPLOYEE_ID.length > 0) {
      allocatedToFilterStr = " AND ALLOCATED_EMPLOYEE_ID IN (" + this.ALLOCATED_EMPLOYEE_ID + ")";
    }

    var purchaseDateFilter = "";
    if ((this.PURCHASE_DATE != undefined) && (this.PURCHASE_DATE.length != 0)) {
      purchaseDateFilter = " AND (PURCHASE_DATE BETWEEN '" + this.datePipe.transform(this.PURCHASE_DATE[0], 'yyyy-MM-dd 00:00:00') + "' AND '" + this.datePipe.transform(this.PURCHASE_DATE[1], 'yyyy-MM-dd 23:59:59') + "')";
    }

    var expiryDateFilter = "";
    if ((this.EXPIRY_DATE != undefined) && (this.EXPIRY_DATE.length != 0)) {
      expiryDateFilter = " AND (EXPIRY_DATE BETWEEN '" + this.datePipe.transform(this.EXPIRY_DATE[0], 'yyyy-MM-dd 00:00:00') + "' AND '" + this.datePipe.transform(this.EXPIRY_DATE[1], 'yyyy-MM-dd 23:59:59') + "')";
    }

    var lastMaintenanceDateFilter = "";
    if ((this.LAST_MAINTENANCE_DATE != undefined) && (this.LAST_MAINTENANCE_DATE.length != 0)) {
      lastMaintenanceDateFilter = " AND (LAST_MAINTENANCE_DATE BETWEEN '" + this.datePipe.transform(this.LAST_MAINTENANCE_DATE[0], 'yyyy-MM-dd 00:00:00') + "' AND '" + this.datePipe.transform(this.LAST_MAINTENANCE_DATE[1], 'yyyy-MM-dd 23:59:59') + "')";
    }

    var nextMaintenanceDateFilter = "";
    if ((this.NEXT_MAINTENANCE_DATE != undefined) && (this.NEXT_MAINTENANCE_DATE.length != 0)) {
      nextMaintenanceDateFilter = " AND (NEXT_MAINTENANCE_DATE BETWEEN '" + this.datePipe.transform(this.NEXT_MAINTENANCE_DATE[0], 'yyyy-MM-dd 00:00:00') + "' AND '" + this.datePipe.transform(this.NEXT_MAINTENANCE_DATE[1], 'yyyy-MM-dd 23:59:59') + "')";
    }

    this.loadingRecords = true;
    this.api.getAllAssets(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + categoryFilterStr + statusFilterStr + allocatedToFilterStr + purchaseDateFilter + expiryDateFilter + lastMaintenanceDateFilter + nextMaintenanceDateFilter).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.assetData = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  @ViewChild(AssetDrawerComponent, { static: false }) AssetDrawerComponentVar: AssetDrawerComponent;

  add(): void {
    this.drawerTitle = "Add Asset";
    this.drawerData = new Asset();
    this.drawerVisible = true;
    this.AssetDrawerComponentVar.assetCategoryYesNo = false;
    this.drawerData.MAINTENANCE_FREQUENCY_TYPE = "M";
  }

  edit(data: Asset): void {
    this.drawerTitle = "Update Asset";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
    this.AssetDrawerComponentVar.assetCategoryYesNo = true;

    if ((this.datePipe.transform(this.drawerData.EXPIRY_DATE, "yyyyMMdd")) === '19000101')
      this.drawerData.EXPIRY_DATE = null;
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  employees = [];
  employeeLoading: boolean = false;

  getEmployeeList(empID) {
    this.employees = [];
    this.employeeLoading = true;

    this.api.getAllemployeeMaster(0, 0, 'NAME', 'asc', ' AND ORG_ID=1 AND ID!=1 AND STATUS=1 AND ID!=' + empID).subscribe(data => {
      if (data['code'] == 200) {
        this.employees = data['data'];
        this.employeeLoading = false;
      }

    }, err => {
      this.employeeLoading = false;

      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  getInitial(empName) {
    let initial: string = empName.charAt(0);
    return initial.trim();
  }

  isFilterApplied: string = "default";
  filterClass: string = "filter-visible";

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  CATEGORY_ID = [];
  STATUS = [];
  ALLOCATED_EMPLOYEE_ID = [];
  PURCHASE_DATE = [];
  EXPIRY_DATE = [];
  LAST_MAINTENANCE_DATE = [];
  NEXT_MAINTENANCE_DATE = [];
  categories = [];

  getAssetCategory() {
    this.categories = [];

    this.api.getAllAssetCategory(0, 0, 'NAME', 'asc', ' AND IS_LAST=1').subscribe(data => {
      if (data['code'] == 200) {
        this.categories = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  clearFilter() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.CATEGORY_ID = [];
    this.STATUS = [];
    this.ALLOCATED_EMPLOYEE_ID = [];
    this.PURCHASE_DATE = [];
    this.EXPIRY_DATE = [];
    this.LAST_MAINTENANCE_DATE = [];
    this.NEXT_MAINTENANCE_DATE = [];
    this.search(true);
  }

  applyFilter() {
    if ((this.CATEGORY_ID.length > 0) || (this.STATUS.length > 0) || (this.ALLOCATED_EMPLOYEE_ID.length > 0) || (this.PURCHASE_DATE.length > 0) || (this.EXPIRY_DATE.length > 0) || (this.LAST_MAINTENANCE_DATE.length > 0) || (this.NEXT_MAINTENANCE_DATE.length > 0))
      this.isFilterApplied = "primary";

    else
      this.isFilterApplied = "default";

    this.search(true);
    this.filterClass = "filter-invisible";
  }

  onSearchBoxEnter() {
    document.getElementById("searchBtn").focus();
  }

  allocatedEmployees = [];
  allocatedEmployeeLoading = false;

  getAllocatedEmployeeList() {
    this.allocatedEmployees = [];
    this.allocatedEmployeeLoading = true;

    this.api.getAllemployeeMaster(0, 0, 'NAME', 'asc', ' AND ORG_ID=1 AND ID!=1 AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.allocatedEmployees = data['data'];
        this.allocatedEmployeeLoading = false;
      }

    }, err => {
      this.allocatedEmployeeLoading = false;

      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  getMaintenanceFrequencyInFullForm(frequency) {
    if (frequency == "D")
      return "Day(s)";

    else if (frequency == "M")
      return "Month(s)";

    else if (frequency == "Y")
      return "Year(s)";
  }

  DATE_FOR_MAINTENANCE = new Date();
  ASSET_ID: number;
  @ViewChild(AssetMaintenanceRegisterComponent, { static: false }) AssetMaintenanceRegisterComponentVar: AssetMaintenanceRegisterComponent;

  viewMaintenanceReport(data: Asset) {
    console.log(data);
    this.ASSET_ID = data.ID;
    this.maintenanceReportDrawerVisible = true;
    this.maintenanceReportDrawerTitle = "Maintenance Report > " + data.NAME + " (" + data.ASSET_CODE + ")";
    this.AssetMaintenanceRegisterComponentVar.maintenanceReportSearchText = "";
    this.AssetMaintenanceRegisterComponentVar.ASSET_ID_Filter = data.ID;
    this.AssetMaintenanceRegisterComponentVar.maintenanceReportSearch(true);
  }

  maintenanceReportDrawerVisible: boolean = false;
  maintenanceReportDrawerTitle: string = "";

  maintenanceReportDrawerClose(): void {
    this.maintenanceReportDrawerVisible = false;
  }

  get maintenanceReportCloseCallback() {
    return this.maintenanceReportDrawerClose.bind(this);
  }
}
