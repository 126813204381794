import { Component, OnInit } from '@angular/core';
import { TrainingEvalutionForm } from 'src/app/Models/trainingevalutionform';

@Component({
  selector: 'app-trainingevalutionformlist',
  templateUrl: './trainingevalutionformlist.component.html',
  styleUrls: ['./trainingevalutionformlist.component.css']
})

export class TrainingevalutionformlistComponent implements OnInit {
  formTitle = "Manage Training Evalution Form";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = []
  drawerVisible!: boolean;
  drawerTitle!: string;
  drawerData: TrainingEvalutionForm = new TrainingEvalutionForm();

  constructor() { }

  ngOnInit() {
    this.search();
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "id";
      this.sortValue = "desc"
    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    console.log("search text:" + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      console.log("likeQuery" + likeQuery);
    }

    // this.api.getAllsilo(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
    //   this.loadingRecords = false;
    //   this.totalRecords = data['count'];
    //   this.dataList = data['data'];
    // }, err => {
    //   console.log(err);
    // });
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  add(): void {
    this.drawerTitle = "Create New Training Evalution Form";
    this.drawerData = new TrainingEvalutionForm();
    // this.drawerData.IS_ACTIVE=true;
    this.drawerVisible = true;
  }

  edit(data: TrainingEvalutionForm): void {
    this.drawerTitle = "Update Training Evalution Form";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }
}
