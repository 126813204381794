export class Earlymark {
    NAME: string
    ID: number
    CLIENT_ID: number
    EMPLOYEE_ID: number
    DATE: string
    EXPECTED_TIME: string
    REASON: string
    STATUS: string
    REMARK: string
    APPROVER_ID: number
}