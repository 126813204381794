export class Expenses {
    ID: number
    CLIENT_ID: number
    EMPLOYEE_ID: number
    DATE: string
    CUST_ID: number
    CUST_NAME: string
    PARTY_NAME: string
    HEAD_ID: number
    AMOUNT: number
    ATTACHMENT: string
    DESCRIPTION: string
    PEOPLES_ID: any
    PEOPLES_NAMES: any
    DETAILS: any

    STATUS: string
    HEAD_STATUS: string
    APPROVER_ID: number
    APPROVED_AMOUNT: number
    REMARK: string
    AC_TIME: string

    HEAD_REMARK: string
    HEAD_TIME: string
    EXPENSE_HEAD_NAME: string
}