import { Component, OnInit } from '@angular/core';
import { TrainerMaster } from 'src/app/Models/trainermaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-trainermasterlist',
  templateUrl: './trainermasterlist.component.html',
  styleUrls: ['./trainermasterlist.component.css']
})

export class TrainermasterlistComponent implements OnInit {
  formTitle = "Manage Trainer";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [['NAME', 'Trainer Name'], ['MOBILE_NO', 'Mobile No.'], ['EMAIL_ID', 'Email ID'], ['PASSWORD', 'Password'], ['ROLE_NAME', 'Position']]
  data: TrainerMaster = new TrainerMaster();
  MOBILE_NO: number = 0;
  EMAIL_ID: string = '';
  SUBJECT_ID: number = 0;
  drawerVisible!: boolean;
  drawerTitle!: string;
  drawerData: TrainerMaster = new TrainerMaster();

  constructor(private api: ApiService) { }

  ngOnInit() {
    this.search(true);
  }

  keyup(event: any) {
    this.search();
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "id";
      this.sortValue = "desc"
    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }

    this.api.getAlltrainer(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];

    }, err => {
      console.log(err);
    });
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  add(): void {
    this.drawerTitle = "Create New Trainer";
    this.drawerData = new TrainerMaster();
    this.drawerVisible = true;
  }

  edit(data: TrainerMaster): void {
    this.drawerTitle = "Update Trainer";
    this.drawerData = Object.assign({}, data);
    this.drawerData.ROLE_ID = data.ROLE_ID
    this.drawerVisible = true;
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }
}
