import { Component, OnInit, Input } from '@angular/core';
import { Role } from 'src/app/Models/role';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { Roledetails } from 'src/app/Models/roledetails';

@Component({
  selector: 'app-roledetails',
  templateUrl: './roledetails.component.html',
  styleUrls: ['./roledetails.component.css']
})

export class RoledetailsComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: Role;
  @Input() roleDetailsData: string[];
  @Input() drawerVisible: boolean;
  forms = []
  drawerVisible4: boolean;
  drawerTitle4: string;
  drawerData4: Roledetails = new Roledetails();
  isSpinning = false

  loadingRecords = true;

  constructor(private api: ApiService, private message: NzNotificationService) { }

  ngOnInit() { }

  close(): void {
    this.drawerClose();
  }

  drawerClose4(): void {
    // this.search();
    this.drawerVisible4 = false;
  }

  save() {
    this.isSpinning = true;
    console.log(this.data.ID, this.roleDetailsData);
    this.api.addRoleDetails(this.data.ID, this.roleDetailsData).subscribe(successCode => {
      console.log(successCode);

      if (successCode['code'] == "200") {
        this.message.success("Role Details added Successfully", "");
        this.drawerClose();
        this.isSpinning = false;

      } else {
        this.message.error("Role Details assigning Failed", "");
        this.isSpinning = false;
      }
    });
  }
}
