import { Component, OnInit, Input } from '@angular/core';
import { EvaluationCriateria } from 'src/app/Models/evaluation-criateria';
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { ApiService } from 'src/app/Service/api.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-evaluationcriteria',
  templateUrl: './evaluationcriteria.component.html',
  styleUrls: ['./evaluationcriteria.component.css']
})

export class EvaluationcriteriaComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: EvaluationCriateria;
  @Input() drawerVisible: boolean;

  isSpinning = false;

  constructor(private api: ApiService, private message: NzNotificationService) { }

  ngOnInit() { }

  close(myForm: NgForm): void {
    this.drawerClose();
    this.reset(myForm);
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  save(addNew: boolean, myForm: NgForm): void {
    var isOk = true;

    if (this.data.NAME != undefined) {
      if (this.data.NAME.trim() != '') {
        if (!this.api.checkTextBoxIsValid(this.data.NAME)) {
          isOk = false;
          this.message.error("Please Enter Valid Criteria Name", "");
        }
      } else {
        isOk = false;
        this.message.error("Please Enter Valid Criteria Name", "");
      }
    } else {
      isOk = false;
      this.message.error("Please Enter Valid Criteria Name", "");
    }

    if (isOk) {
      this.isSpinning = true;

      if (this.data.ID) {
        this.api.updateEvaluationCriteria(this.data).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Evaluation Criteria Updated Successfully", "");

            if (!addNew) {
              this.close(myForm);
              this.isSpinning = false;
            }

          } else {
            this.message.error("Evaluation Criteria Updation Failed", "");
            this.isSpinning = false;
          }
        });

      } else {
        this.api.createEvaluationCriteria(this.data).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Evaluation Criteria Created Successfully", "");

            if (!addNew) {
              this.close(myForm);

            } else {
              this.data = new EvaluationCriateria();
              this.data.STATUS = true;
            }

            this.isSpinning = false;

          } else {
            this.message.error("Evaluation Criteria Creation Failed", "");
            this.isSpinning = false;
          }
        });
      }
    }
  }
}
