import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-asset-register-for-asset-category-register',
  templateUrl: './asset-register-for-asset-category-register.component.html',
  styleUrls: ['./asset-register-for-asset-category-register.component.css']
})

export class AssetRegisterForAssetCategoryRegisterComponent implements OnInit {
  assetRegisterReportPageIndex = 1;
  assetRegisterReportPageSize = 10;
  assetRegisterReportTotalRecords = 1;
  assetRegisterReportData = [];
  assetRegisterReportLoadingRecords = true;
  assetRegisterReportSortValue: string = "desc";
  assetRegisterReportSortKey: string = "ID";
  assetRegisterReportSearchText: string = "";
  columns: string[][] = [["DESCRIPTION", "Description"], ["PURCHASE_DATE", "Purchase Date"], ["EXPIRY_DATE", "Expiry Date"], ["MAINTENANCE_FREQUENCY", "Maintenance Frequency"], ["LAST_MAINTENANCE_DATE", "Last Maintenance Date Time"], ["NEXT_MAINTENANCE_DATE", "Next Maintenance Date Time"], ["ALLOCATED_EMPLOYEE_NAME", "Allocated To"], ["ALLOCATION_DATETIME", "Allocation Date Time"]];
  @Input() drawerClose: Function;
  ASSET_CATEGORY_ID_Filter: number = 0;

  constructor(private api: ApiService, private cookie: CookieService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.getAllocatedEmployeeList();
    this.assetRegisterReportSearch(true);
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  sort(sort: { key: string; value: string }): void {
    this.assetRegisterReportSortKey = sort.key;
    this.assetRegisterReportSortValue = sort.value;
    this.assetRegisterReportSearch(true);
  }

  assetRegisterReportSearch(reset: boolean = false) {
    if (reset) {
      this.assetRegisterReportPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.assetRegisterReportSortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    console.log("search text : " + this.assetRegisterReportSearchText);

    if (this.assetRegisterReportSearchText != "") {
      likeQuery = " AND (";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.assetRegisterReportSearchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')'
    }

    var assetCategoryIDFilter = " AND CATEGORY_ID=" + this.ASSET_CATEGORY_ID_Filter;
    var statusFilterStr = "";
    if (this.STATUS.length > 0) {
      statusFilterStr = " AND STATUS IN (" + this.STATUS + ")";
    }

    var allocatedToFilterStr = "";
    if (this.ALLOCATED_EMPLOYEE_ID.length > 0) {
      allocatedToFilterStr = " AND ALLOCATED_EMPLOYEE_ID IN (" + this.ALLOCATED_EMPLOYEE_ID + ")";
    }

    var purchaseDateFilter = "";
    if ((this.PURCHASE_DATE != undefined) && (this.PURCHASE_DATE.length != 0)) {
      purchaseDateFilter = " AND (PURCHASE_DATE BETWEEN '" + this.datePipe.transform(this.PURCHASE_DATE[0], 'yyyy-MM-dd 00:00:00') + "' AND '" + this.datePipe.transform(this.PURCHASE_DATE[1], 'yyyy-MM-dd 23:59:59') + "')";
    }

    var expiryDateFilter = "";
    if ((this.EXPIRY_DATE != undefined) && (this.EXPIRY_DATE.length != 0)) {
      expiryDateFilter = " AND (EXPIRY_DATE BETWEEN '" + this.datePipe.transform(this.EXPIRY_DATE[0], 'yyyy-MM-dd 00:00:00') + "' AND '" + this.datePipe.transform(this.EXPIRY_DATE[1], 'yyyy-MM-dd 23:59:59') + "')";
    }

    var lastMaintenanceDateFilter = "";
    if ((this.LAST_MAINTENANCE_DATE != undefined) && (this.LAST_MAINTENANCE_DATE.length != 0)) {
      lastMaintenanceDateFilter = " AND (LAST_MAINTENANCE_DATE BETWEEN '" + this.datePipe.transform(this.LAST_MAINTENANCE_DATE[0], 'yyyy-MM-dd 00:00:00') + "' AND '" + this.datePipe.transform(this.LAST_MAINTENANCE_DATE[1], 'yyyy-MM-dd 23:59:59') + "')";
    }

    var nextMaintenanceDateFilter = "";
    if ((this.NEXT_MAINTENANCE_DATE != undefined) && (this.NEXT_MAINTENANCE_DATE.length != 0)) {
      nextMaintenanceDateFilter = " AND (NEXT_MAINTENANCE_DATE BETWEEN '" + this.datePipe.transform(this.NEXT_MAINTENANCE_DATE[0], 'yyyy-MM-dd 00:00:00') + "' AND '" + this.datePipe.transform(this.NEXT_MAINTENANCE_DATE[1], 'yyyy-MM-dd 23:59:59') + "')";
    }

    var allocatedDateFilter = "";
    if ((this.ALLOCATION_DATE != undefined) && (this.ALLOCATION_DATE.length != 0)) {
      purchaseDateFilter = " AND (ALLOCATION_DATETIME BETWEEN '" + this.datePipe.transform(this.ALLOCATION_DATE[0], 'yyyy-MM-dd 00:00:00') + "' AND '" + this.datePipe.transform(this.ALLOCATION_DATE[1], 'yyyy-MM-dd 23:59:59') + "')";
    }

    this.assetRegisterReportLoadingRecords = true;
    this.api.getAllAssets(this.assetRegisterReportPageIndex, this.assetRegisterReportPageSize, this.assetRegisterReportSortKey, sort, likeQuery + assetCategoryIDFilter + statusFilterStr + allocatedToFilterStr + purchaseDateFilter + expiryDateFilter + lastMaintenanceDateFilter + nextMaintenanceDateFilter + allocatedDateFilter).subscribe(data => {
      if (data['code'] == 200) {
        this.assetRegisterReportLoadingRecords = false;
        this.assetRegisterReportTotalRecords = data['count'];
        this.assetRegisterReportData = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  isFilterApplied: string = "default";
  filterClass: string = "filter-visible";

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  clearFilter() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.STATUS = [];
    this.ALLOCATED_EMPLOYEE_ID = [];
    this.PURCHASE_DATE = [];
    this.EXPIRY_DATE = [];
    this.LAST_MAINTENANCE_DATE = [];
    this.NEXT_MAINTENANCE_DATE = [];
    this.ALLOCATION_DATE = [];
    this.assetRegisterReportSearch(true);
  }

  applyFilter() {
    if ((this.STATUS.length > 0) || (this.ALLOCATED_EMPLOYEE_ID.length > 0) || (this.PURCHASE_DATE.length > 0) || (this.EXPIRY_DATE.length > 0) || (this.LAST_MAINTENANCE_DATE.length > 0) || (this.NEXT_MAINTENANCE_DATE.length > 0) || (this.ALLOCATION_DATE.length > 0))
      this.isFilterApplied = "primary";

    else
      this.isFilterApplied = "default";

    this.assetRegisterReportSearch(true);
    this.filterClass = "filter-invisible";
  }

  onSearchBoxEnter() {
    document.getElementById("searchBtn1").focus();
  }

  close() {
    this.drawerClose();
  }

  getMaintenanceFrequencyInFullForm(frequency) {
    if (frequency == "D")
      return "Day(s)";

    else if (frequency == "M")
      return "Month(s)";

    else if (frequency == "Y")
      return "Year(s)";
  }

  STATUS = [];
  ALLOCATED_EMPLOYEE_ID = [];
  PURCHASE_DATE = [];
  EXPIRY_DATE = [];
  LAST_MAINTENANCE_DATE = [];
  NEXT_MAINTENANCE_DATE = [];
  allocatedEmployees = [];
  ALLOCATION_DATE = [];
  allocatedEmployeeLoading = false;

  getAllocatedEmployeeList() {
    this.allocatedEmployees = [];
    this.allocatedEmployeeLoading = true;

    this.api.getAllemployeeMaster(0, 0, 'NAME', 'asc', ' AND ORG_ID=1 AND ID!=1 AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.allocatedEmployees = data['data'];
        this.allocatedEmployeeLoading = false;
      }

    }, err => {
      this.allocatedEmployeeLoading = false;

      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  getInitial(empName) {
    let initial: string = empName.charAt(0);
    return initial.trim();
  }
}
