export class SplitExpensesDetails {
    ALREADY_APPROVED: number
    AMOUNT: number
    AMT: number
    APPROVED_AMOUNT: number
    DEPARTMENT_ID: number
    EMPLOYEE_ID: number
    EXPENSE_TRANSACTION_ID: number
    ID: number
    IS_MULTI_DEPARTMENT: any
    NAME: string
    TODAYS_TOTAL: number
}