import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NzButtonType } from 'ng-zorro-antd/button';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { TrainingSchedule } from 'src/app/Models/trainingschedule';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-accessorviewdrawer',
  templateUrl: './accessorviewdrawer.component.html',
  styleUrls: ['./accessorviewdrawer.component.css']
})

export class AccessorviewdrawerComponent implements OnInit {
  @Input() drawerClose!: Function;
  @Input() data: TrainingSchedule = new TrainingSchedule();
  @Input() mapdata: any[] = [];
  @Input() scheduleId: any;
  @Input() drawerVisible: boolean = false;
  isSpinning = false;
  formTitle = "Training Attendies Mapping";
  loadingRecords = true;
  isOk = true;
  filterQuery: string = "";
  filterValue = []
  isFilterApplied: NzButtonType = "default";
  isloadSpinning = false;
  exportSpinning = false;
  filterClass: string = "filter-invisible";
  current = new Date();
  isStatusSpinning = false;
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList: any = [];
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  DESIGNSTION_ID = 0;
  DEPARTMENT_ID = 0;
  departmentnm: any = 0;
  designationm: any = 0;
  REASON: string = '';
  column = [['EMP_NAME', 'Employee Name'], ['EVALUATION_STATUS', 'Performance']];

  constructor(private datePipe: DatePipe, private api: ApiService, private message: NzNotificationService) { }

  ngOnInit() { }

  close(): void {
    this.drawerClose();
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "id";
      this.sortValue = "desc";
      this.dataList = [];
    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND";
      this.column.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }

    var filter = "";
    if (likeQuery)
      filter = this.filterQuery + likeQuery;
    else
      filter = this.filterQuery;

    var filter = " AND ATTENDANCE_STATUS = 1 " + " " + " AND SCHEDULE_ID =" + this.scheduleId;

    this.api.getAlltrainingAttendiedMapping(0, 0, '', '', filter).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.mapdata = data['data'];
      this.drawerVisible = true;

    }, err => {
    });
  }

  applyFilter() {
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    this.filterQuery = '';
    if (this.departmentnm == 0) {
      this.filterQuery = this.filterQuery;
    } else {
      this.filterQuery += " AND NAME='" + this.departmentnm + "'";
    }

    if (this.designationm == 0) {
      this.filterQuery = this.filterQuery;
    } else {
      this.filterQuery += " AND TITLE='" + this.designationm + "'";
    }

    this.pageIndex = 1;
    this.dataList = [];
    this.search(true);
  }

  clearFilter() {
    this.filterValue = [];
    this.dataList = [];
    this.isFilterApplied = "default";
    this.departmentnm = 0;
    this.designationm = 0;
    this.filterClass = "filter-invisible";
    this.applyFilter();
  }

  ATTENDANCE_STATUS: any;
  EVALUATION_STATUS;
  TRAINER_REMARK;
  ID;
  x = [];
  y = [];
  z = [];
  t = [];
  arraytrain: any;
  pusharray = [];

  save() {
    this.isSpinning = false;
    for (var i = 0; i < this.mapdata.length; i++) {
      this.arraytrain = [
        {
          'ATTENDANCE_STATUS': this.mapdata[i]['ATTENDANCE_STATUS'],
          'EVALUATION_STATUS': this.mapdata[i]['EVALUATION_STATUS'],
          'ID': this.mapdata[i]['ID']
        }]

      this.pusharray.push(this.arraytrain[0]);
    }

    console.log(this.pusharray);
    this.REASON = 'ES';

    this.isSpinning = true;
    this.api.updatetrainingAttendiedMapping(this.pusharray, this.REASON, this.scheduleId).subscribe(data => {
      if (data['code'] == '200') {
        this.message.success("Accessor Evaluation Updated Successfully", "");
        this.isSpinning = false;
        this.close();

      } else {
        this.message.error("Accessor Evaluation Updation Failed", "");
        this.isSpinning = false;
      }

    }, err => {
      this.isSpinning = false;
    });
  }
}
