import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { TrainingSubMaster } from 'src/app/Models/trainingsubmaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-trainingsublist',
  templateUrl: './trainingsublist.component.html',
  styleUrls: ['./trainingsublist.component.css']
})

export class TrainingsublistComponent implements OnInit {
  formTitle = "Manage Training Subject Group";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["TITLE", "Subject Groups"], ["SEQ_NO", "Sequence No."]];
  trainpoint: any = [{ POINTS_ID: 1, POINTS_NAME: "ONE", CLIENT_ID: 1, IS_ACTIVE: 0, PERIOD: "", SEQ_NO: 1 }];
  submasterId = 0;
  drawerVisible!: boolean;
  drawerTitle!: string;
  drawerData: TrainingSubMaster = new TrainingSubMaster();
  drawerVisible1!: boolean;
  drawerTitle1!: string;
  drawerData1: any[] = [];

  constructor(private message: NzNotificationService, private api: ApiService) { }

  ngOnInit() {
    this.search();
  }

  keyup(event: any) {
    this.search();
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "id";
      this.sortValue = "desc"
    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }

    this.api.getAlltrainingSubject(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];

    }, err => {
      console.log(err);
    });
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  add(): void {
    this.drawerData = new TrainingSubMaster();

    this.api.getAlltrainingSubject(1, 1, 'SEQ_NO', 'desc', '').subscribe(data => {
      if (data['count'] == 0) {
        this.drawerData.SEQ_NO = 1;

      } else {
        this.drawerData.SEQ_NO = data['data'][0]['SEQ_NO'] + 1;
      }

    }, err => {
      console.log(err);
    });

    this.drawerTitle = "Create Training Subject Group";
    this.drawerData.IS_ACTIVE = true;
    this.drawerVisible = true;
  }

  edit(data: TrainingSubMaster): void {
    this.drawerTitle = "Update Training Subject Group";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  pointmap(data1: any): void {
    this.drawerData = Object.assign({}, data1);

    this.api.trainingPointsMapping(Number(data1.ID)).subscribe(datas => {
      if (datas['code'] == '200' && datas['data'].length > 0) {
        this.drawerData1 = datas['data'];
        this.drawerVisible1 = true;

      } else {
        this.message.error('Please add training points first.', '')
      }

    }, err => {
      this.message.error('Something Went wrong.', '')
    });

    this.drawerTitle1 = "Training Subject Mapping";
  }

  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }

  drawerClose1(): void {
    this.drawerVisible1 = false;
  }

  save1() {
    this.api.addBulktrainingPointsMapping(this.drawerData.ID, this.drawerData1).subscribe(datas => {
      if (datas['code'] == '200') {
        this.message.success('Successfully mapped training points.', '')
        this.drawerVisible1 = false;

      } else {
        this.message.error('Failed mapp training points.', '')
      }
    }, err => {
      this.message.error('Something Went wrong.', '')
    });
  }

  close(): void {
    this.drawerClose1();
  }

  isSpinning: boolean = false;
}
