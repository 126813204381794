import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AnnualTrainingPoint } from 'src/app/Models/annualtrainingpoint';
import { TrainingSubMaster } from 'src/app/Models/trainingsubmaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-addannualtrainpoint',
  templateUrl: './addannualtrainpoint.component.html',
  styleUrls: ['./addannualtrainpoint.component.css']
})

export class AddannualtrainpointComponent implements OnInit {
  @Input()
  drawerClose!: Function;
  @Input()
  data: AnnualTrainingPoint = new AnnualTrainingPoint;
  @Input()
  drawerVisible: boolean = false;
  isSpinning = false;
  isOk = true;
  posnopatt = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/
  namepatt = /^[A-Za-z]+$/
  today = new Date();
  YEAR = new Date();
  subjects: TrainingSubMaster[] = [];

  constructor(private api2: ApiService, private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.loadSubjects();
  }

  onChange(result: Date): void {
    console.log('onChange: ', result);
  }

  loadSubjects() {
    this.api2.getAlltrainingSubject(0, 0, '', '', 'AND IS_ACTIVE!=false').subscribe(data => {
      this.subjects = data['data'];
    }, err => {
      console.log(err);
      this.isSpinning = false;
    });
  }

  loadOrganizations() {
    this.isSpinning = false;
    // this.api.getAllOrganizations(0,0,'','','').subscribe(organizations => {
    //   this.organizations = organizations['data'];
    //   this.isSpinning = false;
    // }, err => {
    //   console.log(err);
    //   this.isSpinning = false;
    // });
  }

  loadRoles() {
    this.isSpinning = false;
    // this.api.getAllRoles(0,0,'','','').subscribe(roles => {
    //   this.roles = roles['data'];
    //   this.isSpinning = false;
    // }, err => {
    //   console.log(err);
    //   this.isSpinning = false;
    // });
  }

  close(): void {
    this.drawerClose();
  }

  save(addNew: boolean): void {
    this.isOk = true;
    this.isSpinning = false;

    //  if(this.data.SUBJECT_MASTER_ID!=undefined && this.data.MONTH!=undefined && this.data.YEAR!=undefined && this.data.PERIOD!=undefined){
    //    this.isOk=false;
    //    this.message.error('Please Fill All Required Fields','');
    //  }else
    if (this.data.SUBJECT_MASTER_ID == undefined || this.data.SUBJECT_MASTER_ID <= 0) {
      this.isOk = false;
      this.message.error('Please Select Subject', '');
    } else
      if (this.data.MONTH == undefined || this.data.MONTH <= 0) {
        this.isOk = false;
        this.message.error('Please Select Month', '');
      } else
        if (this.YEAR == undefined || this.YEAR == null) {
          this.isOk = false;
          this.message.error('Please Select Year', '');
        } else
          if (this.data.PERIOD == undefined || this.data.PERIOD <= 0) {
            this.isOk = false;
            this.message.error('Please Enter Period', '');
          }

    if (this.isOk) {
      // this.data.MASTER_STATUS = 'Q'
      // this.data.INWARD_DATE = this.datePipe.transform(this.data.INWARD_DATE, "yyyy-MM-dd  HH:mm:ss");
      this.data.YEAR = Number(this.datePipe.transform(this.YEAR, "yyyy"));

      if (this.data.ID) {
        this.api.updateannualTraniningPoints(this.data).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Annual Training Plan Updated Successfully...", "");

            if (!addNew)
              this.drawerClose();
            this.isSpinning = false;

          } else {
            this.message.error("Annual Training Plan Updation Failed...", "");
            this.isSpinning = false;
          }
        });

      } else {
        this.api.createannualTraniningPoints(this.data).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Annual Training Plan Created Successfully...", "");

            if (!addNew)
              this.drawerClose();

            else {
              this.data = new AnnualTrainingPoint();
            }

            this.isSpinning = false;

          } else {
            this.message.error("Annual Training Plan Creation Failed...", "");
            this.isSpinning = false;
          }
        });
      }
    }
  }
}
