import { DatePipe } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
// import { } from '../Service/api.service'
import { leaveMaster } from '../LeaveMaster';
// import {ApiService} from '../app/Service/api.service'


@Component({
  selector: 'app-master-form',
  templateUrl: './master-form.component.html',
  styleUrls: ['./master-form.component.css']
})
export class MasterFormComponent implements OnInit {

  @Input() data: leaveMaster = new leaveMaster();
  @Input() drawerClose!: Function;
  constructor(private message: NzNotificationService, private api: ApiService, private datepipe: DatePipe) { }

  isSpinning = false;
  switchValue = false;
  // switchValue1 = true;



  switch = false;
  switch1 = false;
  @Input() switch5 = false;
  @Input() switch6 = false;
  @Input() listOfData2: any = []

  // listOfD:anyata: leaveMaster[] = [];
  listOfData: any = []

  // listData1: any[];

  // selectedOptions: any = [];

  //SelectAll From s
  listOfOptions: any[] = [
    { ID: 1, NAME: "Full day" },
    { ID: 2, NAME: "Half Day" },
    { ID: 3, NAME: "Quarter Day" }
  ];

  switchChange1(e: boolean) {
    let arr = []
    if (e === true) {

      for (let i = 0; i < this.listOfOptions.length; i++) {

        arr.push(this.listOfOptions[i].ID)

      }
      this.data.APPLICABLE_TYPES = arr;
      console.log(this.data.APPLICABLE_TYPES);

    }
    else {
      this.data.APPLICABLE_TYPES = [];
    }
    console.log(this.data.APPLICABLE_TYPES);
  }


  modelChange(e1: any) {
    this.data.APPLICABLE_TYPES = e1;
    if (this.data.APPLICABLE_TYPES.length != this.listOfOptions.length) {
      this.switch5 = false;
    }
    else {
      this.switch5 = true;
    }
  }

  //selectAll2

  // selectedOptions1: any = [];
  // listOfOptions1: any[] = [ "Type 1","Type 2"]
  // listOfOptions1: { ID: number; NAME: string }[] = [
  //   { ID: 1, NAME: "Type 1" },
  //   { ID: 2, NAME: "Type 2" },
  //   { ID: 3, NAME: "Type 3" },
  // ]

  listOfOptions1: any[] = []

  switchChanges2(ee: boolean) {

    let arr = []
    if (ee === true) {

      for (let i = 0; i < this.listOfOptions1.length; i++) {
        arr.push(this.listOfOptions1[i].ID)
      }
      this.data.CLUBBED_WITH_NOT_POSSIBLE = arr;
      console.log(this.data.CLUBBED_WITH_NOT_POSSIBLE, "hh");
    }
    else {
      this.data.CLUBBED_WITH_NOT_POSSIBLE = [];
    }
    console.log(this.data.CLUBBED_WITH_NOT_POSSIBLE);
  }

  modelChange1(e2: any) {
    console.log('helllllllllllll')
    this.data.CLUBBED_WITH_NOT_POSSIBLE = e2;
    if (this.data.CLUBBED_WITH_NOT_POSSIBLE.length !== this.listOfOptions1.length) {
      this.switch6 = false;
    }
    else {
      this.switch6 = true;
    }
  }

  pageIndex = 1;
  pageSize = 10;
  totalRecords = 100;

  date = null;
  isOk = false;

  onChange($event: string[]): void {
    console.log($event);
  }

  switchChange(e: boolean) {
    this.switch != e
    this.data.PRORATED_MAX_JOINING_DATE = null;
  }

  switchChange2(e1: boolean) {
    this.switch1 = e1;
    // this.data.LEAVE_ENCASHMENT = false;
    this.data.MIN_ENCASH = null;
    this.data.MAX_ENCASH = null;
  }


  ngOnInit() {
    this.getData();

  }

  getData() {
    this.api.getLeaveData(0, 0, '', '', 'AND STATUS=1').subscribe((data) => {
      this.listOfData = data['data'];
      console.log('this.listOfData', this.listOfData);
      this.listOfOptions1 = data['data'];
      // console.log(this.listOfOptions1,"hhghh");
    })
  }

  // // similarData() {
  //   if (this.listOfData.length > 0) {
  //     var filterData = this.listOfData.filter(object => {
  //       return object['CODE'].toLowerCase() == this.data.CODE.toLowerCase()
  //     });
  //     if (filterData.length > 0) {
  //       this.isSpinning = false;
  //       if (filterData.length > 0) {
  //         this.message.error("Name Already Present in Database", "");
  //       }
  //       return false
  //     } else {
  //       return true
  //     }
  //   }

  // // }


  onSubmit(addNew: boolean, httpForm: NgForm) {
    console.log(this.data.PRO_RATED, "prorated")

    console.log(this.data.PRORATED_MAX_JOINING_DATE, "joindate")

    // if (this.data.NAME.trim() == "" && this.data.NAME.trim() == ""
    //   && this.data.CODE.trim() == "" && this.data.CODE.trim() == ""
    //   && this.data.DESCRIPTION == null || this.data.DESCRIPTION.trim() == ""
    //   && this.data.GENDER == null || this.data.GENDER == "" ){
    //   this.isOk = false
    //   this.message.error("Please Fill All required Fields", "");
    // }else
    this.isOk = true;
    if (this.data.NAME == null || this.data.NAME.trim() == "") {
      this.isOk = false
      this.message.error('Please Enter Valid Name.', "")
    }
    else if (this.data.CODE == null || this.data.CODE.trim() == "") {
      this.isOk = false
      this.message.error('Please Enter Valid Code.', "")
    }
    // else if (this.data.DESCRIPTION == null || this.data.DESCRIPTION.trim() == "") {
    //   this.isOk = false
    //   this.message.error('Please Enter Valid Description.', "")
    // }
    else if (this.data.GENDER == null || this.data.GENDER == "") {
      this.isOk = false
      this.message.error('Please Select Gender.', "")
    }
    else if (this.data.APPLICABLE_TYPES == null || this.data.APPLICABLE_TYPES == 0) {
      this.isOk = false
      this.message.error('Please Select Applicable On.', "")
    }
    else if (this.data.ALLOTMENT_TYPE_ID == null || this.data.ALLOTMENT_TYPE_ID == 0) {
      this.isOk = false
      this.message.error('Please Select Allotment Type.', "")
    }
    else if (this.data.ALLOTMENT_FROM == null || this.data.ALLOTMENT_FROM == 0) {
      this.isOk = false
      this.message.error('Please Select Allotment From.', "")
    }
    else if (this.data.EFFECTIVE_FROM == null || this.data.EFFECTIVE_FROM == '') {
      this.isOk = false
      this.message.error('Please Select Effective From.', "")
    }
    else if (this.data.LEAVE_AVAIL_FROM == null || this.data.LEAVE_AVAIL_FROM == 0) {
      this.isOk = false
      this.message.error('Please Enter Leave Avail From.', "")
    }
    else if (this.data.ALLOTMENT_BALANCE == null || this.data.ALLOTMENT_BALANCE == 0) {
      this.isOk = false
      this.message.error('Please Enter Allotment Balance.', "")
    }
    else if (this.data.CARRY_FORWARD_LIMIT == null || this.data.CARRY_FORWARD_LIMIT == 0) {
      this.isOk = false
      this.message.error('Please Enter Carry Forward Limit.', "")
    }

    else
      if (this.data.PRO_RATED == false && (this.data.PRORATED_MAX_JOINING_DATE == null || this.data.PRORATED_MAX_JOINING_DATE == 0)) {
        this.isOk = false
        this.message.error('Please Enter Prorated Date.', "")
        console.log(this.data.PRO_RATED, "pro")
      }

      else
        if (this.data.LEAVE_ENCASHMENT == true && (this.data.MAX_ENCASH == null || this.data.MAX_ENCASH == 0)) {
          this.isOk = false
          this.message.error('Please Enter Max Encash Per Limit.', "")

        }
        else
          if (this.data.LEAVE_ENCASHMENT == true && (this.data.MIN_ENCASH == null || this.data.MIN_ENCASH == 0)) {
            this.isOk = false
            this.message.error('Please Enter Min Encash Per Limit.', "")
          }

          else if (this.data.MAX_AVAIL_PERMONTH == null || this.data.MAX_AVAIL_PERMONTH == 0) {
            this.isOk = false
            this.message.error('Please Enter Max Avail Per Month.', "")
          }
          else if (this.data.MAX_AVAIL_PER_INSTANCE == null || this.data.MAX_AVAIL_PER_INSTANCE == 0) {
            this.isOk = false
            this.message.error('Please Enter Max avail Per Instance.', "")
          }
          else if (this.data.MIN_AVAIL_PER_INSTANCE == null || this.data.MIN_AVAIL_PER_INSTANCE == 0) {
            this.isOk = false
            this.message.error('Please Enter Min Avail per Instance.', "")
          }
          else if (this.data.MIN_GAP_BETWEEN_TWO_INSTANCE == null ) {
            this.isOk = false
            this.message.error('Please Enter Min Gap Between Two Instance.', "")
          }

          else if (this.data.LEAVE_APPROVAL_DAYS == null ) {
            this.isOk = false
            this.message.error('Please Enter Leave Approval Days.', "")
          }



    if (this.isOk) {
      if (this.data.ID) {


        this.data.EFFECTIVE_FROM = this.datepipe.transform(this.data.EFFECTIVE_FROM, 'yyyy-MM-dd');

        if (this.data.LEAVE_ENCASHMENT == true) {
          if (this.data.MIN_ENCASH >= this.data.MAX_ENCASH) {
            this.message.error('Min Encash Should Be Less Than Max Encash', '');
            return false;
          }
        }


        if (this.data.MIN_AVAIL_PER_INSTANCE >= this.data.MAX_AVAIL_PER_INSTANCE) {
          this.message.error('Min Avail Per Instance Should Be Less Than Max Avail Per Instance', '');
          return false;
        }


        this.isSpinning = false;

        if (this.data.PRO_RATED == true) {
          this.data.PRORATED_MAX_JOINING_DATE = 0;
          this.data.PRO_RATED = true;
        }
        if (this.data.LEAVE_ENCASHMENT == false) {
          this.data.MIN_ENCASH = 0;
          this.data.MAX_ENCASH = 0;
          this.data.LEAVE_ENCASHMENT = false;
        }


        var bundleData = this.listOfData2.filter(obj => {
          return obj.CODE == this.data.CODE;
        });


        if (bundleData.length == 0) {
          this.api.updateData(this.data).subscribe((successCode: any) => {
            if (successCode['code'] == 200) {
              this.getData();
              // this.isSpinning = true;
              this.message.success("Leave Information Updated Successfully...", "");
              console.log(this.data, 'Update Success')
              if (!addNew) {
                this.drawerClose();
                this.reset(httpForm);
                this.isSpinning = false;
              }
              else {
                this.data = new leaveMaster();
                this.message.error("Failed To Update Leave information", "");
                console.log(this.data, "fail Update")

                this.isSpinning = false;
              }
              // console.log(this.data.APPLICABLE_TYPES);
            }

          });
        }
        else {
          this.message.error("This code is already exist..", "");
          this.isSpinning = false;
        }
      }
      else {

        this.isSpinning = true;
        this.data.EFFECTIVE_FROM = this.datepipe.transform(this.data.EFFECTIVE_FROM, 'yyyy-MM-dd');

        if (this.data.LEAVE_ENCASHMENT == true) {
          if (this.data.MIN_ENCASH >= this.data.MAX_ENCASH) {
            this.message.error('Min Encash Should Be Less Than Max Encash', '');
            return false;
          }
        }

        if (this.data.MIN_AVAIL_PER_INSTANCE >= this.data.MAX_AVAIL_PER_INSTANCE) {
          this.message.error('Min Avail Per Instance Should Be Less Than Max Avail Per Instance', '');
          return false;
        }


        var bundleData = this.listOfData.filter(obj => {
          return obj.CODE == this.data.CODE;
        });

        if (this.data.PRO_RATED == true) {
          this.data.PRORATED_MAX_JOINING_DATE = 0;
          this.data.PRO_RATED = true;
        }

        if (this.data.LEAVE_ENCASHMENT == false) {
          this.data.MIN_ENCASH = 0;
          this.data.MAX_ENCASH = 0;
          this.data.LEAVE_ENCASHMENT = false;
        }


        if (bundleData.length == 0) {

          console.log(this.listOfData, 'Code')

          this.api.createData(this.data)
            .subscribe(successCode => {

              if (successCode['code'] == 200) {
                this.message.success("Leave Information Added Successfully...", "");
                console.log(this.data, "create Success");
                this.getData();
                if (!addNew) {
                  // this.isSpinning = true;
                  this.drawerClose();

                }
                else {
                  this.data = new leaveMaster();
                  httpForm.resetForm();
                  this.switchChange1(false);
                  this.switchChanges2(false);
                }
                this.isSpinning = false;
              }
              else {
                this.message.error("Failed To Add Leave Information...", "");
                console.log(this.data, "Fail data")

                this.isSpinning = false;
              }
            });

        }

        else {
          this.message.error("This code is already exist..", "");
          this.isSpinning = false;
        }
      }


    }
  }


  //   save(addNew: boolean,bundleMasterPage: NgForm): void {
  //     this.isSpinning = false;
  //     this.isOk=true;

  //     if ( this.data.COLLEGE_ID<0 && this.data.NAME.trim()==""  && this.data.MOBILE_NO == undefined 
  //         && this.data.EMAIL_ID.trim()=='' && this.data.PASSWORD.trim() == '' ){
  //       this.isOk =false 
  //      this.message.error(" Please fill all required fields", ""); 
  //     }else

  //     if(this.data.COLLEGE_ID==null || this.data.COLLEGE_ID < 0){
  //       this.isOk =false
  //       this.message.error('Please select college name','')
  //     }else 

  //     if(this.data.NAME==null || this.data.NAME.trim()==''){
  //      this.isOk =false
  //      this.message.error('Please enter TPO name','')
  //     // }else 

  //     // if(this.data.NAME==null || this.data.NAME.trim()==''){
  //     //  this.isOk =false
  //     //  this.message.error('Please enter TPO name','')
  //     }else 

  //     if(this.data.MOBILE_NO== null || this.data.MOBILE_NO== undefined){
  //       this.isOk =false
  //       this.message.error('Please enter contact number','')
  //     }else 
  //     if(!this.mobpattern.test(this.data.MOBILE_NO.toString())){
  //       this.message.error('Please enter valid contact number ','')
  //     }else

  //     if(this.data.MOBILE_NO.toString().length < 10){
  //       this.isOk =false
  //       this.message.error('Please enter valid mobile number','')
  //     }else

  //     if(this.data.EMAIL_ID==null || this.data.EMAIL_ID.trim()==''){
  //       this.isOk =false
  //       this.message.error('Please enter mail id','')
  //     }else 

  //     if(!this.emailpattern.test(this.data.EMAIL_ID)){
  //       this.isOk =false
  //       this.message.error('Please enter valid mail id','')
  //     }else 

  //     if(this.data.PASSWORD == null || this.data.PASSWORD.trim()==''){
  //      this.isOk =false
  //      this.message.error('Please enter password','')     
  //     // }else if (this.data.PASSWORD.length < 8 ) {
  //     //   this.isOk = false;
  //     //   this.message.error('Password Must Be 8 Character Long', '');
  // }
  // if(this.isOk)
  // {
  //   this.data.ROLE_ID = 2
  //   this.isSpinning=false; 
  //   // this.data.LATITUDE = this.latitude.toString();
  //   // this.data.LONGITUDE   = this.longitude.toString();
  //   this.isSpinning=true; 
  // if(this.data.ID)
  // {
  //   var bundleData2 = this.listdata2.filter(obj => {
  //     return obj.COLLEGE_ID == this.data.COLLEGE_ID;
  //   });
  //   console.log('bundleData2',bundleData2.length);
  //   console.log('this.listdata1',this.listdata2);

  //   if (bundleData2.length == 0) {
  //     this.api.updateTPO(this.data)
  //     .subscribe(successCode => {
  //       if(successCode.code=="200")
  //       {
  //         this.message.success(' Information updated successfully...', '');
  //         if(!addNew)
  //           this.drawerClose();
  //           this.isSpinning = false;
  //       }   
  //       else
  //       {
  //         if(successCode.code=="401"){
  //           this.message.error(' Entered Mobile Number or Email Already Present...', '');
  //           this.isSpinning = false;
  //           }else {
  //             this.message.error(' Failed To Update Information...', '');
  //             this.isSpinning = false;
  //           }
  //         }
  //     });
  //   } else {
  //     this.message.error("TPO Already Assign To This College...", "");
  //     this.isSpinning = false;
  //   }
  // }
  //   else{
  //       var bundleData = this.listdata1.filter(obj => {
  //         return obj.COLLEGE_ID == this.data.COLLEGE_ID;
  //       });  
  //   console.log('bundleData2',bundleData.length);
  //   console.log('this.listdata1',this.listdata1);

  //       if (bundleData.length == 0) {  
  //       this.api.createTPO(this.data)
  //       .subscribe(successCode => {
  //         if(successCode.code=="200")
  //         {
  //           this.message.success(' Information Save Successfully...', '');
  //           this. loadTPOlist()
  //           if(!addNew)
  //          this.drawerClose();
  //             else
  //             {
  //               this.data=new TPOMaster();
  //               this.resetDrawer(bundleMasterPage);
  //                this. loadTPOlist();

  //               // this.data.img= '';

  //               this.api.getAllTPO(1,1,'','desc','').subscribe (data =>{
  //                 // if (data['count']==0){
  //                 //   this.data.SEQUENCE_NO=1;
  //                 // }else
  //                 // {
  //                 //   this.data.SEQUENCE_NO=data['data'][0]['SEQUENCE_NO']+1;
  //                 // }
  //               },err=>{
  //                 console.log(err);
  //               })
  //             }
  //             this.isSpinning = false;
  //           }
  //            else
  //            {
  //             if(successCode.code=="401"){
  //               this.message.error(' Entered Mobile Number or Email Already Present...', '');
  //               this.isSpinning = false;
  //               }else {
  //                 this.message.error(' Failed To Save Information...', '');
  //                 this.isSpinning = false;
  //               }
  //            }
  //           });
  //         } else {
  //           this.message.error("TPO Already Assign To This College...", "");
  //           this.isSpinning = false;
  //         }
  //         }
  // }

  // // else
  // // {
  // //   this.message.error("Please Fill All Required Fields...","");
  // //   this.isSpinning = false;
  // // }
  //   }


  close(httpForm: NgForm) {
    // this.isSpinning=true;
    this.reset(httpForm);
    this.drawerClose();
  }

  reset(httpForm: NgForm) {
    // this.isSpinning = true;
    httpForm.reset();
  }
}
