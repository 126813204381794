import { Component, Input, OnInit } from '@angular/core';
import { TrainingFeedbackMaster } from 'src/app/Models/TrainingFeedbackMaster';

@Component({
  selector: 'app-addtrainfeedmaster',
  templateUrl: './addtrainfeedmaster.component.html',
  styleUrls: ['./addtrainfeedmaster.component.css']
})

export class AddtrainfeedmasterComponent implements OnInit {
  @Input()
  drawerClose!: Function;
  @Input()
  data: TrainingFeedbackMaster = new TrainingFeedbackMaster;
  @Input()
  drawerVisible: boolean = false;

  constructor() { }

  ngOnInit(): void { }
}
