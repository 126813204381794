export class LeaveType {
    ID: number;
    NAME: string;
    DESCRIPTION: string;
    IS_ACTIVE: boolean;
    ORGANIZATION_ID: number;
    TITLE: string;
    NOTIFICATION_DESCRIPTION: string;
    CLIENT_ID: number;
    LEAVE_APPROVAL_DAYS: string;
}
