import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { CustomerMaster } from 'src/app/Models/customer-master';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})

export class CustomerComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: CustomerMaster;
  isSpinning = false;

  constructor(private api: ApiService, private message: NzNotificationService) { }

  ngOnInit() { }

  close(myForm: NgForm): void {
    this.drawerClose();
    this.reset(myForm);
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  save(addNew: boolean, myForm: NgForm): void {
    var isOk = true;

    if (this.data.NAME != undefined) {
      if (this.data.NAME.trim() != '') {
        if (!this.api.checkTextBoxIsValid(this.data.NAME)) {
          isOk = false;
          this.message.error("Please Enter Valid Location Name", "");
        }
      } else {
        isOk = false;
        this.message.error("Please Enter Valid Location Name", "");
      }
    } else {
      isOk = false;
      this.message.error("Please Enter Valid Location Name", "");
    }

    if (isOk) {
      this.isSpinning = true;
      console.log(this.data);

      if (this.data.ID) {
        this.api.updateCustomer(this.data).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Location Updated Successfully", "");

            if (!addNew)
              this.close(myForm);
            this.isSpinning = false;

          } else {
            this.message.error("Location Updation Failed", "");
            this.isSpinning = false;
          }
        });

      } else {
        this.api.createCustomer(this.data).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Location Created Successfully", "");

            if (!addNew)
              this.close(myForm);

            else {
              this.data = new CustomerMaster();
              this.data.IS_ACTIVE = true;
            }

            this.isSpinning = false;

          } else {
            this.message.error("Location Creation Failed", "");
            this.isSpinning = false;
          }
        });
      }
    }
  }
}
