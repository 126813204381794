import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { TrainerMaster } from 'src/app/Models/trainermaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-addtrainermaster',
  templateUrl: './addtrainermaster.component.html',
  styleUrls: ['./addtrainermaster.component.css']
})

export class AddtrainermasterComponent implements OnInit {
  @Input()
  drawerClose!: Function;
  @Input()
  data: TrainerMaster = new TrainerMaster();
  @Input()
  drawerVisible: boolean = false;

  isSpinning = false;
  // organizations: OrganizationMaster[];
  // roles: RoleMaster[];
  // selectedRole:RoleMaster;
  isOk = true;
  emailpattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  namepatt = /^[a-zA-Z \-\']+/
  mobpattern = /^[6-9]\d{9}$/
  subjects: any[] = []

  constructor(private api: ApiService, private message: NzNotificationService) { }

  ngOnInit() { }

  loadOrganizations() {
    this.isSpinning = false;
    // this.api.getAllOrganizations(0,0,'','','').subscribe(organizations => {
    //   this.organizations = organizations['data'];
    //   this.isSpinning = false;
    // }, err => {
    //   console.log(err);
    //   this.isSpinning = false;
    // });
  }

  // Accessor
  // loadRoles() {
  //   this.isSpinning = false;
  //   // this.api2.getAllRoles(0, 0, '', '', ' AND NAME = "Trainer"' + ' ' + ' AND NAME = "Accessor"').subscribe(roles => {
  //     this.api2.getAllRoles(0, 0, '', '', ' AND NAME = "Trainer"').subscribe(roles => {  
  //   this.subjects = roles['data'];
  //     this.isSpinning = false;
  //   }, err => {
  //     console.log(err);
  //     this.isSpinning = false;
  //   });
  // }

  changeFrom(event) { }

  close(): void {
    this.drawerClose();
  }

  save(addNew: boolean): void {
    this.isOk = true;
    this.isSpinning = false;

    if (this.data.NAME.trim() == "" && this.data.MOBILE_NO != undefined && this.data.EMAIL_ID.trim() == ""
      && this.data.MOBILE_NO != undefined && this.data.PASSWORD.trim() == "" && this.data.IS_ACTIVE) {
      this.isOk = false
      this.message.error("Please Fill All Required Fields", "");

    } else
      if (this.data.NAME == null || this.data.NAME.trim() == '') {
        this.isOk = false
        this.message.error('Please Enter Name', '');

      } else
        if (!this.namepatt.test(this.data.NAME)) {
          this.isOk = false
          this.message.error('Please Enter Valid Name', '');

        } else
          if (this.data.MOBILE_NO == null || this.data.MOBILE_NO <= 0) {
            this.isOk = false
            this.message.error('Please Enter Mobile Number', '');

          } else
            if (!this.mobpattern.test(this.data.MOBILE_NO.toString())) {
              this.isOk = false
              this.message.error('Please Enter Valid Mobile Number', '');

            } else
              if (this.data.EMAIL_ID == null || this.data.EMAIL_ID.trim() == '') {
                this.isOk = false
                this.message.error('Please Enter Email', '');

              } else
                if (!this.emailpattern.test(this.data.EMAIL_ID)) {
                  this.isOk = false
                  this.message.error('Please Enter Valid Email', '');

                } else
                  if (this.data.PASSWORD == null || this.data.PASSWORD.trim() == '') {
                    this.isOk = false
                    this.message.error('Please Enter Email', '');

                  } else
                    if (this.data.ROLE_ID == undefined || this.data.ROLE_ID <= 0) {
                      this.isOk = false
                      this.message.error('Please Select Position  ', '');
                    }

    if (this.isOk) {
      this.data.SUBJECT_ID = 0
      this.isSpinning = true;

      if (this.data.ID != 0) {
        this.api.updatetrainer(this.data).subscribe(successCode => {
          if (successCode.code == "200") {
            this.message.success("Trainer Updated Successfully...", "");

            if (!addNew)
              this.drawerClose();
            this.isSpinning = false;

          } else {
            this.message.error("Trainer Updation Failed...", "");
            this.isSpinning = false;
          }
        });

      } else {
        this.api.createtrainer(this.data).subscribe(successCode => {
          if (successCode.code == "200") {
            this.message.success("Trainer Created Successfully...", "");

            if (!addNew)
              this.drawerClose();

            else {
              this.data = new TrainerMaster();
            }

            this.isSpinning = false;

          } else {
            this.message.error("Trainer Creation Failed...", "");
            this.isSpinning = false;
          }
        });
      }
    }

    // else
    // {
    //   this.message.error("Please Fill All Required Fields...","");
    //   this.isSpinning = false;
    // }
    //  }
  }
}
