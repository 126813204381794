import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { Expenses } from 'src/app/Models/Expenses';
import { SplitExpensesDetails } from 'src/app/Models/SplitExpensesDetails';
import { ApiService } from 'src/app/Service/api.service';
import { HeadExpensesApproveDrawerComponent } from '../head-expenses-approve-drawer/head-expenses-approve-drawer.component';

@Component({
  selector: 'app-expenses-approve-from-head-tiles',
  templateUrl: './expenses-approve-from-head-tiles.component.html',
  styleUrls: ['./expenses-approve-from-head-tiles.component.css']
})

export class ExpensesApproveFromHeadTilesComponent implements OnInit {
  formTitle = "Manage Expenses";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  splitExpensesData1 = new SplitExpensesDetails();
  splitExpensesData2 = new SplitExpensesDetails();
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "DATE";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["STATUS", "Status"], ["NAME", "Employee Name"], ["EXPENSE_HEAD_NAME", "Expense Type"], ["PARTY_NAME", "Party Name"], ["CUST_NAME", "Location"], ["CLAIM_DATE", "Expense Date"], ["DATE", "Claim Date"], ["DESCRIPTION", "Description"], ["AMOUNT", "Amount"], ["APPROVED_AMOUNT", "Approved Amount"], ["REMARK", "Remark"], ["HEAD_STATUS", "Head Status"], ["HEAD_REMARK", "Head Remark"]];
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Expenses = new Expenses();

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private cookie: CookieService) { }

  userId = Number(this.cookie.get('userId'));
  roleId = Number(this.cookie.get('roleId'));
  orgId = Number(this.cookie.get('orgId'));
  deptId = Number(this.cookie.get('deptId'));
  branchId = Number(this.cookie.get('branchId'));
  designationId = Number(this.cookie.get('designationId'));

  ngOnInit() {
    this.search();
    this.date = [];
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    console.log("search text : " + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND (";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')'
    }

    var empFilter = " and (REPORTING_PERSON1=" + this.userId + " or REPORTING_PERSON2=" + this.userId + ")";

    var dateFilter = "";
    if (this.date != undefined && this.date.length != 0) {
      dateFilter = " AND (CLAIM_DATE BETWEEN '" + this.datePipe.transform(this.date[0], 'yyyy-MM-dd') + "' AND '" + this.datePipe.transform(this.date[1], 'yyyy-MM-dd') + "')";
    }

    var status = "";
    if (this.STATUS == "P")
      status = " and STATUS in ('O')";

    else if (this.STATUS == "A")
      status = " and STATUS in ('HA')";

    else if (this.STATUS == "R")
      status = " and STATUS in ('HR')";

    this.api.getAllEmployeeExpenses(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + empFilter + dateFilter + status).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  @ViewChild(HeadExpensesApproveDrawerComponent, { static: false }) HeadExpensesApproveDrawerComponentVar: HeadExpensesApproveDrawerComponent;

  add(): void {
    this.drawerTitle = "Add Expenses";
    this.drawerData = new Expenses();
    this.drawerVisible = true;

    this.HeadExpensesApproveDrawerComponentVar.fileURL = null;
    this.drawerData.DATE = this.datePipe.transform(new Date(), "yyyy-MM-dd");
  }

  edit(data: Expenses): void {
    console.log(data);

    this.drawerTitle = "Approve Expenses from Head";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;

    this.HeadExpensesApproveDrawerComponentVar.fileURL = null;
    this.HeadExpensesApproveDrawerComponentVar.photoURL = this.api.retriveimgUrl + "expenses/" + this.drawerData.ATTACHMENT;

    this.HeadExpensesApproveDrawerComponentVar.APPROVE = true;
    this.HeadExpensesApproveDrawerComponentVar.REMARK = this.drawerData.HEAD_REMARK;

    // Get split Details
    this.HeadExpensesApproveDrawerComponentVar.isSplitTableLoading = true;

    this.api.getAllEmployeeSplitDetails(this.drawerData.ID).subscribe(data => {
      if (data['code'] == 200) {
        this.HeadExpensesApproveDrawerComponentVar.isSplitTableLoading = false;

        this.splitExpensesData1 = data['data'];
        console.log(this.splitExpensesData1);

        this.splitExpensesData2 = data['data'];
        console.log(this.splitExpensesData2);
      }

    }, err => {
      console.log(err);
    });
  }

  drawerClose(): void {
    this.search(true);
    this.drawerVisible = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  filterClass: string = "filter-visible";
  dateFormat = "dd/MMM/yyyy";
  date: Date[] = [];
  date1 = new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + 1;
  filterStatusValue: string[] = ['O'];
  isSpinning = false;
  employeeFilter

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  applyFilter() {
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    if (this.date == undefined || this.date.length == 0) {
      this.message.error("Please Select Date", "");

    } else {
      this.filterQuery = " AND (CLAIM_DATE BETWEEN '" + this.datePipe.transform(this.date[0], 'yyyy-MM-dd') + "' AND '" + this.datePipe.transform(this.date[1], 'yyyy-MM-dd') + "') AND ";
      this.filterQuery = this.filterQuery.substring(0, this.filterQuery.length - 5);

      var empFilter = " and (REPORTING_PERSON1=" + this.userId + " or REPORTING_PERSON2=" + this.userId + ")";

      var status = "";
      if (this.STATUS == "P")
        status = " and STATUS in ('O')";

      else if (this.STATUS == "A")
        status = " and STATUS in ('HA')";

      else if (this.STATUS == "R")
        status = " and STATUS in ('HR')";

      this.isSpinning = true;
      this.api.getAllEmployeeExpenses(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery + empFilter + status).subscribe(data => {
        if (data['code'] == 200) {
          this.isSpinning = false;
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isFilterApplied = "primary";
        }

      }, err => {
        console.log(err);
      });
    }

    this.filterClass = "filter-invisible";
  }

  clearFilter() {
    this.date = [];
    this.filterStatusValue = ['O'];
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.employeeFilter = 1;
    this.filterQuery = "";
    this.search(true);
  }

  STATUS = "P";

  changeRadioButton(earlymarkStatus) {
    this.STATUS = earlymarkStatus;
    this.search(true);
  }

  getTimeInAM_PM(time) {
    return this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + time;
  }

  onSearching() {
    document.getElementById("button1").focus();
    this.search(true);
  }
}
