import { Component, Input, OnInit } from '@angular/core';
import { TrainingFeedbackFormMaster } from 'src/app/Models/TrainingFeedbackFormMaster';

@Component({
  selector: 'app-addtrainfeedformmaster',
  templateUrl: './addtrainfeedformmaster.component.html',
  styleUrls: ['./addtrainfeedformmaster.component.css']
})

export class AddtrainfeedformmasterComponent implements OnInit {
  @Input()
  drawerClose!: Function;
  @Input()
  data: TrainingFeedbackFormMaster = new TrainingFeedbackFormMaster;
  @Input()
  drawerVisible: boolean = false;

  constructor() { }

  ngOnInit(): void { }
}
