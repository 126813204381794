import { Component, OnInit, Input } from '@angular/core';
import { Ticket } from 'src/app/Models/ticket';
import { Ticketdetails } from 'src/app/Models/ticketdetails';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-chattdetailsicket',
  templateUrl: './chattdetailsicket.component.html',
  styleUrls: ['./chattdetailsicket.component.css']
})
export class ChattdetailsicketComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: Ticket;
  @Input() data2: Ticketdetails;
  folderName = "ticket"
  userId = Number(this.cookie.get('userId'))
  ticketDetailsData: Ticketdetails
  DESCRIPTION
  fileDataLOGO_URL: File = null
  ID
  date = new Date();
  date1 = this.datePipe.transform(this.date, 'yyyy-MM-dd HH:mm:ss')
  isSpinning = false;
  imageSrc = '';
  todayDate = new Date();
  constructor(private cookie: CookieService, private datePipe: DatePipe, private api: ApiService, private message: NzNotificationService) { }

  ngOnInit() {

  }
  
  getFormatedDate() {
    var date_ob = new Date();
    let date = ("0" + date_ob.getDate()).slice(-2);
    let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
    let year = date_ob.getFullYear();
    let hours = ("0" + date_ob.getHours()).slice(-2);
    let minutes = ("0" + date_ob.getMinutes()).slice(-2);
    let seconds = ("0" + date_ob.getSeconds()).slice(-2);

    return year + month + date + hours + minutes + seconds;
  }
 

  getUrl(url) {
    if (url)
      return this.api.url + "static/ticket/" + url
    else
      return ""
  }
  urlClick(url) { 
    window.open( this.api.url + "static/ticket/" + url);
  }
 

}
