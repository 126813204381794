import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';
import { Ticketgroup } from 'src/app/Models/ticketgroup';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-optionreport',
  templateUrl: './optionreport.component.html',
  styleUrls: ['./optionreport.component.css']
})
export class OptionreportComponent implements OnInit {
  formTitle = "Option wise summary";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  fileName = 'OptionWise.xlsx';
  dataList = [];
  userId = this.cookie.get('userId')
  roleId = this.cookie.get('roleId')
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";

  columns: string[][] = [["TICKET_GROUP_VALUE", "Option"], ["TOTAL", "Total Tickets Loged"],
  ["ASSIGNED", "Assigned"], ["BANNED", "Banned"], ["CLOSED", "Closed"], ["CREATED", "Created"], ["ON_HOLD", "On-Hold"], ["RE_OPEN", "Re-Open"]]
  STATUS = "AL";
  DEPARTMENT = 'AL';
  SUPPORT_USER = 'AL';
  isSpinning = false
  filterClass: string = "filter-visible";
  applicationId = Number(this.cookie.get('applicationId'))
  departmentId = Number(this.cookie.get('departmentId'))

  selectedDate: Date[] = []
  dateFormat = 'dd/MM/yyyy';

  data1 = [];
  ticketGroups: Ticketgroup[] = []
  index = 0;
  ticketQuestion = {};
  value1: string = ""
  value2: string = ""
  departments = []
  supportusers = []
  constructor(private api: ApiService, private datePipe: DatePipe, private cookie: CookieService) { }

  ngOnInit() {
    this.search();

    this.api.getOptionwiseReport(0, 0, 'NAME', 'ASC', ' AND ORG_ID= ' + this.cookie.get('orgId'), '', '').subscribe(data => {
      this.departments = data['data'];
    }, err => {
      console.log(err);
    });
  }

  // Basic Methods
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  exportexcel(): void {
    // pass here the table id /
    let element = document.getElementById('summer');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    // generate workbook and add the worksheet /
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // save to file /  
    XLSX.writeFile(wb, this.fileName);
  }

  clearFilter() {
    this.DEPARTMENT = "AL";
    this.filterQuery = ""
    this.selectedDate = null;
    this.value1 = ''
    this.value2 = ''
    this.filterClass = "filter-invisible";
    this.applyFilter()
  }

  changeDate(value) {
    this.value1 = this.datePipe.transform(value[0], "yyyy-MM-dd")
    this.value2 = this.datePipe.transform(value[1], "yyyy-MM-dd");
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "id";
      this.sortValue = "desc"
      this.dataList = []
    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)

      var filterQuery = this.filterQuery + likeQuery

      this.api.getOptionwiseReport(this.pageIndex, this.pageSize, this.sortKey, sort, filterQuery, this.applicationId, 0).subscribe(data => {
        console.log(data)
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }, err => {
        console.log(err);
      });
    }
    else {
      this.applyFilter()
    }
  }

  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }

  applyFilter() {
    this.isSpinning = true
    this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    this.filterQuery = ""
    // var filterQuery="AND IS_TAKEN='0' AND STATUS = '"+this.STATUS+"' AND APPLICATION_ID="+this.applicationId + " AND DEPARTMENT_ID="+this.departmentId
    // if (this.value1 == "" && this.value2 == "") {
    //   this.filterQuery = ""
    // }
    // else {
    //   this.filterQuery = " AND ( DATE BETWEEN '" + this.value1 + ":00:00:00" + "' AND '" + this.value2 + ":23:59:59" + "' ) "
    // }

    var department = 0
    if (this.DEPARTMENT == 'AL') {
      department = 0
    }
    else {
      department = Number(this.DEPARTMENT)
      console.log(department)
    }

    this.api.getOptionwiseReport(this.pageIndex, this.pageSize, this.sortKey, sort, '', this.applicationId, department).subscribe(data => {
      console.log(data)
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
      this.isSpinning = false
      this.isFilterApplied = "primary";
      this.filterClass = "filter-invisible";
    }, err => {
      console.log(err);
    });
  }
}


