import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { TrainingSchedule } from 'src/app/Models/trainingschedule';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-trainingtrainermap',
  templateUrl: './trainingtrainermap.component.html',
  styleUrls: ['./trainingtrainermap.component.css']
})

export class TrainingtrainerMapComponent implements OnInit {
  @Input() drawerClose!: Function;
  @Input() data: TrainingSchedule = new TrainingSchedule();
  @Input() trainingtrainermapData: any[] = [];
  @Input() drawerVisible: boolean = false;

  isSpinning = false;
  formTitle = "Training Trainer Map";
  loadingRecords = true;
  emailId = sessionStorage.getItem('emailId');
  traintrainermap: any = [{ SCHEDULE_ID: 1, TRAINER_ID: 1, IS_ACTIVE: 0 }];
  column = [['SCHEDULE_ID', 'Subject'], ['TRAINER_ID', 'Trainer'], ['ATTENDANCE', 'Is Attending']];

  constructor(private api: ApiService, private message: NzNotificationService) { }

  ngOnInit() { }

  close(): void {
    this.drawerClose();
  }

  save() {
    this.isSpinning = true;

    this.api.addBulktrainingTrainerMapping(Number(this.data.ID), this.trainingtrainermapData).subscribe(data => {
      if (data['code'] == '200') {
        this.drawerClose();
        this.drawerVisible = false;

      } else {
        // this.message.error('Something Went wrong.','')
      }

      this.isSpinning = false;

    }, err => {
      this.message.error('Something Went wrong.', '')
      this.isSpinning = false;
    });
  }
}
