import { Component, AfterViewInit, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ExpensesHeadMaster } from 'src/app/Models/expenses-head-master';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-expensehead',
  templateUrl: './expensehead.component.html',
  styleUrls: ['./expensehead.component.css']
})

export class ExpenseheadComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: ExpensesHeadMaster;
  @Input() drawerVisible: boolean;

  isSpinning = false;

  constructor(private api: ApiService, private message: NzNotificationService) { }

  ngOnInit() { }

  close(myForm: NgForm): void {
    this.drawerClose();
    this.reset(myForm);
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  save(addNew: boolean, myForm: NgForm): void {
    var isOk = true;

    if (this.data.NAME != undefined) {
      if (this.data.NAME.trim() != '') {
        if (!this.api.checkTextBoxIsValid(this.data.NAME)) {
          isOk = false;
          this.message.error("Please Enter Valid Expense Head", "");
        }
      } else {
        isOk = false;
        this.message.error("Please Enter Valid Expense Head", "");
      }
    } else {
      isOk = false;
      this.message.error("Please Enter Valid Expense Head", "");
    }

    if (isOk) {
      this.isSpinning = true;

      if (this.data.ID) {
        this.api.updateExpenseHead(this.data).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Expense Head Updated Successfully", "");

            if (!addNew)
              this.close(myForm);
            this.isSpinning = false;

          } else {
            this.message.error("Expense Head Updation Failed", "");
            this.isSpinning = false;
          }
        });

      } else {
        this.api.createExpenseHead(this.data).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Expense Head Created Successfully", "");

            if (!addNew)
              this.close(myForm);

            else {
              this.data = new ExpensesHeadMaster();
              this.data.IS_ACTIVE = true;
            }

            this.isSpinning = false;

          } else {
            this.message.error("Expense Head Creation Failed", "");
            this.isSpinning = false;
          }
        });
      }
    }
  }
}
