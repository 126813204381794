import { Component, OnInit, Input } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { EmployeeMaster } from 'src/app/Models/employeemaster';

@Component({
  selector: 'app-employeecollegemapping',
  templateUrl: './employeecollegemapping.component.html',
  styleUrls: ['./employeecollegemapping.component.css']
})

export class EmployeecollegemappingComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: EmployeeMaster;
  @Input() employeeCollegesData: string[];
  @Input() drawerVisible: boolean;

  isSpinning = false;
  loadingRecords = true;

  constructor(private api: ApiService, private message: NzNotificationService) { }

  ngOnInit() { }

  close(): void {
    this.drawerClose();
  }

  save() {
    this.isSpinning = true;
    console.log(this.employeeCollegesData);

    this.api.addEmployeeCollegeDetails(this.data.ID, this.employeeCollegesData).subscribe(successCode => {
      if (successCode['code'] == "200") {
        this.message.success("College Details added Successfully", "");

        this.drawerClose();
        this.isSpinning = false;

      } else {
        this.message.error("College Details assigning Failed", "");
        this.isSpinning = false;
      }
    });
  }
}