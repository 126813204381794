import { Component, OnInit } from '@angular/core';
import { PayrollItemMaster } from 'src/app/Models/payrollitem';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-payrollitemlist',
  templateUrl: './payrollitemlist.component.html',
  styleUrls: ['./payrollitemlist.component.css']
})

export class PayrollitemlistComponent implements OnInit {
  drawerVisible!: boolean;
  drawerTitle!: string;
  drawerData: PayrollItemMaster = new PayrollItemMaster();
  formTitle = "Payroll Item Master";
  loadingRecords = true;
  totalRecords = 1;
  pageIndex = 1;
  pageSize = 10;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["NAME", " Item Name "], ["PARENT_NAME", " Parent Name "], ["TYPE", " Type "], ["AMOUNT", " Amount "], ["BASED_ON", " Based On "],];

  dataList =
    [
      { "ID": 1, "CLIENT_ID": 1, "NAME": "WD", "PARENT_ID": 0, "TYPE": "F", "AMOUNT": 2000, "BASED_ON": 0, "PERCENT": 0, "STATUS": true, "IS_ACTIVE": true },
      { "ID": 2, "CLIENT_ID": 1, "NAME": "PD", "PARENT_ID": 0, "TYPE": "F", "AMOUNT": 1000, "BASED_ON": 0, "PERCENT": 0, "STATUS": true, "IS_ACTIVE": true },
      { "ID": 3, "CLIENT_ID": 1, "NAME": "Basic", "PARENT_ID": 0, "TYPE": "F", "AMOUNT": 18000, "BASED_ON": 0, "PERCENT": 0, "STATUS": true, "IS_ACTIVE": true },
      { "ID": 4, "CLIENT_ID": 1, "NAME": "DA", "PARENT_ID": 0, "TYPE": "B", "AMOUNT": 500, "BASED_ON": 3, "PERCENT": 10, "STATUS": true, "IS_ACTIVE": true },
      { "ID": 5, "CLIENT_ID": 1, "NAME": "HRA", "PARENT_ID": 0, "TYPE": "B", "AMOUNT": 500, "BASED_ON": 3, "PERCENT": 15, "STATUS": true, "IS_ACTIVE": true },
      { "ID": 6, "CLIENT_ID": 1, "NAME": "CON", "PARENT_ID": 0, "TYPE": "F", "AMOUNT": 500, "BASED_ON": 0, "PERCENT": 0, "STATUS": true, "IS_ACTIVE": true },
      { "ID": 7, "CLIENT_ID": 1, "NAME": "Medical", "PARENT_ID": 0, "TYPE": "F", "AMOUNT": 400, "BASED_ON": 0, "PERCENT": 0, "STATUS": true, "IS_ACTIVE": true },
      { "ID": 8, "CLIENT_ID": 1, "NAME": "TEL", "PARENT_ID": 0, "TYPE": "F", "AMOUNT": 100, "BASED_ON": 0, "PERCENT": 0, "STATUS": true, "IS_ACTIVE": true },
      { "ID": 9, "CLIENT_ID": 1, "NAME": "UNI", "PARENT_ID": 0, "TYPE": "F", "AMOUNT": 100, "BASED_ON": 0, "PERCENT": 0, "STATUS": true, "IS_ACTIVE": true },
      { "ID": 10, "CLIENT_ID": 1, "NAME": "WAS", "PARENT_ID": 0, "TYPE": "F", "AMOUNT": 100, "BASED_ON": 0, "PERCENT": 0, "STATUS": true, "IS_ACTIVE": true },
    ]

  constructor(private api: ApiService) { }

  ngOnInit(): void {
    this.loadingRecords = false;
  }

  keyup(event: any) {
    this.search();
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "id";
      this.sortValue = "desc";
    }

    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }

    this.api.getAllPayrollItem(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];

      if (this.totalRecords == 0) {
        data.SEQUENCE_NO = 1;

      } else {
        data.SEQUENCE_NO = this.dataList[this.dataList.length - 1]['SEQUENCE_NO'] + 1
      }

    }, err => {
      console.log(err);
    });
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  add(): void {
    this.drawerTitle = "Add New Payroll Item";
    this.drawerData = new PayrollItemMaster();
    // this.api.getAllPayrollItem(1,1,'SEQUENCE_NO','desc','').subscribe (data =>{
    //   if (data['count']==0){
    //     this.drawerData.SEQUENCE_NO=1;
    //   }else
    //   {
    //     this.drawerData.SEQUENCE_NO=data['data'][0]['SEQUENCE_NO']+1;
    //   }
    // },err=>{
    //   console.log(err);
    // })

    this.drawerVisible = true;
  }

  edit(data: PayrollItemMaster): void {
    this.drawerTitle = "Update Payroll Item";
    this.drawerData = Object.assign({}, data);
    console.log(this.drawerData);
    // this.drawerData.TIME= new Date('01-01-1970 '+this.drawerData.TIME)
    this.drawerVisible = true;
  }

  drawerClose(): void {
    this.dataList
    this.drawerVisible = false;
  }

  // sort(params: NzTableQueryParams): void {
  //     const { pageSize, pageIndex, sort} = params;
  //     const currentSort = sort.find(item => item.value !== null);
  //     const sortField = (currentSort && currentSort.key) || 'id';
  //     const sortOrder = (currentSort && currentSort.value) || 'asc';
  //     console.log(currentSort);

  //     console.log("sortOrder :"+sortOrder);
  //     this.pageIndex = pageIndex;
  //     this.pageSize = pageSize;

  //     if(this.pageSize != pageSize) {
  //       this.pageIndex = 1;
  //       this.pageSize = pageSize;
  //     }    

  //     if( this.sortKey != sortField) {
  //       this.pageIndex = 1;
  //       this.pageSize =pageSize;
  //     }

  //     this.sortKey = sortField;
  //     this.sortValue = sortOrder;
  //     this.search();
  //   }
}