import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';
import { Ticketgroup } from 'src/app/Models/ticketgroup';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-userwisesummary',
  templateUrl: './userwisesummary.component.html',
  styleUrls: ['./userwisesummary.component.css'],
  providers: [DatePipe]
})
export class UserwisesummaryComponent implements OnInit {
  formTitle = "User wise summary";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  userId = this.cookie.get('userId')
  roleId = this.cookie.get('roleId')
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  fileName= 'UserWise.xlsx';
  isFilterApplied: string = "default";
  columns: string[][] = [["CREATOR_EMPLOYEE_NAME", "User Name"], ["TOTAL", "Total Tickets Loged"],
  ["CLOSED", "Closed"], ["OPENED", "Open"], ["ANSWERED", "Answered"],["CLIENT_REPLIED", "Re-Questioned"]]
  STATUS = "AL";
  DEPARTMENT = 'AL';
  SUPPORT_USER = 'AL';
  isSpinning = false
  filterClass: string = "filter-visible";
  applicationId = Number(this.cookie.get('applicationId'))
  departmentId = Number(this.cookie.get('departmentId'))

  selectedDate: Date[] = []
  dateFormat = 'dd/MM/yyyy';

  data1 = [];
  ticketGroups: Ticketgroup[] = []
  index = 0;
  ticketQuestion = {};
  fromDate: string = ""
  toDate: string = ""
  departments = []
  supportusers = []
  constructor(private api: ApiService, private datePipe: DatePipe, private cookie: CookieService) { }

  ngOnInit() {
    this.search();

    this.api.getAllDepartments(0, 0, 'NAME', 'ASC',' AND ORG_ID= '+this.cookie.get('orgId')).subscribe(data => {
      this.departments = data['data'];
    }, err => {
      console.log(err);
    });


    this.supportusers = [];
    this.SUPPORT_USER = 'AL'
     var data2:any[] = []
    this.api.getAllEmpRoleMap(0, 0, '', '', ' AND ROLE_ID= 4').subscribe(data => {
      data2 = []
      if (data['code'] == 200) {
        console.log(data)
        for (var i = 0; i < data['data'].length; i++) {
          data2.push(data['data'][i]['EMPLOYEE_ID'])
          if (data['data'].length == i + 1) {
            if (data2.length > 0)
              this.api.getAllemployeeMaster(0, 0, '', '', ' AND ORG_ID  = ' + this.cookie.get('orgId') + ' AND ID in(' + data2.toString() + ')').subscribe(data => {
                this.supportusers = data['data'];
              }, err => {
                console.log(err);
              });
          }
        }

      }
    });
  }
  exportexcel(): void
  {
    // pass here the table id /
    let element = document.getElementById('summer');
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
 
   // generate workbook and add the worksheet /
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
 
    // save to file /  
    XLSX.writeFile(wb, this.fileName);
 
  }  

  // Basic Methods
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }
  clearFilter() {
    this.DEPARTMENT = "AL";
    this.SUPPORT_USER = "AL";
    this.filterQuery = ""
    this.selectedDate = null;
    this.fromDate = ''
    this.toDate = ''
    this.filterClass = "filter-invisible";
    this.applyFilter()
  }

  changeDate(value) {
    this.fromDate = this.datePipe.transform(value[0], "yyyy-MM-dd")
    this.toDate = this.datePipe.transform(value[1], "yyyy-MM-dd");
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "id";
      this.sortValue = "desc"
      this.dataList = []
    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)

      // var filterQuery = this.filterQuery  + " AND DEPARTMENT_ID=" + this.departmentId + likeQuery

      var filterQuery = this.filterQuery + likeQuery

      this.api.getUserwiseReport(this.pageIndex, this.pageSize, this.sortKey, sort, filterQuery + ' AND  ORG_ID =' + this.cookie.get('orgId'),this.applicationId,0).subscribe(data => {
        console.log(data)
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }, err => {
        console.log(err);
      });
    }
    else {
      this.applyFilter()
    }
  }

  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }

  applyFilter() {
    this.isSpinning = true
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    
    this.filterQuery = ""
    if (this.fromDate == "" && this.toDate == "") {
      this.filterQuery = ""
    }
    else {
      this.filterQuery = " AND ( DATE BETWEEN '" + this.fromDate + ":00:00:00" + "' AND '" + this.toDate + ":23:59:59" + "' ) "
    }

    if (this.SUPPORT_USER == 'AL') {
      this.filterQuery = this.filterQuery
    }
    else {
      this.filterQuery += " AND TAKEN_BY_USER_ID='" + this.SUPPORT_USER + "'"
    }


    // var filterQuery = this.filterQuery + " AND APPLICATION_ID=" + this.applicationId + " AND DEPARTMENT_ID=" + this.departmentId;

    var department = 0
    if (this.DEPARTMENT == 'AL') {
      department = 0
     
    }
    else {
      department =  Number(this.DEPARTMENT)
      console.log(department)
    }
    

    this.api.getUserwiseReport(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery + ' AND  ORG_ID =' + this.cookie.get('orgId'),this.applicationId,department).subscribe(data => {
      console.log(data)
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
      this.isSpinning = false
      this.isFilterApplied = "primary";
      this.filterClass = "filter-invisible";
    }, err => {
      console.log(err);
    });
  }



}

