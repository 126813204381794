import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { folderMaster } from 'src/app/Models/folderMaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-sharing-existing-folder',
  templateUrl: './sharing-existing-folder.component.html',
  styleUrls: ['./sharing-existing-folder.component.css']
})

export class SharingExistingFolderComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: [];
  @Input() folderData: any;

  isSpinning = false;
  loadingForm = false;
  loadingRecords = false;

  forms: folderMaster[];
  searchText = "";

  CLUSTER_ID = 0;
  DEPARTMENT_ID = 0;
  ROLE_ID = 0;
  DESIGNATION_ID = 0;
  BRANCH_ID = 0;
  ACCESS_MODE = 'E';
  sharingMode = "I";

  constructor(private api: ApiService, private message: NzNotificationService, private cookie: CookieService) { }

  ngOnInit() { }

  folderShareData = [];
  employeeList = [];

  userId = Number(this.cookie.get('userId'));
  roleId = Number(this.cookie.get('roleId'));
  orgId = Number(this.cookie.get('orgId'));
  deptId = Number(this.cookie.get('deptId'));
  branchId = Number(this.cookie.get('branchId'));
  designationId = Number(this.cookie.get('designationId'));

  heading = "";

  individualGrid = false;
  deptGrid = false;
  branchGrid = false;
  designationGrid = false;

  changeRadioButton(btnValue) {
    this.USER_IDS = [];
    console.log(this.folderData);

    if (btnValue == 'I') {
      this.heading = "Select Employee";

      this.individualGrid = true;
      this.deptGrid = false;
      this.branchGrid = false;
      this.designationGrid = false;

      this.api.getSubordinateList(this.folderData.ID, this.userId, this.roleId, this.orgId).subscribe(data => {
        if (data['code'] == 200) {
          this.employeeList = data['data'];
          console.log(this.employeeList);
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });

      this.refreshGrid(1);

    } else if (btnValue == 'D') {
      this.heading = "Select Department";

      this.individualGrid = false;
      this.deptGrid = true;
      this.branchGrid = false;
      this.designationGrid = false;

      this.api.getSubordinateDepartmentList(this.folderData.ID, this.userId, this.orgId).subscribe(data => {
        if (data['code'] == 200) {
          this.employeeList = data['data'];
          console.log(this.employeeList);
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });

      this.refreshGrid(2);

    } else if (btnValue == 'B') {
      this.heading = "Select Branch";

      this.individualGrid = false;
      this.deptGrid = false;
      this.branchGrid = true;
      this.designationGrid = false;

      this.api.getSubordinateBranchList(this.folderData.ID, this.userId, this.orgId).subscribe(data => {
        if (data['code'] == 200) {
          this.employeeList = data['data'];
          console.log(this.employeeList);
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });

      this.refreshGrid(3);

    } else if (btnValue == 'DE') {
      this.heading = "Select Designation";

      this.individualGrid = false;
      this.deptGrid = false;
      this.branchGrid = false;
      this.designationGrid = true;

      this.api.getSubordinateDesignationList(this.folderData.ID, this.userId, this.orgId).subscribe(data => {
        if (data['code'] == 200) {
          this.employeeList = data['data'];
          console.log(this.employeeList);
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });

      this.refreshGrid(4);
    }
  }

  close(): void {
    this.drawerClose();
  }

  USER_IDS = [];
  ACCESS_TYPE = "E";

  save(addNew: boolean): void {
    var isOk = true;

    if (this.individualGrid) {
      if (this.USER_IDS.length == 0) {
        isOk = false;
        this.message.error("Please Select Valid User", "");
      }

      if (this.ACCESS_TYPE == undefined) {
        isOk = false;
        this.message.error("Please Select Valid User Access Type", "");
      }

      if (isOk) {
        this.isSpinning = true;
        var a = [];

        if (this.USER_IDS.length != 0) {
          for (var i = 0; i < this.USER_IDS.length; i++) {
            var obj1 = new Object();
            obj1['EMPLOYEE_ID'] = this.USER_IDS[i];
            obj1['ACCESS_TYPE'] = this.ACCESS_TYPE;

            a.push(Object.assign({}, obj1));
          }

        } else {
          var obj1 = new Object();
          obj1['EMPLOYEE_ID'] = this.USER_IDS;
          obj1['ACCESS_TYPE'] = this.ACCESS_TYPE;

          a.push(Object.assign({}, obj1));
        }

        this.api.folderSharigDetailsAddBulk(this.folderData.ID, 1, this.userId, a).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.isSpinning = false;
            this.message.success("Shared Status Added Successfully", "");
            this.changeRadioButton('I');

          } else {
            this.message.error("Failed to Add Shared Status", "");
          }

        }, err => {
          if (err['ok'] == false)
            this.message.error("Failed to Add Shared Status", "");
        });
      }

    } else if (this.deptGrid) {
      if (this.USER_IDS.length == 0) {
        isOk = false;
        this.message.error("Please Select Valid Department", "");
      }

      if (this.ACCESS_TYPE == undefined) {
        isOk = false;
        this.message.error("Please Select Valid User Access Type", "");
      }

      if (isOk) {
        this.isSpinning = true;
        var a = [];

        if (this.USER_IDS.length != 0) {
          for (var i = 0; i < this.USER_IDS.length; i++) {
            var obj1 = new Object();
            obj1['DEPARTMENT_ID'] = this.USER_IDS[i];
            obj1['ACCESS_TYPE'] = this.ACCESS_TYPE;

            a.push(Object.assign({}, obj1));
          }

        } else {
          var obj1 = new Object();
          obj1['DEPARTMENT_ID'] = this.USER_IDS;
          obj1['ACCESS_TYPE'] = this.ACCESS_TYPE;

          a.push(Object.assign({}, obj1));
        }

        this.api.folderSharigDetailsAddBulk(this.folderData.ID, 2, this.userId, a).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.isSpinning = false;
            this.message.success("Shared Status Added Successfully", "");
            this.changeRadioButton('D');

          } else {
            this.message.error("Failed to Add Shared Status", "");
          }

        }, err => {
          if (err['ok'] == false)
            this.message.error("Failed to Add Shared Status", "");
        });
      }

    } else if (this.branchGrid) {
      if (this.USER_IDS.length == 0) {
        isOk = false;
        this.message.error("Please Select Valid Branch", "");
      }

      if (this.ACCESS_TYPE == undefined) {
        isOk = false;
        this.message.error("Please Select Valid User Access Type", "");
      }

      if (isOk) {
        this.isSpinning = true;
        var a = [];

        if (this.USER_IDS.length != 0) {
          for (var i = 0; i < this.USER_IDS.length; i++) {
            var obj1 = new Object();
            obj1['BRANCH_ID'] = this.USER_IDS[i];
            obj1['ACCESS_TYPE'] = this.ACCESS_TYPE;

            a.push(Object.assign({}, obj1));
          }

        } else {
          var obj1 = new Object();
          obj1['BRANCH_ID'] = this.USER_IDS;
          obj1['ACCESS_TYPE'] = this.ACCESS_TYPE;

          a.push(Object.assign({}, obj1));
        }

        this.api.folderSharigDetailsAddBulk(this.folderData.ID, 3, this.userId, a).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.isSpinning = false;
            this.message.success("Shared Status Added Successfully", "");
            this.changeRadioButton('B');

          } else {
            this.message.error("Failed to Add Shared Status", "");
          }

        }, err => {
          if (err['ok'] == false)
            this.message.error("Failed to Add Shared Status", "");
        });
      }

    } else if (this.designationGrid) {
      if (this.USER_IDS.length == 0) {
        isOk = false;
        this.message.error("Please Select Valid Designation", "");
      }

      if (this.ACCESS_TYPE == undefined) {
        isOk = false;
        this.message.error("Please Select Valid User Access Type", "");
      }

      if (isOk) {
        this.isSpinning = true;
        var a = [];

        if (this.USER_IDS.length != 0) {
          for (var i = 0; i < this.USER_IDS.length; i++) {
            var obj1 = new Object();
            obj1['DESIGNATION_ID'] = this.USER_IDS[i];
            obj1['ACCESS_TYPE'] = this.ACCESS_TYPE;

            a.push(Object.assign({}, obj1));
          }

        } else {
          var obj1 = new Object();
          obj1['DESIGNATION_ID'] = this.USER_IDS;
          obj1['ACCESS_TYPE'] = this.ACCESS_TYPE;

          a.push(Object.assign({}, obj1));
        }

        this.api.folderSharigDetailsAddBulk(this.folderData.ID, 4, this.userId, a).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.isSpinning = false;
            this.message.success("Shared Status Added Successfully", "");
            this.changeRadioButton('DE');

          } else {
            this.message.error("Failed to Add Shared Status", "");
          }

        }, err => {
          if (err['ok'] == false)
            this.message.error("Failed to Add Shared Status", "");
        });
      }
    }
  }

  changeAccessType(accesType, data) {
    console.log(data);

    data.ACCESS_TYPE = accesType;

    this.api.updateFolderSharingDetails(data).subscribe(successCode => {
      if (successCode['code'] == "200") {
        this.message.success("Shared Access Updated Successfully", "");

      } else {
        this.message.error("Failed to Update Shared Access", "");
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Failed to Update Shared Access", "");
    });
  }

  deleteSharingDetails(data) {
    this.api.removeFolderSharingDetails(data).subscribe(successCode => {
      if (successCode['code'] == "200") {
        this.message.success("Shared Access Removed Successfully", "");

        if (this.individualGrid) {
          this.changeRadioButton('I');

        } else if (this.deptGrid) {
          this.changeRadioButton('D');

        } else if (this.branchGrid) {
          this.changeRadioButton('B');

        } else if (this.designationGrid) {
          this.changeRadioButton('DE');
        }

      } else {
        this.message.error("Failed to Remove Shared Access", "");
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Failed to Remove Shared Access", "");
    });
  }

  refreshGrid(typeId) {
    this.loadingRecords = true;

    if (typeId == 1) {
      this.api.getAllFolderSharigDetails(0, 0, '', '', " and EMPLOYEE_ID<>" + this.userId + " and FOLDER_ID in (SELECT S.ID FROM ( (select id from (select * from folder_master order by PARENT_ID desc , id desc) products_sorted, (select @pv := " + this.folderData.ID + ") initialisation where find_in_set(id, @pv) and length(@pv := concat(@pv, ',', parent_id)))) S) and SHARING_TYPE=" + typeId).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          console.log(data);
          this.data = data['data'];
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });

    } else {
      this.api.getAllFolderSharigDetails(0, 0, '', '', " and FOLDER_ID in (SELECT S.ID FROM ( (select id from (select * from folder_master order by PARENT_ID desc , id desc) products_sorted, (select @pv := " + this.folderData.ID + ") initialisation where find_in_set(id, @pv) and length(@pv := concat(@pv, ',', parent_id)))) S) and SHARING_TYPE=" + typeId).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          console.log(data);
          this.data = data['data'];
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });
    }

    this.USER_IDS = [];
  }

  btnIndividualStatus = false;
  btnDepartmentStatus = false;
  btnBranchStatus = false;
  btnDesignationStatus = false;

  disableRadioButtons() {
    if (this.roleId == 11) {
      this.btnIndividualStatus = true;
      this.btnDepartmentStatus = true;
      this.btnBranchStatus = true;
      this.btnDesignationStatus = true;

    } else {
      this.btnIndividualStatus = true;
      this.btnDepartmentStatus = false;
      this.btnBranchStatus = false;
      this.btnDesignationStatus = false;

      if (this.deptId == 0) {
        this.btnDepartmentStatus = true;
      }

      if (this.designationId == 0) {
        this.btnDesignationStatus = true;
      }

      if (this.branchId == 0) {
        this.btnBranchStatus = true;
      }
    }
  }

  getInitial(empName) {
    let initial: string = empName.charAt(0);
    return initial.trim();
  }
}
