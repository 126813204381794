import { Component, OnInit, Input } from '@angular/core';
import { Ticket } from 'src/app/Models/ticket';
import { Ticketdetails } from 'src/app/Models/ticketdetails';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-viewchatticket',
  templateUrl: './viewchatticket.component.html',
  styleUrls: ['./viewchatticket.component.css'],
  providers: [DatePipe]
})

export class ViewchatticketComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: Ticket;
  @Input() data2: Ticketdetails;
  @Input() newstr = ''
  folderName = "ticket"
  userId = Number(this.cookie.get('userId'))
  ticketDetailsData: Ticketdetails
  DESCRIPTION
  fileDataLOGO_URL: File = null
  ID

  date1: string;
  isSpinning = false;
  loading = false;
  imageSrc = '';
  todayDate = new Date();

  constructor(private cookie: CookieService, private datePipe: DatePipe, private api: ApiService, private message: NzNotificationService) { }

  ngOnInit() {
    this.getAllEmployee();
  }

  empList = [];

  getAllEmployee() {
    this.empList = [];

    this.api.getAllemployeeMaster(0, 0, 'ID', 'desc', ' AND  ORG_ID =' + this.cookie.get('orgId')).subscribe(data => {
      if (data['code'] == 200) {
        this.empList = data['data'];
        console.log(this.empList);
      }

    }, err => {
      console.log(err);
    });
  }

  getSenderEmpName(senderEmpID): any {
    let empName = '';

    this.empList.filter(obj => {
      if (obj.ID == senderEmpID) {
        empName = obj['NAME'];
      }
    });

    return empName;
  }

  getFormatedDate() {
    var date_ob = new Date();
    let date = ("0" + date_ob.getDate()).slice(-2);
    let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
    let year = date_ob.getFullYear();
    let hours = ("0" + date_ob.getHours()).slice(-2);
    let minutes = ("0" + date_ob.getMinutes()).slice(-2);
    let seconds = ("0" + date_ob.getSeconds()).slice(-2);

    return year + month + date + hours + minutes + seconds;
  }

  send(data1: Ticket) {
    this.date1 = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
    var LOGO_URL = '';

    if (this.DESCRIPTION != undefined && this.DESCRIPTION.trim() != '') {
      this.isSpinning = true;

      if (this.fileDataLOGO_URL) {
        var number = Math.floor(100000 + Math.random() * 900000);
        var fileExt = this.fileDataLOGO_URL.name.split('.').pop();
        var Dates = new Date();
        var formatedDate = this.datePipe.transform(Dates, 'yyyyMMddHHmmss');
        var url = formatedDate + number + "." + fileExt;

        this.api.onUpload2(this.folderName, this.fileDataLOGO_URL, url).subscribe(successCode => {
          if (successCode['code'] == "200") {
            LOGO_URL = url;

            var ticketDetailsData1 = {
              ID: 0,
              SENDER: "U",
              CLIENT_ID: this.api.clientId,
              SENDER_ID: +this.userId,
              TICKET_MASTER_ID: data1.ID,
              DESCRIPTION: this.DESCRIPTION,
              URL: LOGO_URL
            }

            this.api.createTicketDetail(ticketDetailsData1).subscribe(successCode => {
              this.isSpinning = false;
              if (successCode['code'] == "200") {
                this.drawerClose()
                this.isSpinning = false;
                this.DESCRIPTION = ""
                this.fileDataLOGO_URL = null;
                this.imageSrc = '';
                this.message.success("Sent successfully", "");

                data1.LAST_RESPONDED = this.date1;
                data1['KEY'] = 'USER';

                this.api.updateTicket(data1).subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    this.drawerClose()
                    this.fileDataLOGO_URL = null;
                    this.DESCRIPTION = ''
                    //this.message.success("Faq information added successfully...", "");
                  }
                  else {
                    //this.message.error("Failed to add faq information...", "");
                  }
                });
              }
              else {
                this.isSpinning = false;
                this.message.error("Failed to Send", "");
              }
            });
          }
          else {
            this.isSpinning = false;
            this.message.error("Failed to upload file", "");
          }
        });

      } else {
        var ticketDetailsData1 = {
          ID: 0,
          SENDER: "U",
          CLIENT_ID: this.api.clientId,
          SENDER_ID: +this.userId,
          TICKET_MASTER_ID: data1.ID,
          DESCRIPTION: this.DESCRIPTION,
          URL: this.genarateKeyLOGO_URL()
        }

        this.api.createTicketDetail(ticketDetailsData1).subscribe(successCode => {
          this.isSpinning = false;
          if (successCode['code'] == "200") {
            this.drawerClose()
            this.isSpinning = false;
            this.DESCRIPTION = ""
            this.fileDataLOGO_URL = null;
            this.imageSrc = '';
            this.message.success("Sent successfully", "");

            data1.LAST_RESPONDED = this.date1;
            data1['KEY'] = 'USER';

            this.api.updateTicket(data1).subscribe(successCode => {
              if (successCode['code'] == "200") {
                this.drawerClose()
                this.fileDataLOGO_URL = null;
                this.DESCRIPTION = ''
                //this.message.success("Faq information added successfully...", "");
              }
              else {
                //this.message.error("Failed to add faq information...", "");
              }
            });
          }
          else {
            this.isSpinning = false;
            this.message.error("Failed to Send", "");
          }
        });
      }
    } else {
      this.message.error("Please mention your problem.", "");
    }
  }

  send2(data1: Ticket) {
    this.isSpinning = true
    var ticketDetailsData1 = {
      ID: 0,
      SENDER: "U",
      CLIENT_ID: this.api.clientId,
      SENDER_ID: +this.userId,
      TICKET_MASTER_ID: data1.ID,
      DESCRIPTION: this.DESCRIPTION,
      URL: this.genarateKeyLOGO_URL()
    }

    this.api.createTicketDetail(ticketDetailsData1).subscribe(successCode => {
      if (successCode['code'] == "200") {
        //  this.load(data1.ID)
        this.drawerClose()
        this.isSpinning = false
        this.DESCRIPTION = ""
      }
      else {
        this.message.error("Failed", "");
      }
    });

    // data1.STATUS = "R"
    data1.LAST_RESPONDED = this.date1;
    data1['KEY'] = 'USER';
    this.api.updateTicket(data1).subscribe(successCode => {
      if (successCode['code'] == "200") {
        this.drawerClose()
        this.fileDataLOGO_URL = null;
        this.DESCRIPTION = ''
        //this.message.success("Faq information added successfully...", "");
      }
      else {
        //this.message.error("Failed to add faq information...", "");
      }
    });
  }

  takeTicket(data: Ticket) {
    data.IS_TAKEN = true
    data.TAKEN_BY_USER_ID = +this.userId

    this.api.updateTicket(data)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          this.drawerClose()
          this.fileDataLOGO_URL = null;
          this.DESCRIPTION = ''
          //this.message.success("Faq information added successfully...", "");
        }
        else {
          //this.message.error("Failed to add faq information...", "");
        }
      });
  }


  reopenTicket(data: Ticket) {

    data.STATUS = "O";
    data['KEY'] = 'USER';
    this.api.updateTicket(data)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          this.drawerClose()
          this.fileDataLOGO_URL = null;
          this.DESCRIPTION = ''
          //this.message.success("Faq information added successfully...", "");
        }
        else {
          //this.message.error("Failed to add faq information...", "");
        }
      });
  }

  genarateKeyLOGO_URL() {
    if (this.fileDataLOGO_URL) {
      var number = Math.floor(100000 + Math.random() * 900000)
      var fileExt = this.fileDataLOGO_URL.name.split('.').pop();
      var url = this.date1 + number + "." + fileExt
      console.log(this.fileDataLOGO_URL)
      this.api.onUpload2(this.folderName, this.fileDataLOGO_URL, url)
      var LOGO_URL = url;
      return LOGO_URL
    }
    else {
      return ""
    }
  }

  getUrl(url) {
    if (url)
      return this.api.baseUrl + "static/ticket/" + url
    else
      return ""
  }

  urlClick(url) {
    window.open(this.api.baseUrl + "static/ticket/" + url);
  }

  clearImg() {
    this.fileDataLOGO_URL = null
  }

  onFileSelectedLOGO_URL(event) {
    const reader = new FileReader();
    this.fileDataLOGO_URL = event.target.files[0];
    reader.readAsDataURL(this.fileDataLOGO_URL);

    reader.onload = () => {
      this.imageSrc = reader.result as string;
    };
  }

  closeTicket(data: Ticket) {
    data.STATUS = "C"
    data['KEY'] = 'USER';
    this.api.updateTicket(data)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          this.drawerClose()
          this.fileDataLOGO_URL = null;
          this.DESCRIPTION = ''
          //this.message.success("Faq information added successfully...", "");
        }
        else {
          //this.message.error("Failed to add faq information...", "");
        }
      });
  }
}
