import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { AssetMapping } from 'src/app/Models/AssetMapping';
import { ApiService } from 'src/app/Service/api.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-asset-mapping-drawer',
  templateUrl: './asset-mapping-drawer.component.html',
  styleUrls: ['./asset-mapping-drawer.component.css']
})

export class AssetMappingDrawerComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: AssetMapping;
  @Input() drawerVisible: boolean;

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.getParentAssetCategory();
  }

  categories = [];

  getParentAssetCategory() {
    this.categories = [];

    this.api.getAllAssetCategory(0, 0, 'NAME', 'asc', ' and PARENT_ID=0').subscribe(data => {
      if (data['code'] == 200) {
        this.categories = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  close(myForm: NgForm) {
    this.drawerClose();
    this.reset(myForm);
    this.getParentAssetCategory();
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  isSpinning = false;

  save(addNew: boolean, myForm: NgForm): void {
    var isOk = true;

    // if (this.data.ALLOCATED_QUANTITY != undefined) {
    //   if (this.data.ALLOCATED_QUANTITY != "0") {
    //     if (Number(this.data.ALLOCATED_QUANTITY) > Number(this.data.REQUESTED_QUANTITY)) {
    //       isOk = false;
    //       this.message.error("Allocating Quantity is higher than Requested Quantity", "");
    //     }

    //   } else {
    //     isOk = false;
    //     this.message.error("Please Enter Valid Allocating Quantity", "");
    //   }

    // } else {
    //   isOk = false;
    //   this.message.error("Please Enter Allocation Quantity", "");
    // }

    if (this.data.STATUS == undefined) {
      isOk = false;
      this.message.error("Please Select Valid Status", "");
    }

    // if (this.data.RETURNED_DATETIME == undefined) {
    //   isOk = false;
    //   this.message.error("Please Select Valid Returned Date Time", "");
    // }

    if (isOk) {
      this.isSpinning = true;

      this.data.STATUS = "A";
      this.data.ALLOCATED_QUANTITY = "1";
      this.data.RETURNED_DATETIME = this.datePipe.transform(this.data.RETURNED_DATETIME, "yyyy-MM-dd HH:mm") + ":00";
      this.data.NEW_EMPLOYEE_ID = 0;
      this.data.APPROVER_ID = this.api.userId;
      if (this.data.REMARK == undefined || this.data.REMARK.trim() == "")
        this.data.REMARK = "No Remark";

      console.log(this.data);

      if (this.data.ID) {
        this.api.allocatingAsset(this.data).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Asset Allocated Successfully", "");
            this.isSpinning = false;
            this.close(myForm);

          } else {
            this.message.error("Asset Allocation Failed", "");
            this.isSpinning = false;
          }
        });
      }
    }
  }

  today = new Date();

  disabledStartDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) < 0;

  omit(event: any) {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
