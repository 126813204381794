import { Component, OnInit, ViewChild } from '@angular/core';
import { ThoughMaster } from 'src/app/Models/though-master';
import { ApiService } from 'src/app/Service/api.service';
import { ThoughComponent } from '../though/though.component';

@Component({
  selector: 'app-thoughs',
  templateUrl: './thoughs.component.html',
  styleUrls: ['./thoughs.component.css']
})

export class ThoughsComponent implements OnInit {
  formTitle = "Manage Thoughts";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["NAME", "Name"], ["DESCRIPTION", "Description"]]

  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: ThoughMaster = new ThoughMaster();

  constructor(private api: ApiService) { }

  ngOnInit() {
    this.search();
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      console.log("likeQuery" + likeQuery);
    }

    this.api.getAllThough(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  @ViewChild(ThoughComponent, { static: false }) ThoughComponentVar: ThoughComponent;

  add(): void {
    this.drawerTitle = "Create New Though";
    this.drawerData = new ThoughMaster();
    this.drawerData.IS_ACTIVE = true;
    this.drawerVisible = true;

    this.ThoughComponentVar.fileURL = null;
    this.ThoughComponentVar.photoURL = null;
  }


  edit(data: ThoughMaster): void {
    console.log(data);
    this.drawerTitle = "Update Though Details";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;

    if (this.drawerData.FILE_URL)
      this.ThoughComponentVar.photoURL = this.drawerData.FILE_URL;

    else
      this.ThoughComponentVar.photoURL = null;
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }
}
