import { Component, OnInit } from '@angular/core';
import { Faqhead } from 'src/app/Models/faqhead';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-faq-heads',
  templateUrl: './faq-heads.component.html',
  styleUrls: ['./faq-heads.component.css']
})
export class FaqHeadsComponent implements OnInit {

  formTitle = "Manage FAQ Heads";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["PARENT_NAME", "Parent Name"], ["NAME", "FAQ Head Name"]]
  applicationId = Number(this.cookie.get('applicationId'))
  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Faqhead = new Faqhead();
  constructor(private api: ApiService, private cookie: CookieService) { }
  ngOnInit() {
    this.search();
  }
  // Basic Methods
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    var likeQuery = " ";
    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')'

    }
    if (likeQuery)
      this.filterQuery = "AND APPLICATION_ID=" + this.applicationId + likeQuery
    else
      this.filterQuery = "AND APPLICATION_ID=" + this.applicationId



    this.api.getAllFaqHeads(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + ' AND  ORG_ID =' + this.cookie.get('orgId')).subscribe(data => {
      console.log(data)
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
      if (this.totalRecords == 0) {
        data['SEQUENCE_NO'] = 1;
      } else {
        data['SEQUENCE_NO'] = this.dataList[this.dataList.length - 1]['SEQUENCE_NO'] + 1
      }
    }, err => {
      console.log(err);
    });
  }
  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  add(): void {
    // this.drawerTitle = "Create New Faq Head";
    // this.drawerData = new  Faqhead();
    // this.drawerData.STATUS=true
    // this.drawerData.IS_PARENT=true
    this.drawerVisible = true;

    this.drawerTitle = "Create New FAQ Head";
    this.drawerData = new Faqhead();
    this.api.getAllFaqHeads(1, 1, 'SEQUENCE_NO', 'desc', ' AND  ORG_ID =' + this.cookie.get('orgId')).subscribe(data => {
      if (data['count'] == 0) {
        this.drawerData.SEQUENCE_NO = 1;
      } else {
        this.drawerData.SEQUENCE_NO = data['data'][0]['SEQUENCE_NO'] + 1;
      }
    }, err => {
      console.log(err);
    })
    this.drawerData.ORG_ID = null;
    this.drawerData.PARENT_ID = null;
    this.drawerData.NAME = null;
    this.drawerData.DESCRIPTION = null;
    // this.drawerData.ORG_ID=null;

    // this.drawerVisible = true;


  }
  edit(data: Faqhead): void {
    console.log(data)
    this.drawerTitle = "Update FAQ Head";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
  }
  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }
}
