export class Branchmaster {
    ID: number;
    CLUSTER_ID: string;
    NAME: string;
    ADDRESS_LINE_1: string;
    ADDRESS_LINE_2: string;
    LONGITUTE: string;
    LATITUTE: string;

    SEQUENCE_NO: number;
    CITY: string;
    TEHSIL: string;
    DISTRICT: string;
    STATE: string;
    COUNTRY: string;

    PINCODE: number;
    CLIENT_ID: number;
    STATUS: Boolean = true;
    ORG_ID = 0;
    LOCATION_RESTRICATION: string;
}