import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ExpensesHeadMaster } from 'src/app/Models/expenses-head-master';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-expenseshead',
  templateUrl: './expenseshead.component.html',
  styleUrls: ['./expenseshead.component.css']
})

export class ExpensesheadComponent implements OnInit {
  formTitle = "Manage Expenses Head";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["NAME", "Name"], ["DESCRIPTION", "Description"]]

  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: ExpensesHeadMaster = new ExpensesHeadMaster();

  constructor(private api: ApiService) { }

  ngOnInit() {
    this.search();
  }

  // Basic Methods
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      //this.sortKey = "id";
      // this.sortValue = "descend";
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    if (this.searchText != "") {
      var likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      console.log("likeQuery" + likeQuery);
    }

    this.api.getAllExpenseHead(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
    }, err => {
      console.log(err);
    });
  }

  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  add(): void {
    this.drawerTitle = "Create Expense Head";
    this.drawerData = new ExpensesHeadMaster();
    this.drawerData.IS_ACTIVE = true;
    this.drawerVisible = true;
  }

  edit(data: ExpensesHeadMaster): void {
    this.drawerTitle = "Update Expense Head";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }
}
