export class AssetRequest {
    ID: number
    CLIENT_ID: number
    CATEGORY_ID: number
    EMPLOYEE_ID: number
    ASSET_ID: number
    REQUESTED_QUANTITY: string
    ALLOCATED_QUANTITY: string
    REQUESTED_DATETIME: string
    EXPECTED_DATE_TIME: string
    STATUS: string
    DESCRIPTION: string
    REMARK: string
    APPROVER_ID: number
    APPROVED_DATETIME: string
}