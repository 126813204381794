import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/Service/api.service";

@Component({
  selector: "app-payroll-policy",
  templateUrl: "./payroll-policy.component.html",
  styleUrls: ["./payroll-policy.component.css"],
})

export class PayrollPolicyComponent implements OnInit {
  formTitle = "Manage Payroll Policy";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;

  dataList = [
    {
      ID: 1,
      CLIENT_ID: 1,
      POLICY_NAME: "Payroll Policy 1",
      EFFECTIVE_FROM: "2022-10-10",
      PAY_ROLL_ITEMS: [],
    },
    {
      ID: 2,
      CLIENT_ID: 1,
      POLICY_NAME: "Payroll Policy 2",
      EFFECTIVE_FROM: "2022-10-11",
      PAY_ROLL_ITEMS: [],
    },
    {
      ID: 3,
      CLIENT_ID: 1,
      POLICY_NAME: "Payroll Policy 3",
      EFFECTIVE_FROM: "2022-10-11",
      PAY_ROLL_ITEMS: [],
    },
    {
      ID: 4,
      CLIENT_ID: 1,
      POLICY_NAME: "Payroll Policy 4",
      EFFECTIVE_FROM: "2022-10-11",
      PAY_ROLL_ITEMS: [],
    },
  ];

  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [
    ["POLICY_NAME", "Policy Name"],
    ["EFFECTIVE_FROM", "Effective From"],
  ];

  drawerVisible: boolean;
  drawerTitle: string;
  // drawerData: LeaveType = new LeaveType();

  constructor(private api: ApiService) { }

  ngOnInit() {
    this.search();
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    console.log("search text:" + this.searchText);

    if (this.searchText != "") {
      var likeQuery = " AND";
      this.columns.forEach((column) => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
      console.log("likeQuery" + likeQuery);
    }

    // this.api.getAllLeaveType(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
    //   console.log(data);
    //   this.loadingRecords = false;
    //   this.totalRecords = data['count'];
    //   this.dataList = data['data'];

    // }, err => {
    //   console.log(err);
    // });
  }

  add(): void {
    this.drawerTitle = "New Payroll Policy";
    // this.drawerData = new LeaveType();
    // this.drawerData.IS_ACTIVE = true;
    this.drawerVisible = true;
  }

  edit(data: any): void {
    this.drawerTitle = "Update Payroll Policy";
    // this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
    // this.drawerData.LEAVE_APPROVAL_DAYS = String(this.drawerData.LEAVE_APPROVAL_DAYS);
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }
}
