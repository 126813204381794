import { Component, OnInit, Input } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { EmployeeMaster } from 'src/app/Models/employeemaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-employeeexpensetypemapping',
  templateUrl: './employeeexpensetypemapping.component.html',
  styleUrls: ['./employeeexpensetypemapping.component.css']
})

export class EmployeeexpensetypemappingComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: EmployeeMaster;
  @Input() employeeExpenseHeadsData: string[];
  @Input() drawerVisible: boolean;

  isSpinning = false;
  loadingRecords = true;

  constructor(private api: ApiService, private message: NzNotificationService) { }

  ngOnInit() { }

  close(): void {
    this.drawerClose();
  }

  save() {
    this.isSpinning = true;
    console.log(this.employeeExpenseHeadsData);

    this.api.addEmployeeExpenseHeadDetails(this.data.ID, this.employeeExpenseHeadsData).subscribe(successCode => {
      if (successCode['code'] == "200") {
        console.log(successCode);
        this.message.success("Expense Head Details Added Successfully", "");
        this.drawerClose();
        this.isSpinning = false;

      } else {
        this.message.error("Failed to Add Expense Head Details", "");
        this.isSpinning = false;
      }
    });
  }
}
