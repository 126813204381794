import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { FeatureMaster } from 'src/app/Models/Feature';
import { ApiService } from 'src/app/Service/api.service';
import { OrganizationMaster } from '../../../Models/organization-master';
import { EmmService } from '../../../Service/emm.service';
import { OrganizationComponent } from '../organization/organization.component';

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.css']
})

export class OrganizationsComponent implements OnInit {
  formTitle = "Manage Organization";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["NAME", "Organization Name"], ["EMAIL_ID", "Email"], ["MOBILE_NUMBER", "Mobile"], ["CITY", "City"], ["TEHSIL", "Tehsil"], ["DISTRICT", "District"], ["STATE", "State"], ["COUNTRY", "Country"]]
  time = new Date();
  features = [];
  visible = false;
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: OrganizationMaster = new OrganizationMaster();
  data: FeatureMaster = new FeatureMaster();
  drawerVisible1: boolean;
  drawerTitle1: string;
  drawerData1: OrganizationMaster = new OrganizationMaster();
  ROLES = [];
  isSpinning = false
  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.search();
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      console.log("likeQuery" + likeQuery);
    }

    this.api.getAllOrganizations(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];

    }, err => {
      console.log(err);
    });
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  add(): void {
    this.drawerTitle = "Create New Organization";
    this.drawerData = new OrganizationMaster();
    this.drawerVisible = true;
  }

  @ViewChild(OrganizationComponent, { static: false }) OrganizationComponentVar: OrganizationComponent;

  edit(data: OrganizationMaster): void {
    console.log(data);

    this.drawerTitle = "Update Organization Details";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
    this.OrganizationComponentVar.getallorg2(this.drawerData.ID);

    this.drawerData.MAX_APPLIED_LATEMARK_TIME= new Date('01-01-1970 '+this.drawerData.MAX_APPLIED_LATEMARK_TIME)
    this.drawerData.MAX_APPLIED_LATEMARK_TIME= new Date(this.drawerData.MAX_APPLIED_LATEMARK_TIME)

    this.drawerData.MAX_LATEMARK_TIME= new Date('01-01-1970 '+this.drawerData.MAX_LATEMARK_TIME)
    this.drawerData.MAX_LATEMARK_TIME= new Date(this.drawerData.MAX_LATEMARK_TIME)

    this.drawerData.MAX_EARLYMARK_TIME= new Date('01-01-1970 '+this.drawerData.MAX_EARLYMARK_TIME)
    this.drawerData.MAX_EARLYMARK_TIME= new Date(this.drawerData.MAX_EARLYMARK_TIME)

    this.drawerData.MIN_EARLYMARK_APPLIED_TIME= new Date('01-01-1970 '+this.drawerData.MIN_EARLYMARK_APPLIED_TIME)
    this.drawerData.MIN_EARLYMARK_APPLIED_TIME= new Date(this.drawerData.MIN_EARLYMARK_APPLIED_TIME)

    this.drawerData.HALFDAY_TIME= new Date('01-01-1970 '+this.drawerData.HALFDAY_TIME)
    this.drawerData.HALFDAY_TIME= new Date(this.drawerData.HALFDAY_TIME)

    this.drawerData.DAY_START_TIME= new Date('01-01-1970 '+this.drawerData.DAY_START_TIME)
    this.drawerData.DAY_START_TIME= new Date(this.drawerData.DAY_START_TIME)

    this.drawerData.DAY_END_TIME= new Date('01-01-1970 '+this.drawerData.DAY_END_TIME)
    this.drawerData.DAY_END_TIME= new Date(this.drawerData.DAY_END_TIME)

    // if (data.MAX_LATEMARK_TIME != null)
    //   this.drawerData.MAX_LATEMARK_TIME = this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + this.drawerData.MAX_LATEMARK_TIME;

    // if (data.MAX_EARLYMARK_TIME != null)
    //   this.drawerData.MAX_EARLYMARK_TIME = this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + this.drawerData.MAX_EARLYMARK_TIME;

    // if (data.MAX_APPLIED_LATEMARK_TIME != null)
    //   this.drawerData.MAX_APPLIED_LATEMARK_TIME = this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + this.drawerData.MAX_APPLIED_LATEMARK_TIME;

    // if (data.MIN_EARLYMARK_APPLIED_TIME != null)
    //   this.drawerData.MIN_EARLYMARK_APPLIED_TIME = this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + this.drawerData.MIN_EARLYMARK_APPLIED_TIME;

    // if (data.HALFDAY_TIME != null)
    //   this.drawerData.HALFDAY_TIME = this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + this.drawerData.HALFDAY_TIME;

    // if (data.DAY_START_TIME != null)
    //   this.drawerData.DAY_START_TIME = this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + this.drawerData.DAY_START_TIME;

    // if (data.DAY_END_TIME != null)
    //   this.drawerData.DAY_END_TIME = this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + this.drawerData.DAY_END_TIME;

    this.drawerData.MAX_LATEMARK_COUNT = String(this.drawerData.MAX_LATEMARK_COUNT);
    this.drawerData.MAX_EARLYMARK_COUNT = String(this.drawerData.MAX_EARLYMARK_COUNT);
    this.drawerData.MAX_LATECOMING_COUNT = String(this.drawerData.MAX_LATECOMING_COUNT);
  }

  open(data: OrganizationMaster): void {
    this.drawerTitle1 = "Organisation Features Mapping";
    this.drawerData1 = Object.assign({}, data);
    this.drawerVisible1 = true;

    this.api.getAllFeature(this.pageIndex, this.pageSize, this.sortKey, '', '').subscribe(data => {
      this.loadingRecords = false;

      this.features = data['data'];
    }, err => {
      console.log(err);
    });
  }

  close(): void {
    this.visible = false;
  }

  close1(accountMasterPage: NgForm) {
    this.drawerVisible1 = false;
    this.resetDrawer(accountMasterPage);
  }

  resetDrawer(accountMasterPage: NgForm) {
    accountMasterPage.form.reset();
  }

  save1(accountMasterPage: NgForm): void {
    var d = []
    if (this.ROLES != undefined && this.ROLES.length > 0) {
      for (var i = 0; i < this.ROLES.length; i++) {
        d.push({
          CLIENT_ID: 1,
          ORG_ID: this.drawerData1.ID,
          FEATURE_ID: this.ROLES[i],
          STATUS: true
        })
      }

      this.isSpinning = true;
      this.api.organisationFeatureMapping(d, this.drawerData1.ID).subscribe(successCode => {
        this.isSpinning = false;
        if (successCode['code'] == 200) {
          this.message.success("Feature Mapping Updated Successfully...", "");
          accountMasterPage.form.reset();
          this.drawerClose1();

        } else {
          this.message.error("Feature Mapping Updation Failed...", "");
        }
      });

    } else {
      this.message.error("Please Select Roles...", "");
    }
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  drawerClose1(): void {
    this.drawerVisible1 = false;
  }
}
