import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { Asset } from 'src/app/Models/Asset';
import { ApiService } from 'src/app/Service/api.service';
import { differenceInCalendarDays, setHours } from 'date-fns';

@Component({
  selector: 'app-asset-drawer',
  templateUrl: './asset-drawer.component.html',
  styleUrls: ['./asset-drawer.component.css']
})

export class AssetDrawerComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: Asset;
  @Input() drawerVisible: boolean;

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.getParentAssetCategory();
  }

  categories = [];

  getParentAssetCategory() {
    this.categories = [];

    this.api.getAllAssetCategory(0, 0, 'NAME', 'asc', ' AND IS_LAST=1 AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.categories = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  close(myForm: NgForm) {
    this.drawerClose();
    this.reset(myForm);
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
    this.assetCategoryYesNo = false;
  }

  isSpinning = false;

  save(addNew: boolean, myForm: NgForm): void {
    var isOk = true;

    if (this.data.CATEGORY_ID == undefined) {
      isOk = false;
      this.message.error("Please Select Valid Category", "");
    }

    if (this.data.NAME == undefined || this.data.NAME.trim() == "") {
      isOk = false;
      this.message.error("Please Enter Valid Asset Name", "");
    }

    // if (this.data.ASSET_CODE == undefined || this.data.ASSET_CODE.trim() == "") {
    //   isOk = false;
    //   this.message.error("Please Enter Valid Asset Short Code", "");
    // }

    if (this.data.PURCHASE_DATE == undefined) {
      isOk = false;
      this.message.error("Please Select Valid Purchase Date", "");
    }

    if (this.data.DESCRIPTION == undefined || this.data.DESCRIPTION.trim() == "") {
      isOk = false;
      this.message.error("Please Enter Valid Description", "");
    }

    if (this.data.MAINTENANCE_FREQUENCY != undefined) {
      this.data.MAINTENANCE_FREQUENCY = String(this.data.MAINTENANCE_FREQUENCY);

      if (this.data.MAINTENANCE_FREQUENCY.trim() != "") {
        if (this.data.MAINTENANCE_FREQUENCY == "0" || this.data.MAINTENANCE_FREQUENCY == "00") {
          isOk = false;
          this.message.error("Please Enter Valid Maintenance Frequency", "");
        }

      } else {
        isOk = false;
        this.message.error("Please Enter Valid Maintenance Frequency", "");
      }

    } else {
      isOk = false;
      this.message.error("Please Enter Valid Maintenance Frequency", "");
    }

    if (this.data.MAINTENANCE_FREQUENCY_TYPE == undefined || this.data.MAINTENANCE_FREQUENCY_TYPE.trim() == "") {
      isOk = false;
      this.message.error("Please Enter Valid Maintenance Frequency Type", "");
    }

    if (isOk) {
      this.isSpinning = true;

      if (this.data.EXPIRY_DATE == undefined || this.data.EXPIRY_DATE == null) {
        this.data.EXPIRY_DATE = new Date(1900, 0, 1);
      }

      this.data.PURCHASE_DATE = this.datePipe.transform(this.data.PURCHASE_DATE, "yyyy-MM-dd");
      this.data.EXPIRY_DATE = this.datePipe.transform(this.data.EXPIRY_DATE, "yyyy-MM-dd");

      if (!this.data.ID)
        this.data.STATUS = "NA";

      if (this.data.ID)
        this.data.ALLOCATED_EMPLOYEE_ID = this.data.ALLOCATED_EMPLOYEE_ID;

      if (this.data.ALLOCATED_EMPLOYEE_ID == null)
        this.data.ALLOCATED_EMPLOYEE_ID = "0";

      if (this.data.ID) {
        this.api.updateAssets(this.data).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.message.success("Asset Updated Successfully", "");

            if (!addNew)
              this.close(myForm);

            this.isSpinning = false;

          } else {
            this.message.error("Asset Updation Failed", "");
            this.isSpinning = false;
          }
        });

      } else {
        this.api.createAssets(this.data).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.message.success("Asset Created Successfully", "");

            if (!addNew)
              this.close(myForm);

            else {
              this.reset(myForm);
              this.getParentAssetCategory();
            }

            this.isSpinning = false;

          } else {
            this.message.error("Asset Creation Failed", "");
            this.isSpinning = false;
          }
        });
      }
    }
  }

  assetCategoryYesNo: boolean = false;

  onlyNumber(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  today = new Date();

  disabledPurchaseDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) > 0;

  disabledEndDate = (endValue: Date): boolean => {
    if (this.data.PURCHASE_DATE != null) {
      if (!endValue) {
        return false;
      }

      var modulePreviousDate = new Date(this.data.PURCHASE_DATE);
      modulePreviousDate.setDate(modulePreviousDate.getDate() + (-1));

      return endValue <= new Date(modulePreviousDate);
    };
  }

  moduleStartDateHandle(open: boolean) {
    if (!open) {
      this.data.EXPIRY_DATE = null;
      var tempDate = new Date(this.data.PURCHASE_DATE);
      this.data.EXPIRY_DATE = tempDate.setFullYear(tempDate.getFullYear() + 1);
    }
  }
}
