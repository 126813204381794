export class TrainingAttendanceCard {
    ID: number = 0;
    PROGRAM_TITLE: string = '';
    VENUE: string = '';
    PROGRAM_DATE: any = new Date();
    PROGRAM_CONDUCT_BY: string = '';
    SPONSOR_ORG: string = '';
    PARTICIPANT_NAME: string = '';
    DESIGNATION: string = '';
    DESCRIPTION: string = '';
    PERFORMANCE: boolean = true;
    EVALUATION_CRITERIA: string = '';
}