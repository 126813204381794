import { Component, OnInit } from '@angular/core';
import { Department } from 'src/app/Models/department';
import { ApiService } from 'src/app/Service/api.service';
import { Ticketgroup } from 'src/app/Models/ticketgroup';
import { NzNotificationService, NzTreeNodeOptions } from 'ng-zorro-antd';
import { DatePipe } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-ticketgroup',
  templateUrl: './ticketgroup.component.html',
  styleUrls: ['./ticketgroup.component.css'],
  providers: [DatePipe]
})

export class TicketgroupComponent implements OnInit {
  formTitle = "Ticket Group Master"
  formTitleHead
  pageIndex = 1;
  pageSize = 10;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  addFirstQuestion = false
  parentLoading = false
  departmentLoading = false
  loadingRecords = false;
  mapFaqVisible = false
  totalRecords = 1;
  filterQuery = ""
  faqs: any[] = [];
  ticketGroups: Ticketgroup[];
  ticketGroup: Ticketgroup
  departments: Department[];
  ticketData: Ticketgroup = new Ticketgroup()
  ticketDat2: Ticketgroup = new Ticketgroup()
  isSpinning = false;
  date = new Date();
  date1 = this.datePipe.transform(this.date, 'yyyyMMddHHmmss')
  fileDataLOGO_URL: File = null
  folderName = "ticketGroup"
  nodes = []
  ID: number
  ticketGroupId: number
  dataList = []
  columns: string[][] = [["PARENT_VALUE", "Parent Name"], ["DEPARTMENT_NAME", "Department"], ["TYPE", "Type"], ["VALUE", "Value"], ["SEQ_NO", "Sequence No"], ["IS_LAST_STATUS", "Is Last"], ["TICKET_GROUP_STATUS", "Status"], ["PRIORITY", "Priority"]]
  addOptionVisible = false
  visibleAdd = true
  NAME: string
  Question: string
  applicationId = Number(this.cookie.get('applicationId'))
  ticketId: number
  name1 = "None"
  newId = 0
  loadingRecordsFaqs = false;
  item = {};
  parentId = 0;
  type = ""
  ticketQuestion: Ticketgroup[] = []
  is_first = 0;
  title = '';
  clickedParentID = 0
  parentTicketGroups: Ticketgroup[] = [];
  Title = ''
  FAQHead = 0;
  faqdataList = []
  constructor(private api: ApiService, private cookie: CookieService, private datePipe: DatePipe, private message: NzNotificationService) { }

  ngOnInit() {
    let get = "AND APPLICATION_ID=" + this.applicationId
    // this.loadTicketGroups(get)
    this.loadDepartments()
    var filterQuery = "AND PARENT_ID=0 "
    this.loadTicketGroups(filterQuery)

    this.api.getAllFaqHeads(0, 0, 'NAME', 'ASC', ' AND STATUS = 1' + ' AND  ORG_ID =' + this.cookie.get('orgId')).subscribe(data => {
      if (data['code'] == '200' && data['count'] > 0) {
        this.faqdataList = data['data'];
        this.FAQHead = this.faqdataList[0]['ID'];
      }
      else {
        this.faqdataList = []
        this.FAQHead = 0;
      }
    }, err => {
      console.log(err);
    });
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
  }

  setFilter(event) {
    this.FAQHead = event;
    this.mapFaq3(this.ticketGroupId)
  }

  Visible(n) {
    this.is_first = n;
    this.ticketData = Object.assign({}, this.ticketDat2);
    this.parentTicketGroups = [];
    console.log(this.ticketData)
    if (this.ticketData.ID) {
      this.addFirstQuestion = true
      this.newId = this.ticketData.PARENT_ID;
      this.name1 = 'None';
    }
    else {
      this.newId = 0;
      this.name1 = 'None';
      this.ticketData.PARENT_ID = 0
      this.ticketData.ID = undefined
      this.ticketData.STATUS = true
      this.ticketData.IS_LAST = false
      this.ticketData.URL = ""
      this.ticketData.TYPE = "Q";
      this.addFirstQuestion = true
    }
  }

  loadDepartments() {
    var filter1 = "AND APPLICATION_ID=" + this.applicationId
    this.api.getAllDepartments(0, 0, '', '', ' AND ORG_ID= ' + this.cookie.get('orgId')).subscribe(departments => {
      this.departments = departments['data'];
      this.departmentLoading = false;
    }, err => {
      //console.log(err);
      this.departmentLoading = false;
    });
  }

  drawerClose(): void {
    this.is_first = 0;
    this.addFirstQuestion = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  drawerClose1(): void {
    this.addOptionVisible = false;
  }

  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }

  drawerClose2(): void {
    this.mapFaqVisible = false;
  }

  get closeCallback2() {
    return this.drawerClose2.bind(this);
  }

  loadTicketGroups(filter?) {
    this.loadingRecords = true;
    this.parentLoading = true
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    if (this.searchText != "") {
      var likeQuery = this.filterQuery + " AND ";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      filter = likeQuery
    }

    this.dataList = []
    this.ticketDat2 = new Ticketgroup()
    this.api.getAllTicketGroups(0, 0, '', '', filter + ' AND ORG_ID= ' + this.cookie.get('orgId')).subscribe(ticketGroups => {
      if (filter.match("AND PARENT_ID=0")) {
        if (ticketGroups['count'] != 0) {
          this.ticketDat2 = Object.assign({}, ticketGroups['data'][0]);
          this.ticketId = this.ticketDat2.ID
          this.nodes = ticketGroups['data']
          this.nodes = [
            {
              title: ticketGroups['data'][0]['TYPE'] + '.1) ' + ticketGroups['data'][0]['VALUE'], key: ticketGroups['data'][0]['ID'], type: ticketGroups['data'][0]['TYPE'],
              department: ticketGroups['data'][0]['DEPARTMENT_ID'],
              islast: ticketGroups['data'][0]['IS_LAST_STATUS'],
              parentID: ticketGroups['data'][0]['PARENT_ID']
            },
          ];

          this.loadingRecords = false;
        } else {
          this.ticketDat2 = new Ticketgroup()
        }
      }
      else {
        // if (ticketGroups['count'] == 1) {
        //   if (ticketGroups['data'][0]['TYPE'] == 'Q')
        //     this.visibleAdd = false
        // }
        // else
        //   this.visibleAdd = true

        if (ticketGroups['count'] > 0) {
          this.ticketData.PARENT_ID = ticketGroups['data'][0]['ID']
        } else {
          this.ticketDat2 = new Ticketgroup()
        }
        this.loadingRecords = false;
        this.totalRecords = ticketGroups['count'];
        this.ticketGroups = ticketGroups['data']
        this.dataList = ticketGroups['data'];
      }

      // if (ticketGroups['count'] >= 1) {
      //   if (ticketGroups['data'][0]['TYPE'] == "O") {
      //     this.ticketData.TYPE = 'Q'
      //     this.NAME = "Options"
      //   }
      //   else {
      //     this.ticketData.TYPE = 'O'
      //   }
      // }
      // else {
      //   this.NAME = "Question"
      // }

      this.parentLoading = false;
    }, err => {
      //console.log(err);
      this.loadingRecords = false;
      this.parentLoading = false;
    });
  }

  loadTicketGroups2(filter?) {
    this.loadingRecords = true;
    this.parentLoading = false
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    var likeQuery = ''
    if (this.searchText != "") {
      var likeQuery = this.filterQuery + " AND ";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      //console.log("likeQuery" + likeQuery);
      filter = likeQuery;
    }
    this.ticketGroups = []
    this.ticketQuestion = [];
    this.api.getAllTicketGroups(0, 0, 'SEQ_NO', 'ASC', filter + ' AND ORG_ID= ' + this.cookie.get('orgId')).subscribe(ticketGroups => {

      if (ticketGroups['code'] == 200) {
        if (ticketGroups['count'] == 0) {
          this.totalRecords = 0;
          this.loadingRecords = false;
        } else {
          this.ticketQuestion = ticketGroups['data'].filter(item => item.TYPE == 'Q');
          this.ticketGroups = ticketGroups['data'].filter(item => item.TYPE == 'O');
          this.totalRecords = ticketGroups['count'];
          this.loadingRecords = false;
        }
      }
    }, err => {
      this.loadingRecords = false
      this.message.error(JSON.stringify(err), "");
    });
  }

  onFileSelectedURL(event) {
    this.fileDataLOGO_URL = <File>event.target.files[0];
    //console.log(this.fileDataLOGO_URL)
    var fileExt = this.fileDataLOGO_URL.name.split('.').pop();
  }

  close(accountMasterPage: NgForm) {
    accountMasterPage.form.reset();
    this.addFirstQuestion = false;
  }

  close2() {
    this.addFirstQuestion = false;
    // if (this.is_first == 1) {
    this.filterQuery = " AND PARENT_ID=0";
    // AND APPLICATION_ID=" + this.applicationId;
    this.loadTicketGroups(this.filterQuery);
    // } else {
    //   this.filterQuery = " AND PARENT_ID=" + this.parentId 
    //   this.loadTicketGroups2(this.filterQuery);
    // }
  }

  save(addNew: boolean, accountMasterPage: NgForm) {
    var ok = true;
    this.ticketData['ORG_ID'] = Number(this.cookie.get('orgId'));

    if (this.ticketData.VALUE != undefined && this.ticketData.VALUE.toString().trim() != "") {
      if (this.ticketData.IS_LAST) {
        if (this.ticketData.PRIORITY != undefined && this.ticketData.PRIORITY.toString().trim() != '') {
        } else {
          ok = false
          this.message.error("Please Select Priority...", "");
        }
      }

      if (this.ticketData.TYPE == 'O') {
        if (this.ticketData.SEQ_NO == undefined || this.ticketData.SEQ_NO <= 0) {
          ok = false
          this.message.error("Please Enter Sequence No....", "");
        }
      } else {
        this.ticketData.SEQ_NO = 0;
      }

      if (this.ticketData.IS_LAST == 1) {
        if (this.ticketData.DEPARTMENT_ID == undefined || this.ticketData.DEPARTMENT_ID.toString().trim() == '' || this.ticketData.DEPARTMENT_ID < 0) {
          ok = false
          this.message.error("Please Select Department....", "");
        }
      } else {
        this.ticketData.DEPARTMENT_ID = 0;
      }

      if (ok) {
        this.isSpinning = true;
        if (this.ticketData.ID) {
          if (this.fileDataLOGO_URL) {
            if (this.ticketData.URL == "") {
              this.ticketData.URL = this.genarateKeyLOGO_URL();
            }
            else {
              var str = this.ticketData.URL.substring(this.ticketData.URL.lastIndexOf('/') + 1).split('.')
              var fileExt = this.fileDataLOGO_URL.name.split('.').pop();
              var url = str[0] + "." + fileExt;
              this.api.onUpload(this.folderName, this.fileDataLOGO_URL, url)
              this.ticketData.URL = this.api.retriveimgUrl + this.folderName + "/" + url;
            }
          }
          else {
            this.ticketData.URL = ""
          }

          this.api.updateTicketGroup(this.ticketData)
            .subscribe(successCode => {
              if (successCode['code'] == "200") {
                this.message.success("Ticket group update Successfully...", "");
                accountMasterPage.form.reset();
                this.close2();
                this.isSpinning = false;
              }
              else {
                this.message.error("Failed to update ticket group information...", "");
                this.isSpinning = false;
              }
            });
        }
        else {
          if (this.fileDataLOGO_URL) {
            this.ticketData.URL = this.genarateKeyLOGO_URL()
            //console.log(this.ticketData.URL)
          }
          else {
            this.ticketData.URL = "";
          }
          //console.log(this.ticketData)
          this.ticketData.URL = ""
          this.ticketData.PRIORITY = "M"
          this.ticketData.ALERT_MSG = ""
          this.api.createTicketGroup(this.ticketData)
            .subscribe(successCode => {
              //console.log(successCode)
              if (successCode['code'] == "200") {
                this.message.success("Ticket Group information added successfully...", "");
                if (!addNew) {
                  accountMasterPage.form.reset();
                  this.close2();
                }
                else {
                  this.ticketData = new Ticketgroup();
                  accountMasterPage.form.reset();
                  this.clearData()

                }
                this.isSpinning = false;
              }
              else {
                this.message.error("Failed to add ticket group information...", "");
                this.isSpinning = false;
              }
            });
        }
      }
    }
    else {
      this.message.error("Please Fill All Required Fields...", "");
      this.isSpinning = false;
    }

  }

  genarateKeyLOGO_URL() {
    var number = Math.floor(100000 + Math.random() * 900000)
    var fileExt = this.fileDataLOGO_URL.name.split('.').pop();
    var url = this.date1 + number + "." + fileExt;
    this.api.onUpload(this.folderName, this.fileDataLOGO_URL, url)
    this.ticketData.URL = this.api.retriveimgUrl + this.folderName + "/" + url;
    return this.ticketData.URL
  }

  nzEvent(event) {
    console.log(event)
    if (event['eventName'] == "expand") {
      let node = event['node'];
      this.clickedParentID = node.origin.parentID;
      console.log()
      if (node['key'] != undefined) {
        this.filterQuery = "AND PARENT_ID=" + node['key']
        this.api.getAllTicketGroups(0, 0, '', '', this.filterQuery + ' AND ORG_ID= ' + this.cookie.get('orgId')).subscribe(ticketGroups => {
          // console.log(ticketGroups['data']);
          if (ticketGroups['count'] > 0) {
            node.clearChildren(childrens)
            var childrens = ticketGroups['data']
            for (var index = 0; index < ticketGroups['count']; index++) {
              childrens = [
                {
                  title: ticketGroups['data'][index]['TYPE'] + '.' + (index + 1) + ') ' + ticketGroups['data'][index]['VALUE'], key: ticketGroups['data'][index]['ID'],
                  type: ticketGroups['data'][index]['TYPE'], parentID: ticketGroups['data'][0]['PARENT_ID'],
                  department: ticketGroups['data'][index]['DEPARTMENT_ID'],
                  islast: ticketGroups['data'][index]['IS_LAST_STATUS'],
                },

              ];

              node.addChildren(childrens);
            }
          }
          else {
            this.message.info("No any Child", "");
          }
        });

      }
    }

    // if (event['eventName'] == "click") {
    //   this.addOptionVisible = true;

    //   this.ID = event['node']['origin']['key']
    //   this.type = event['node']['origin']['type']
    //   this.title = event['node']['origin']['title']
    //   this.clickedParentID = event['node']['origin']['parentID'];
    //   this.loadingRecords = true;
    //   this.parentId = this.ID;

    //   if (this.type == 'O')
    //     this.formTitleHead = "View Questions";
    //   else
    //     this.formTitleHead = "View Options";

    //   this.filterQuery = " AND PARENT_ID=" + this.ID + " AND APPLICATION_ID=" + this.applicationId
    //   this.loadTicketGroups2(this.filterQuery);
    // }
  }

  editOption(event) {
    this.ID = event;
    this.parentId = this.ID;
    var data = new Ticketgroup();
    this.api.getAllTicketGroups(1, 1, 'ID', 'DESC', ' AND ID=' + event + ' AND ORG_ID= ' + this.cookie.get('orgId')).subscribe(ticketGroups => {
      if (ticketGroups['code'] == 200) {
        data = ticketGroups['data'][0];
        // this.clickedParentID = data.PARENT_ID;

        this.api.getAllTicketGroups(1, 1, 'ID', 'DESC', ' AND ID=' + data.PARENT_ID + ' AND ORG_ID= ' + this.cookie.get('orgId')).subscribe(ticketGroups => {
          if (ticketGroups['code'] == 200 && ticketGroups['count'] > 0) {
            this.clickedParentID = ticketGroups['data'][0].PARENT_ID;
            this.edit(data)
          }else{
            this.clickedParentID = 0;
            this.edit(data)
          }
        });
      }
    });
  }

  add() {
    this.is_first = 0;
    this.clearData();
    this.newId = 0;
    this.name1 = 'None';
    this.ticketData.PARENT_ID = this.ID
    var filterQuery = " AND PARENT_ID=" + this.clickedParentID
    this.api.getAllTicketGroups(0, 0, 'SEQ_NO', 'ASC', filterQuery + ' AND ORG_ID= ' + this.cookie.get('orgId')).subscribe(ticketGroups => {

      if (ticketGroups['code'] == 200) {
        this.parentTicketGroups = ticketGroups['data'];
      }
      this.addFirstQuestion = true;
    }, err => {
      this.loadingRecords = false
      this.message.error(JSON.stringify(err), "");
    });
  }

  add2(event, type, node) {
    console.log(node)
    this.is_first = 0;
    this.clearData();
    this.newId = 0;
    this.name1 = 'None';
    this.ticketData.PARENT_ID = Number(node.origin.key);
    this.ticketData.TYPE = type;
    this.ticketData.STATUS = true;
    this.ticketData.DEPARTMENT_ID = node.origin.department;
    this.clickedParentID = node.origin.parentID;
    this.ID = Number(node.origin.key);
    this.Title = type == 'O' ? 'Add New Option' : 'Add New Question'
    this.filterQuery = "AND PARENT_ID=" + node.origin['key'];
    if (type == "Q") {
      this.api.getAllTicketGroups(0, 0, '', '', this.filterQuery + ' AND ORG_ID= ' + this.cookie.get('orgId')).subscribe(ticketGroups => {
        if (ticketGroups['count'] > 0) {
          this.message.info('You can add only one question to options.', '');
        } else {
          var filterQuery = " AND PARENT_ID=" + this.clickedParentID
          this.api.getAllTicketGroups(0, 0, 'SEQ_NO', 'ASC', filterQuery + ' AND ORG_ID= ' + this.cookie.get('orgId')).subscribe(ticketGroups => {

            if (ticketGroups['code'] == 200) {
              this.parentTicketGroups = ticketGroups['data'];

            }
            this.addFirstQuestion = true;
          }, err => {
            this.loadingRecords = false
            this.message.error(JSON.stringify(err), "");
          });
        }
      });
    } else {
      var filterQuery = " AND PARENT_ID=" + this.clickedParentID
      this.api.getAllTicketGroups(0, 0, 'SEQ_NO', 'ASC', filterQuery + ' AND ORG_ID= ' + this.cookie.get('orgId')).subscribe(ticketGroups => {
        if (ticketGroups['code'] == 200) {
          this.parentTicketGroups = ticketGroups['data'];
          
        }
        this.addFirstQuestion = true;
      }, err => {
        this.loadingRecords = false
        this.message.error(JSON.stringify(err), "");
      });

      this.api.getAllTicketGroups(0, 0, 'SEQ_NO', 'DESC', " AND PARENT_ID=" + this.ID + ' AND ORG_ID= ' + this.cookie.get('orgId')).subscribe(ticketGroups2 => {
        if (ticketGroups2['code'] == 200) { 
          if (ticketGroups2['data'].length == 0) {
            this.ticketData.SEQ_NO = 1;
          } else {
            this.ticketData.SEQ_NO = Number(ticketGroups2['data'][0]['SEQ_NO']) + 1;
          }
        } 
      }, err => {  
      });
    }
  }

  clearData() {
    this.ticketData.ID = undefined;
    this.ticketData.DEPARTMENT_ID = undefined;
    this.ticketData.VALUE = ""
    this.ticketData.SEQ_NO = 0
    this.ticketData.PRIORITY = "M"
    this.ticketData.ALERT_MSG = ""
  }

  edit(data: Ticketgroup) {
    this.ticketData = data;
    this.ticketData['PARENT_ID'] = Number(data.PARENT_ID);
    this.newId = 0;
    this.name1 = 'None';
    this.Title = this.ticketData['TYPE'] == 'O' ? 'Update Option' : 'Update Question'
    var filterQuery = " AND PARENT_ID=" + this.clickedParentID
    this.api.getAllTicketGroups(0, 0, 'SEQ_NO', 'ASC', filterQuery + ' AND ORG_ID= ' + this.cookie.get('orgId')).subscribe(ticketGroups => {

      if (ticketGroups['code'] == 200) {
        this.parentTicketGroups = ticketGroups['data'];
      }
    }, err => {
      this.loadingRecords = false
      this.message.error(JSON.stringify(err), "");
    });
    this.addFirstQuestion = true
  }

  mapFaq(data: Ticketgroup) {
    this.loadingRecordsFaqs = true
    this.ticketGroupId = data.ID;
    this.Question = data.VALUE
    this.api.getMappingFaqs(data.ID, this.FAQHead).subscribe(data => {
      if (data['code'] = "200") {
        this.faqs = data['data'];
        this.loadingRecordsFaqs = false;
        this.mapFaqVisible = true;
      } else {
        this.loadingRecordsFaqs = false;
        this.message.error('Failed to load Mapped FAQ data', '');
      }
    }, err => {
      this.loadingRecordsFaqs = false;
      this.message.error('Failed to load Mapped FAQ data', '');
    });
  }

  mapFaq2(node) {
    this.loadingRecordsFaqs = true
    this.ticketGroupId = node.key;
    this.Question = node.title
    this.api.getMappingFaqs(node.key, this.FAQHead).subscribe(data => {
      if (data['code'] = "200") {
        this.faqs = data['data'];
        this.loadingRecordsFaqs = false;
        this.mapFaqVisible = true;
      } else {
        this.loadingRecordsFaqs = false;
        this.message.error('Failed to load Mapped FAQ data', '');
      }
    }, err => {
      this.loadingRecordsFaqs = false;
      this.message.error('Failed to load Mapped FAQ data', '');
    });
  }

  mapFaq3(node) {
    this.loadingRecordsFaqs = true
    this.ticketGroupId = node;

    this.api.getMappingFaqs(node, this.FAQHead).subscribe(data => {
      if (data['code'] = "200") {
        this.faqs = data['data'];
        this.loadingRecordsFaqs = false;
        this.mapFaqVisible = true;
      } else {
        this.loadingRecordsFaqs = false;
        this.message.error('Failed to load Mapped FAQ data', '');
      }
    }, err => {
      this.loadingRecordsFaqs = false;
      this.message.error('Failed to load Mapped FAQ data', '');
    });
  }
  closeFaqMap() {
    this.mapFaqVisible = false;
  }

  saveMappping() {
    this.isSpinning = true;
    this.api.addMappingFaqs(this.ticketGroupId, this.faqs)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          this.message.success("Faq Map Successfully ...", "");
          this.closeFaqMap();
          this.isSpinning = false;
        }
        else {
          this.message.error("Faq Map assigning Failed...", "");
          this.isSpinning = false;
        }
      });
  }
}
