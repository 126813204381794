import { Component, Input, OnInit } from '@angular/core';
import { TrainingEvalutionForm } from 'src/app/Models/trainingevalutionform';

@Component({
  selector: 'app-addtrainingevalutionform',
  templateUrl: './addtrainingevalutionform.component.html',
  styleUrls: ['./addtrainingevalutionform.component.css']
})

export class AddtrainingevalutionformComponent implements OnInit {
  @Input()
  drawerClose!: Function;
  @Input()
  data: TrainingEvalutionForm = new TrainingEvalutionForm;
  @Input()
  drawerVisible: boolean = false;

  constructor() { }

  ngOnInit(): void { }
}
