export class AssetMapping {
    ID: number
    CLIENT_ID: number
    ASSET_ID: number
    EMPLOYEE_ID: number
    EXPIRY_DATE: string
    STATUS: string
    REQUEST_ID: number
    NEW_EMPLOYEE_ID: number
    ALLOCATION_DATETIME: string
    RETURNED_DATETIME: string
    NAME: string
    ALLOCATED_EMPLOYEE_NAME: string
    ALLOCATED_QUANTITY: string
    APPROVER_ID: number
    REMARK: string
    REQUESTED_QUANTITY: string
    EMPLOYEE_NAME: string
    ASSET_CODE: string
    ASSET_NAME: string
}