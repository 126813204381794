import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NzButtonType } from 'ng-zorro-antd/button';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Department } from 'src/app/Models/department';
import { Designationmaster } from 'src/app/Models/Designationmaster';
// import { scheduled } from 'rxjs';
import { EmployeeMaster } from 'src/app/Models/employeemaster';
import { TrainingSchedule } from 'src/app/Models/trainingschedule';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-trainattendmap',
  templateUrl: './trainattendmap.component.html',
  styleUrls: ['./trainattendmap.component.css']
})

export class TrainattendmapComponent implements OnInit {
  @Input() drawerClose!: Function;
  @Input() data: TrainingSchedule = new TrainingSchedule();
  @Input() trainattendiesData: any[] = [];
  @Input() scheduleId: any;
  @Input() drawerVisible: boolean = false;

  isSpinning = false;
  formTitle = "Training Attendies Mapping";
  loadingRecords = false;
  isOk = true;
  filterQuery: string = "";
  filterValue = [];
  isFilterApplied: NzButtonType = "default";
  isloadSpinning = false;
  exportSpinning = false;
  filterClass: string = "filter-invisible";
  current = new Date();
  isStatusSpinning = false;
  departlist: Department[] = [];
  desglist: Designationmaster[] = [];
  emplist: EmployeeMaster[] = [];
  pageIndex = 1;
  pageSize = 10;
  @Input() totalRecords = 1;
  dataList: any = [];
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  DESIGNSTION_ID = 0;
  DEPARTMENT_ID = 0;
  departmentnm: any = [];
  designationm: any = [];
  a: any = [];
  b: any = [];
  dataList2 = [];
  attendmap: any = [{ SCHEDULE_ID: 1, EMP_ID: 1, DEPARTMENT_ID: 1, ATTENDANCE: 0, FEEDBACK: "valid", RATINGS: 1, PERFORMANCE: 1, IS_ACTIVE: 0 }];
  column = [['EMP_ID', 'Employee Name'], ['SCHEDULE_ID', 'Schedule'], ['ATTENDANCE', 'Is Attending']];

  constructor(private api3: ApiService, private api2: ApiService, private datePipe: DatePipe, private api: ApiService, private message: NzNotificationService) { }

  ngOnInit() {
    this.loaddepartment();
    this.loaddesignation();
  }

  loaddepartment() {
    this.api2.getAllDepartments(0, 0, '', '', '').subscribe(data => {
      if (data['code'] == 200) {
        this.departlist = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  loaddesignation() {
    this.api3.getAllDesignation(0, 0, '', '', '').subscribe(data => {
      if (data['code'] == 200) {
        this.desglist = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  close(): void {
    this.drawerClose();
    this.departmentnm = [];
    this.designationm = [];
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      this.dataList = [];
    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND";

      this.column.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }

    var filter = ""
    if (likeQuery)
      filter = this.filterQuery + likeQuery;

    else
      filter = this.filterQuery;

    this.a = this.departmentnm.toString();
    this.b = this.designationm.toString();

    this.api.trainingAttendiedMapping(this.scheduleId, this.a, this.b).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
      this.trainattendiesData = this.dataList

    }, err => {
      console.log(err);
      this.clearFilter();
    });
  }

  applyFilter() {
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    this.filterQuery = '';
    this.pageIndex = 1;
    this.dataList = [];
    this.search(true);
  }

  clearFilter() {
    this.filterValue = [];
    this.dataList = [];
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.a = '';
    this.b = '';
    this.departmentnm = [];
    this.designationm = [];

    this.api.trainingAttendiedMapping(Number(this.scheduleId), this.a, this.b).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
        this.trainattendiesData = this.dataList;
      }

    }, err => {
      console.log(err);
    });
  }

  save() {
    this.isSpinning = true;

    this.api.addBulktrainingAttendiedMapping(Number(this.data.ID), this.trainattendiesData).subscribe(data => {
      if (data['code'] == 200) {
        this.message.success("Training Attendance Created Successfully", "");
        this.isSpinning = false;
        this.close();
        this.drawerVisible = false;

      } else {
        this.message.error("Training Attendance Creation Failed", "");
        this.isSpinning = false;
      }

    }, err => {
      this.isSpinning = false;
    });
  }
}
