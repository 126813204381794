import { Component, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { BirthdayMaster } from 'src/app/Models/birthday-master';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-birthday',
  templateUrl: './birthday.component.html',
  styleUrls: ['./birthday.component.css']
})

export class BirthdayComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: BirthdayMaster;
  @Input() drawerVisible: boolean;

  fileData: File = null;
  imgUrl
  isSpinning = false;

  constructor(private api: ApiService, private message: NzNotificationService) { }

  ngOnInit() { }

  close(myForm: NgForm) {
    this.drawerClose();
    this.reset(myForm);
    this.clear();
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  save(addNew: boolean, myForm: NgForm): void {
    var isOk = true;

    if (this.data.TITLE != undefined) {
      if (this.data.TITLE.trim() != '') {
        if (!this.api.checkTextBoxIsValid(this.data.TITLE)) {
          isOk = false;
          this.message.error("Please Enter Valid Title", "");
        }
      } else {
        isOk = false;
        this.message.error("Please Enter Valid Title", "");
      }
    } else {
      isOk = false;
      this.message.error("Please Enter Valid Title", "");
    }

    if (this.data.DESCRIPTION != undefined) {
      if (this.data.DESCRIPTION.trim() != '') {
        if (!this.api.checkTextBoxIsValidWithComma(this.data.DESCRIPTION)) {
          isOk = false;
          this.message.error("Please Enter Valid Description", "");
        }
      } else {
        isOk = false;
        this.message.error("Please Enter Valid Description", "");
      }
    } else {
      isOk = false;
      this.message.error("Please Enter Valid Description", "");
    }

    if (isOk) {
      this.isSpinning = true;

      this.imageUpload();
      this.data.URL = this.uploadedAttachmentStr;
      console.log(this.data);

      if (this.data.ID) {
        this.api.updateBirthday(this.data).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Birthday Updated Successfully", "");

            if (!addNew)
              this.close(myForm);
            this.isSpinning = false;

          } else {
            this.message.error("Birthday Updation Failed", "");
            this.isSpinning = false;
          }
        });

      } else {
        this.api.createBirthday(this.data).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Birthday Created Successfully", "");

            if (!addNew)
              this.close(myForm);

            else {
              this.data = new BirthdayMaster();
            }

            this.isSpinning = false;

          } else {
            this.message.error("Birthday Creation Failed", "");
            this.isSpinning = false;
          }
        });
      }
    }
  }

  fileURL: File = null;
  photoURL: any;
  imageSrc1: string;

  onFileSelected(event) {
    if (event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/png") {
      this.fileURL = <File>event.target.files[0];

      const reader = new FileReader();

      if (event.target.files && event.target.files.length) {
        const [file] = event.target.files;
        reader.readAsDataURL(file);

        reader.onload = () => {
          this.imageSrc1 = reader.result as string;
          this.photoURL = this.imageSrc1;
        };
      }

    } else {
      this.message.error("Please Select only JPEG/ JPG/ PNG File", "");
      this.fileURL = null;
    }
  }

  clear() {
    this.fileURL = null;
    this.photoURL = null;
  }

  clear1() {
    this.photoURL = null;
  }

  folderName = "birthday";
  uploadedAttachmentStr: string;

  imageUpload() {
    this.uploadedAttachmentStr = "";

    if (!this.data.ID) {
      if (this.fileURL) {
        var number = Math.floor(100000 + Math.random() * 900000);
        var fileExt = this.fileURL.name.split('.').pop();
        var url = "B" + number + "." + fileExt;

        this.api.onUpload2(this.folderName, this.fileURL, url).subscribe(res => {
          if (res["code"] == 200) {
            console.log("Uploaded");

          } else {
            console.log("Not Uploaded");
          }
        });

        this.uploadedAttachmentStr = this.api.retriveimgUrl + "birthday/" + url;

      } else {
        this.uploadedAttachmentStr = "";
      }

    } else {
      if (this.fileURL) {
        var number = Math.floor(100000 + Math.random() * 900000);
        var fileExt = this.fileURL.name.split('.').pop();
        var url = "B" + number + "." + fileExt;

        this.api.onUpload2(this.folderName, this.fileURL, url).subscribe(res => {
          if (res["code"] == 200) {
            console.log("Uploaded");

          } else {
            console.log("Not Uploaded");
          }
        });

        this.uploadedAttachmentStr = this.api.retriveimgUrl + "birthday/" + url;

      } else {
        if (this.photoURL)
          this.uploadedAttachmentStr = this.data.URL;
        else
          this.uploadedAttachmentStr = "";
      }
    }
  }
}
