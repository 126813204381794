import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-transfer-ticket-drawer',
  templateUrl: './transfer-ticket-drawer.component.html',
  styleUrls: ['./transfer-ticket-drawer.component.css']
})

export class TransferTicketDrawerComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: any;
  @Input() data2: any;
  isLoading = false;

  constructor(private cookie: CookieService, private datePipe: DatePipe, private api: ApiService, private message: NzNotificationService) { }

  ngOnInit() { }
  userId = Number(this.cookie.get('userId'))
  EMPLOYEE_ID: any;
  @Input() empList = [];
  data3:any[] = [];
  getAllEmployee(deptID) {
    this.empList = [];
    this.api.getAllemployeeMaster(0, 0, '', '', ' AND ID<>'+this.userId+'  AND  ID in(SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID = '+this.cookie.get('orgId')+' AND ROLE_ID=4)   AND STATUS=1  AND DEPARTMENT_ID=' + deptID ).subscribe(data => {
 
      this.empList = data['data']; 
    }, err => { 
    });
    // this.api.getAllEmpRoleMap(0, 0, '', '', ' AND ROLE_ID= 4').subscribe(data => {
      
    //   if (data['code'] == 200) { 
    //     for (var i = 0; i < data['data'].length; i++) {
    //       this.data3.push(data['data'][i]['EMPLOYEE_ID'])
    //       if (data['data'].length == i + 1) {
    //         if (this.data3.length > 0)
    //           this.api.getAllemployeeMaster(0, 0, '', '', 'and DEPARTMENT_ID=' + deptID +' AND ORG_ID  = ' + this.cookie.get('orgId') + ' AND ID in(' + this.data3.toString() + ')').subscribe(data => {
    //             this.empList = data['data'];
    //           }, err => {
    //             console.log(err);
    //           });
    //       }
    //     }

    //   }
    // });
    // this.api.getAllemployeeMaster(0, 0, 'ID', 'desc', ' and DEPARTMENT_ID=' + deptID + ' AND  ORG_ID =' + this.cookie.get('orgId')).subscribe(data => {
    //   if (data['code'] == 200) {
    //     this.empList = data['data'];
    //     console.log(this.empList);
    //   }

    // }, err => {
    //   console.log(err);
    // });
  }

  close(myForm: NgForm) {
    this.drawerClose();
    this.resetPage(myForm);
  }

  resetPage(myForm: NgForm) {
    myForm.form.reset();
  }

  save(myForm: NgForm) {
    var isOk = true;

    if (this.EMPLOYEE_ID != undefined) {
      if (this.EMPLOYEE_ID == null || this.EMPLOYEE_ID == 0) {
        isOk = false;
        this.message.error("Please Select Valid Employee Name", "");
      }
    } else {
      isOk = false;
      this.message.error("Please Select Valid Employee Name", "");
    }

    if (isOk) { 
      this.data2.TAKEN_BY_USER_ID = this.EMPLOYEE_ID; 
      this.isLoading = true;

      this.api.transferTicket(this.data2).subscribe(successCode => {
        if (successCode['code'] == "200") {
          this.isLoading = false;
          this.close(myForm);
          this.message.success("Ticket Transfered successfully...", "");

        } else {
          this.message.error("Failed to Transfer Ticket...", "");
        }
      }, err => {
        console.log(err);

        if (err["ok"] == false) {
          this.message.error("Server Not Found", "");
        }
      });
    }
  }
}
