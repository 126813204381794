import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-asset-maintenance-register',
  templateUrl: './asset-maintenance-register.component.html',
  styleUrls: ['./asset-maintenance-register.component.css']
})

export class AssetMaintenanceRegisterComponent implements OnInit {
  maintenanceReportPageIndex = 1;
  maintenanceReportPageSize = 10;
  maintenanceReportTotalRecords = 1;
  maintenanceReportData = [];
  maintenanceReportLoadingRecords = true;
  maintenanceReportSortValue: string = "desc";
  maintenanceReportSortKey: string = "ID";
  maintenanceReportSearchText: string = "";
  columns: string[][] = [["MAINTENANCE_DATE", "Date"], ["MAINTENANCE_DONE_BY", "Done By"], ["REMARK", "Remark"]];
  @Input() drawerClose: Function;
  ASSET_ID_Filter: number = 0;

  constructor(private api: ApiService, private cookie: CookieService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.maintenanceReportSearch(true);
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  sort(sort: { key: string; value: string }): void {
    this.maintenanceReportSortKey = sort.key;
    this.maintenanceReportSortValue = sort.value;
    this.maintenanceReportSearch(true);
  }

  maintenanceReportSearch(reset: boolean = false) {
    if (reset) {
      this.maintenanceReportPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.maintenanceReportSortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    console.log("search text : " + this.maintenanceReportSearchText);

    if (this.maintenanceReportSearchText != "") {
      likeQuery = " AND (";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.maintenanceReportSearchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')'
    }

    var assetIDFilter = " AND ASSET_ID=" + this.ASSET_ID_Filter;
    var maintenanceDateFilter = "";
    if ((this.MAINTENANCE_DATE != undefined) && (this.MAINTENANCE_DATE.length != 0)) {
      maintenanceDateFilter = " AND (MAINTENANCE_DATE BETWEEN '" + this.datePipe.transform(this.MAINTENANCE_DATE[0], 'yyyy-MM-dd 00:00:00') + "' AND '" + this.datePipe.transform(this.MAINTENANCE_DATE[1], 'yyyy-MM-dd 23:59:59') + "')";
    }

    this.maintenanceReportLoadingRecords = true;
    this.api.getAssetMaintenance(this.maintenanceReportPageIndex, this.maintenanceReportPageSize, this.maintenanceReportSortKey, sort, likeQuery + assetIDFilter + maintenanceDateFilter).subscribe(data => {
      if (data['code'] == 200) {
        this.maintenanceReportLoadingRecords = false;
        this.maintenanceReportTotalRecords = data['count'];
        this.maintenanceReportData = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  isFilterApplied: string = "default";
  filterClass: string = "filter-visible";

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  MAINTENANCE_DATE = [];

  clearFilter() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.MAINTENANCE_DATE = [];
    this.maintenanceReportSearch(true);
  }

  applyFilter() {
    if ((this.MAINTENANCE_DATE.length > 0))
      this.isFilterApplied = "primary";

    else
      this.isFilterApplied = "default";

    this.maintenanceReportSearch(true);
    this.filterClass = "filter-invisible";
  }

  onSearchBoxEnter() {
    document.getElementById("searchBtn1").focus();
  }

  close() {
    this.drawerClose();
  }
}
