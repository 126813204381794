import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { AssetMapping } from 'src/app/Models/AssetMapping';
import { ApiService } from 'src/app/Service/api.service';
import { differenceInCalendarDays, setHours } from 'date-fns';

@Component({
  selector: 'app-asset-allocation-register',
  templateUrl: './asset-allocation-register.component.html',
  styleUrls: ['./asset-allocation-register.component.css']
})

export class AssetAllocationRegisterComponent implements OnInit {
  formTitle = "Asset Allocation";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  assetData = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  filterClass: string = "filter-visible";
  isSpinning = false;
  loadingFilterEmployees = true;
  columns: string[][] = [["REQUEST_ID", "Request ID"], ["ASSET_NAME", "Asset"], ["EMPLOYEE_NAME", "Requested By/ Allocated To"], ["NEW_ALLOCATED_EMPLOYEE", "Re-Allocated To"], ["ALLOCATION_DATETIME", "Allocation Date Time"], ["RETURNED_DATETIME", "Returned Date Time"]];
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: AssetMapping = new AssetMapping();
  applicationId = Number(this.cookie.get('applicationId'));
  drawerVisible1: boolean;
  drawerTitle1: string;

  constructor(private api: ApiService, private cookie: CookieService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.getRequestedEmployeeList();
    this.getAssets();
    this.search(true);
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    console.log("search text : " + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND (";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
    }

    var statusFilterStr = "";
    if (this.STATUS.length > 0) {
      statusFilterStr = " AND STATUS IN (" + this.STATUS + ")";
    }

    var requestedEmpFilterStr = "";
    if (this.REQUESTED_EMPLOYEE_ID.length > 0) {
      requestedEmpFilterStr = " AND EMPLOYEE_ID IN (" + this.REQUESTED_EMPLOYEE_ID + ")";
    }

    var allocationDateFilter = "";
    if ((this.ALLOCATION_DATE_FOR_FILTER != undefined) && (this.ALLOCATION_DATE_FOR_FILTER.length != 0)) {
      allocationDateFilter = " AND (ALLOCATION_DATETIME BETWEEN '" + this.datePipe.transform(this.ALLOCATION_DATE_FOR_FILTER[0], 'yyyy-MM-dd 00:00:00') + "' AND '" + this.datePipe.transform(this.ALLOCATION_DATE_FOR_FILTER[1], 'yyyy-MM-dd 23:59:59') + "')";
    }

    var returnedDateFilter = "";
    if ((this.RETURNED_DATE_FOR_FILTER != undefined) && (this.RETURNED_DATE_FOR_FILTER.length != 0)) {
      returnedDateFilter = " AND (RETURNED_DATETIME BETWEEN '" + this.datePipe.transform(this.RETURNED_DATE_FOR_FILTER[0], 'yyyy-MM-dd 00:00:00') + "' AND '" + this.datePipe.transform(this.RETURNED_DATE_FOR_FILTER[1], 'yyyy-MM-dd 23:59:59') + "')";
    }

    var assetFilterStr = "";
    if (this.ASSET_ID_FOR_FILTER.length > 0) {
      assetFilterStr = " AND ASSET_ID IN (" + this.ASSET_ID_FOR_FILTER + ")";
    }

    this.loadingRecords = true;
    this.api.getAllAssetMapping(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + statusFilterStr + requestedEmpFilterStr + allocationDateFilter + returnedDateFilter + assetFilterStr).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.assetData = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  edit(data: AssetMapping): void {
    console.log(data);

    this.drawerTitle = "Allocate Asset";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;

    if (this.drawerData.STATUS == "P")
      this.drawerData.STATUS = "A";

    let todayDate = new Date();
    let d = todayDate.getDay();
    let m = todayDate.getMonth();
    let y = todayDate.getFullYear() + 1;

    let returnedDate = new Date(y, m, d);
    this.drawerData.RETURNED_DATETIME = this.datePipe.transform(returnedDate, "yyyy-MM-dd 12:00:00");
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  returnAsset(data) {
    if (data['ID']) {
      data['STATUS'] = "R";

      this.api.allocatingAsset(data).subscribe(successCode => {
        if (successCode['code'] == 200) {
          this.message.success("Asset Returned Successfully", "");
          this.search();

        } else {
          this.message.error("Failed to Asset Return", "");
        }
      });
    }
  }

  cancel(): void { }

  rejectAssetRequest(data) {
    if (data['ID']) {
      data['STATUS'] = "RJ";

      this.api.allocatingAsset(data).subscribe(successCode => {
        if (successCode['code'] == 200) {
          this.message.success("Asset Request Successfully Rejected", "");
          this.search();

        } else {
          this.message.error("Failed to Reject Asset Request", "");
        }
      });
    }
  }

  isReallocateModalVisible = false;
  isConfirmLoading = false;
  reallocateModalTitle: string = "";
  reallocationData: any;

  showReallocationModal(data): void {
    console.log(data);

    this.NEW_EMPLOYEE_ID = undefined;
    this.RETURNED_DATETIME = undefined;

    this.reallocationData = data;
    this.isReallocateModalVisible = true;
    this.reallocateModalTitle = "Re-Allocation of " + data["ASSET_NAME"] + "(" + data["ASSET_CODE"] + ")";

    let empID: number;
    if (data['EMPLOYEE_ID'] == undefined || data['EMPLOYEE_ID'] == null)
      empID = data['NEW_EMPLOYEE_ID'];
    else
      empID = data['EMPLOYEE_ID'];

    this.getEmployeeList(empID);

    let todayDate = new Date();
    let d = todayDate.getDay();
    let m = todayDate.getMonth();
    let y = todayDate.getFullYear() + 1;

    let returnedDate = new Date(y, m, d);
    this.RETURNED_DATETIME = this.datePipe.transform(returnedDate, "yyyy-MM-dd 12:00:00");
  }

  handleReallocateModalOk(): void {
    var isOk = true;

    if (this.NEW_EMPLOYEE_ID == undefined) {
      isOk = false;
      this.message.error("Please Select Valid Employee", "");
    }

    if (isOk) {
      this.isConfirmLoading = true;

      this.reallocationData['NEW_EMPLOYEE_ID'] = this.NEW_EMPLOYEE_ID;
      this.reallocationData['APPROVER_ID'] = this.api.userId;
      this.reallocationData['RETURNED_DATETIME'] = this.datePipe.transform(this.RETURNED_DATETIME, "yyyy-MM-dd HH:mm" + ":00");
      this.reallocationData['STATUS'] = "RA";
      console.log(this.reallocationData);

      if (this.reallocationData['ID']) {
        this.api.allocatingAsset(this.reallocationData).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.message.success("Asset Re-Allocated Successfully", "");
            this.isConfirmLoading = false;
            this.isReallocateModalVisible = false;
            this.search();

          } else {
            this.message.error("Failed to Re-Allocating the Asset", "");
            this.isConfirmLoading = false;
          }
        });
      }
    }
  }

  handleReallocateModalCancel(): void {
    this.isReallocateModalVisible = false;
  }

  today = new Date();

  disabledStartDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) < 0;

  RETURNED_DATETIME: string;
  NEW_EMPLOYEE_ID: number;
  employees = [];
  employeeLoading: boolean = false;

  getEmployeeList(empID) {
    this.employees = [];
    this.employeeLoading = true;

    this.api.getAllemployeeMaster(0, 0, 'NAME', 'asc', ' AND ORG_ID=1 AND ID!=1 AND STATUS=1 AND ID!=' + empID).subscribe(data => {
      if (data['code'] == 200) {
        this.employees = data['data'];
        this.employeeLoading = false;
      }

    }, err => {
      console.log(err);
    });
  }

  getInitial(empName) {
    let initial: string = empName.charAt(0);
    return initial.trim();
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  STATUS = [];
  REQUESTED_EMPLOYEE_ID = [];
  ALLOCATION_DATE_FOR_FILTER = [];
  RETURNED_DATE_FOR_FILTER = [];
  ASSET_ID_FOR_FILTER = [];

  clearFilter() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.STATUS = [];
    this.REQUESTED_EMPLOYEE_ID = [];
    this.ALLOCATION_DATE_FOR_FILTER = [];
    this.RETURNED_DATE_FOR_FILTER = [];
    this.ASSET_ID_FOR_FILTER = [];
    this.search(true);
  }

  applyFilter() {
    if ((this.STATUS.length > 0) || (this.REQUESTED_EMPLOYEE_ID.length > 0) || (this.ALLOCATION_DATE_FOR_FILTER.length > 0) || (this.RETURNED_DATE_FOR_FILTER.length > 0) || (this.ASSET_ID_FOR_FILTER.length > 0))
      this.isFilterApplied = "primary";

    else
      this.isFilterApplied = "default";

    this.search(true);
    this.filterClass = "filter-invisible";
  }

  onSearchBoxEnter() {
    document.getElementById("searchBtn").focus();
  }

  requestedEmployees = [];
  requestedEmployeeLoading = false;

  getRequestedEmployeeList() {
    this.requestedEmployees = [];
    this.requestedEmployeeLoading = true;

    this.api.getAllemployeeMaster(0, 0, 'NAME', 'asc', ' AND ORG_ID=1 AND ID!=1 AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.requestedEmployees = data['data'];
        this.requestedEmployeeLoading = false;
      }

    }, err => {
      this.requestedEmployeeLoading = false;

      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  assets = [];

  getAssets() {
    this.assets = [];
    this.ASSET_ID_FOR_FILTER = [];

    this.api.getAllAssets(0, 0, 'NAME', 'asc', "").subscribe(data => {
      if (data['code'] == 200) {
        this.assets = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }
}
