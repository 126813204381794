import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { AssetCategory } from 'src/app/Models/AssetCategory';
import { ApiService } from 'src/app/Service/api.service';
import { AssetCategoryDrawerComponent } from '../asset-category-drawer/asset-category-drawer.component';

@Component({
  selector: 'app-asset-category-list',
  templateUrl: './asset-category-list.component.html',
  styleUrls: ['./asset-category-list.component.css']
})

export class AssetCategoryListComponent implements OnInit {
  formTitle = "Manage Asset Category";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  assetCategoryData = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  columns: string[][] = [["PARENT_NAME", "Parent Category"], ["NAME", "Category"], ["SHORT_CODE", "Short Code"], ["IS_LAST", "Is Last"], ["STATUS", "Status"]];
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: AssetCategory = new AssetCategory();
  drawerVisible1: boolean;

  constructor(private api: ApiService, private cookie: CookieService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.getParentAssetCategory();
    this.search(true);
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    console.log("search text : " + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND (";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
    }

    var categoryFilterStr = "";
    if (this.PARENT_ID.length > 0) {
      categoryFilterStr = " AND PARENT_ID IN (" + this.PARENT_ID + ")";
    }

    this.loadingRecords = true;
    this.api.getAllAssetCategory(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + categoryFilterStr).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.assetCategoryData = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  @ViewChild(AssetCategoryDrawerComponent, { static: false }) AssetCategoryDrawerComponentVar: AssetCategoryDrawerComponent;

  add(): void {
    this.drawerTitle = "Add Asset Category";
    this.drawerData = new AssetCategory();
    this.drawerVisible = true;
    this.drawerData.STATUS = true;
    this.drawerData.IS_LAST = true;
    this.AssetCategoryDrawerComponentVar.getParentAssetCategory();
    this.AssetCategoryDrawerComponentVar.assetCategoryYesNo = false;
    this.AssetCategoryDrawerComponentVar.assetCategoryShortCodeYesNo = false;
  }

  edit(data: AssetCategory): void {
    this.drawerTitle = "Update Asset Category";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
    this.AssetCategoryDrawerComponentVar.getParentAssetCategory();
    this.AssetCategoryDrawerComponentVar.assetCategoryYesNo = true;
    this.AssetCategoryDrawerComponentVar.assetCategoryShortCodeYesNo = true;
  }

  drawerClose(): void {
    this.search();
    this.getParentAssetCategory();
    this.drawerVisible = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  isFilterApplied: string = "default";
  filterClass: string = "filter-visible";

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  PARENT_ID = [];
  categories = [];

  getParentAssetCategory() {
    this.categories = [];

    this.api.getAllAssetCategory(0, 0, 'NAME', 'asc', ' AND IS_LAST=0').subscribe(data => {
      if (data['code'] == 200) {
        this.categories = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  clearFilter() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.PARENT_ID = [];
    this.search(true);
  }

  applyFilter() {
    if (this.PARENT_ID.length > 0)
      this.isFilterApplied = "primary";

    else
      this.isFilterApplied = "default";

    this.search(true);
    this.filterClass = "filter-invisible";
  }

  onSearchBoxEnter() {
    document.getElementById("searchBtn").focus();
  }
}
