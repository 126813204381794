import { DatePipe } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { fileMaster } from 'src/app/Models/fileMaster';
import { folderMaster } from 'src/app/Models/folderMaster';
import { FolderSharingBreadcrum } from 'src/app/Models/folderSharingBreadcrum';
import { ApiService } from 'src/app/Service/api.service';
import { FileDrawerComponent } from '../../Folder/file-drawer/file-drawer.component';
import { SharingExistingFolderComponent } from '../../Folder/sharing-existing-folder/sharing-existing-folder.component';

@Component({
  selector: 'app-shared-folder-master',
  templateUrl: './shared-folder-master.component.html',
  styleUrls: ['./shared-folder-master.component.css']
})

export class SharedFolderMasterComponent implements OnInit {
  fsb: FolderSharingBreadcrum[] = [];
  fsbTemp: FolderSharingBreadcrum;
  fsbLast: FolderSharingBreadcrum;
  pageIndex = 1;
  pageSize = 8;
  totalRecords = 1;
  folderData = [];
  folderShareData = [];
  fileData = [];
  fileShareData = [];
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["NAME", "Folder Name"]];
  parentFolderID = 0;
  folderID = 0;
  backBtn = false;
  viewAddBtn = false;
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: folderMaster = new folderMaster();
  fileDrawerVisible: boolean;
  fileDrawerTitle: string;
  fileDrawerData: fileMaster = new fileMaster();
  shareDrawerVisible: boolean;
  shareDrawerTitle: string;
  shareDrawerData: folderMaster = new folderMaster();
  fileShareDrawerVisible: boolean;
  fileShareDrawerTitle: string;
  fileShareDrawerData: fileMaster = new fileMaster();
  drawerOpened = false;

  constructor(public api: ApiService, private message: NzNotificationService, private cookie: CookieService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.fsbTemp = new FolderSharingBreadcrum();
    this.fsbTemp.FOLDER_ID = 0;
    this.fsbTemp.NAME = "Shared Drive";
    this.fsbTemp.ACCESS_TYPE = "V";
    this.fsbTemp.SEQUENCE_NO = 0;
    this.fsbLast = Object.assign({}, this.fsbTemp);

    this.fsb.push(this.fsbTemp);
    this.openFoldersAndFiles(this.fsb[0]);
  }

  loadingFolders = false;
  loadingFiles = false;

  userId = Number(this.cookie.get('userId'));
  roleId = Number(this.cookie.get('roleId'));
  orgId = Number(this.cookie.get('orgId'));
  deptId = Number(this.cookie.get('deptId'));
  branchId = Number(this.cookie.get('branchId'));
  designationId = Number(this.cookie.get('designationId'));

  isAddVisible = false;
  isConfirmLoading = false;
  newFolderName = ""
  btnCreateName = ""
  myID = null;
  currentData: folderMaster;

  add(): void {
    this.btnCreateName = "Create";
    this.isAddVisible = true;
    this.currentData = new folderMaster();
    this.currentData.PARENT_ID = this.fsbLast.FOLDER_ID;
  }

  edit(data: any): void {
    this.btnCreateName = "Update";
    this.isAddVisible = true;
    this.currentData = Object.assign({}, data);
    this.currentData.ID = data.FOLDER_ID;
  }

  drawerClose(): void {
    this.drawerVisible = false;
    this.openFoldersAndFiles(this.fsbLast);
  }

  save(): void {
    var isOk = true;

    if (this.currentData.PARENT_ID == undefined) {
      this.currentData.PARENT_ID = 0;
    }

    if (this.currentData.NAME != undefined) {
      if (this.currentData.NAME.trim() != '') {
        if (!this.api.checkTextBoxIsValid(this.currentData.NAME)) {
          isOk = false;
          this.message.error("Please Enter Valid Folder Name", "");
        }
      } else {
        isOk = false;
        this.message.error("Please Enter Valid Folder Name", "");
      }
    } else {
      isOk = false;
      this.message.error("Please Enter Valid Folder Name", "");
    }

    if (isOk) {
      this.isConfirmLoading = true;
      this.currentData.CREATED_DATE_TIME = this.datePipe.transform(new Date(), "yyyy-MM-dd HH:mm") + ":00";

      if (!this.currentData.ID)
        this.currentData.OWNER_ID = this.api.userId;

      this.currentData.FOLDER_FILE = 'F';
      console.log(this.currentData);

      if (this.currentData.ID) {
        this.api.updateExistingMyFolder(this.currentData).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.isConfirmLoading = false;
            this.message.success("Folder Updated Successfully", "");
            this.openFoldersAndFiles(this.fsbLast);

          } else {
            this.message.error("Failed to Update Folder", "");
          }

        }, err => {
          if (err['ok'] == false)
            this.message.error("Failed to Update Folder", "");
        });

      } else {
        this.api.createNewMyFolder(this.currentData).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.isConfirmLoading = false;
            this.message.success("New Folder Added Successfully", "");
            this.openFoldersAndFiles(this.fsbLast);

          } else {
            this.message.error("Failed to Add New Folder", "");
          }

        }, err => {
          if (err['ok'] == false)
            this.message.error("Failed to Add New Folder", "");
        });
      }

      this.isAddVisible = false;
    }
  }

  handleCancel1() {
    this.isAddVisible = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  @ViewChild(FileDrawerComponent, { static: false }) FileDrawerComponentVar: FileDrawerComponent;

  addFile(): void {
    this.fileDrawerTitle = "Add New File";
    this.fileDrawerData = new fileMaster();
    this.fileDrawerVisible = true;
    this.drawerOpened = true;
    this.fileDrawerData.FOLDER_ID = this.fsbLast.FOLDER_ID;

    this.FileDrawerComponentVar.fileURL = null;
    this.FileDrawerComponentVar.tags = [];
    this.FileDrawerComponentVar.isSpinning = false;
    this.FileDrawerComponentVar.progressBar = false;
    this.FileDrawerComponentVar.percent = 0;
    this.FileDrawerComponentVar.stopInterval();
    this.FileDrawerComponentVar.getFolders();
  }

  editFile(data: fileMaster): void {
    this.fileDrawerTitle = "Edit File Details";
    this.fileDrawerData = Object.assign({}, data);
    this.fileDrawerVisible = true;
    this.drawerOpened = true;
  }

  fileDrawerClose(): void {
    this.fileDrawerVisible = false;
    this.openFoldersAndFiles(this.fsbLast);
  }

  get fileDrawerCloseCallback() {
    return this.fileDrawerClose.bind(this);
  }

  folderInfo = new folderMaster();
  @ViewChild(SharingExistingFolderComponent, { static: false }) SharingExistingFolderComponentVar: SharingExistingFolderComponent;

  openShareDrawer(data: any): void {
    this.folderInfo = new folderMaster();
    this.folderInfo.ID = data.FOLDER_ID;
    this.folderInfo.NAME = data.NAME;

    this.shareDrawerTitle = "Share > Folder > " + data.NAME;
    this.shareDrawerData = new folderMaster();
    this.shareDrawerVisible = true;
    this.drawerOpened = true;

    this.SharingExistingFolderComponentVar.ACCESS_TYPE = "E";
    this.SharingExistingFolderComponentVar.sharingMode = "I";
    this.SharingExistingFolderComponentVar.disableRadioButtons();

    this.folderShareData = [];
    this.api.getAllFolderSharigDetails(0, 0, '', '', " and EMPLOYEE_ID<>" + this.userId + " AND FOLDER_ID in (SELECT S.ID FROM ( (select id from (select * from folder_master order by PARENT_ID desc , id desc) products_sorted, (select @pv := " + data.FOLDER_ID + ") initialisation where find_in_set(id, @pv) and length(@pv := concat(@pv, ',', parent_id)))) S) and SHARING_TYPE=1").subscribe(data => {
      if (data['code'] == 200) {
        this.folderShareData = data['data'];
        this.SharingExistingFolderComponentVar.changeRadioButton('I');
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  shareDrawerClose(): void {
    this.shareDrawerVisible = false;
  }

  get shareDrawerCloseCallback() {
    return this.shareDrawerClose.bind(this);
  }

  fileInfo = new fileMaster();

  fileShareDrawerClose(): void {
    this.fileShareDrawerVisible = false;
  }

  get fileShareDrawerCloseCallback() {
    return this.fileShareDrawerClose.bind(this);
  }

  folderStr = "";

  openFolder(data: any) {
    this.pageSize = 8;
    this.folderStr = this.folderStr + ' > ' + data.NAME;
    this.backBtn = true;

    if (data.ACCESS_TYPE == 'E')
      this.viewAddBtn = true;

    this.fsbTemp = new FolderSharingBreadcrum();
    this.fsbTemp.FOLDER_ID = data.FOLDER_ID;
    this.fsbTemp.NAME = data.NAME;
    this.fsbTemp.ACCESS_TYPE = data.ACCESS_TYPE;
    this.fsbTemp.SEQUENCE_NO = this.fsb.length;
    this.fsbLast = Object.assign({}, this.fsbTemp);
    this.fsb.push(this.fsbTemp);
    this.openFoldersAndFiles(data);
  }

  btnLoadMoreStatus = false;

  openFoldersAndFiles(folderData) {
    this.selectedIndex1 = undefined;
    this.selectedIndex2 = undefined;
    this.loadingFolders = true;
    this.folderData = [];

    this.api.getAllSharedFolderDetails(this.pageIndex, this.pageSize, folderData.FOLDER_ID, this.userId, this.orgId, this.branchId, this.deptId, this.designationId, folderData.ACCESS_TYPE).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingFolders = false;
        this.totalRecords = data['count'];
        this.folderData = data['data'];

        if (this.totalRecords == this.folderData.length) {
          this.btnLoadMoreStatus = false;

        } else {
          this.btnLoadMoreStatus = true;
        }
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  openParentFolders() {
    this.pageSize = 8;

    this.fsb = this.fsb.slice(0, this.fsb.length - 1);
    this.fsbLast = Object.assign({}, this.fsb[this.fsb.length - 1]);

    var folderData = { 'FOLDER_ID': 0, 'ACCESS_TYPE': '' };

    folderData.FOLDER_ID = this.fsbLast.FOLDER_ID;
    folderData.ACCESS_TYPE = this.fsbLast.ACCESS_TYPE;

    this.openFoldersAndFiles(folderData);
  }

  openLeaf(varFsb) {
    this.pageSize = 8;

    this.fsb = this.fsb.slice(0, varFsb.SEQUENCE_NO + 1);
    this.fsbLast = Object.assign({}, this.fsb[this.fsb.length - 1]);

    var folderData = { 'FOLDER_ID': 0, 'ACCESS_TYPE': '' };

    folderData.FOLDER_ID = this.fsbLast.FOLDER_ID;
    folderData.ACCESS_TYPE = this.fsbLast.ACCESS_TYPE;

    this.openFoldersAndFiles(folderData);
  }

  timer: any;
  downloadedFileName: string = "";

  onDownloadBtnClick(fileData) {
    this.downloadedFileName = fileData.NAME;

    if (this.timer != undefined)
      this.timer.unsubscribe();

    this.downloadProgress = 0;
    this.isDownloadingProgressVisible = true;
    this.isProcessingProgressVisible = false;
    let fileID: number;

    if (fileData.ID == undefined)
      fileID = fileData.FOLDER_ID;

    else if (fileData.FOLDER_ID == undefined)
      fileID = fileData.ID;

    let thefile: any;
    this.timer = this.api.downloadFileWithProgress(fileData.OWNER_ID, fileData.ACCESS_TYPE, fileData.URL, fileID).subscribe(res => {
      if (res.type === HttpEventType.Response) {
        // console.log('Download complete');
        this.downloadProgress = 0;
        this.isDownloadingProgressVisible = false;

        if (res["body"]["code"] == 200) {
          var byteNumbers = new Array(res["body"]["data"].length);
          for (var i = 0; i < res["body"]["data"].length; i++) {
            byteNumbers[i] = res["body"]["data"].charCodeAt(i);
          }

          var byteArray = new Uint8Array(byteNumbers);
          thefile = new Blob([byteArray], { type: res["body"]["MIMETYPE"] });
          let url = URL.createObjectURL(thefile);
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.href = url;
          a.download = fileData.NAME;
          a.click();
          document.body.removeChild(a);
        }
      }

      if (res.type === HttpEventType.DownloadProgress) {
        const percentDone = Math.round(100 * res.loaded / res.total);
        // console.log('Download Progress ' + percentDone + '%');
        this.downloadProgress = percentDone;
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  onViewBtnClick(fileData) {
    if (this.timer != undefined)
      this.timer.unsubscribe();

    this.processingProgress = 0;
    this.isDownloadingProgressVisible = false;
    this.isProcessingProgressVisible = true;
    let fileID: number;

    if (fileData.ID == undefined)
      fileID = fileData.FOLDER_ID;

    else if (fileData.FOLDER_ID == undefined)
      fileID = fileData.ID;

    let thefile: any;
    this.timer = this.api.downloadFileWithProgress(fileData.OWNER_ID, fileData.ACCESS_TYPE, fileData.URL, fileID).subscribe(res => {
      if (res.type === HttpEventType.Response) {
        // console.log('Download complete');
        this.processingProgress = 0;
        this.isProcessingProgressVisible = false;

        if (res["body"]["code"] == 200) {
          var byteNumbers = new Array(res["body"]["data"].length);
          for (var i = 0; i < res["body"]["data"].length; i++) {
            byteNumbers[i] = res["body"]["data"].charCodeAt(i);
          }

          var byteArray = new Uint8Array(byteNumbers);

          thefile = new Blob([byteArray], { type: res["body"]["MIMETYPE"] });
          let url = URL.createObjectURL(thefile);
          window.open(url);
        }
      }

      if (res.type === HttpEventType.DownloadProgress) {
        const percentDone = Math.round(100 * res.loaded / res.total);
        // console.log('Download Progress ' + percentDone + '%');
        this.downloadProgress = percentDone;
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  getSize(size) {
    var s = Number(size) / 1000;

    if (Number(parseFloat(String(s)).toFixed(0)) > 0)
      return parseFloat(String(s)).toFixed(2) + " MB";

    else
      return parseFloat(String(size)).toFixed(0) + " KB";
  }

  isVisible = false;
  modalTitle1 = "";
  type = "";
  size = "";
  tags = [];
  description = "";
  ownerName = "";

  showModal(data): void {
    this.isVisible = true;
    this.modalTitle1 = data.NAME;
    this.type = data.TYPE;
    this.size = data.SIZE_KB;

    if (data.TAGS != null && data.TAGS != '') {
      this.tags = data.TAGS.split(',');

    } else {
      this.tags = [];
    }

    if (data.DESCRIPTION != null) {
      this.description = data.DESCRIPTION;
    }

    if (data.OWNER_ID == this.api.userId)
      this.ownerName = 'Me';
    else
      this.ownerName = data.OWNER_NAME;
  }

  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  selectedIndex1: number;
  selectedIndex2: number;

  selectDiv1(i) {
    this.selectedIndex1 = i;
    this.selectedIndex2 = undefined;
  }

  selectDiv2(j) {
    this.selectedIndex1 = undefined;
    this.selectedIndex2 = j;
  }

  searchPanel = false;
  searchData = [];
  loadingSearchData = false;

  onSearching() {
    if (this.searchText.length >= 3) {
      this.selectedIndex1 = undefined;
      this.searchPanel = true;
      this.loadingSearchData = true;
      this.searchData = [];

      this.api.getAllMyFoldersFilesOnSearchig(this.userId, this.deptId, this.branchId, this.designationId, this.searchText).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingSearchData = false;
          this.searchData = data['data'];
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });
    }
  }

  closeSearchPanel() {
    this.searchPanel = false;
    this.selectedIndex1 = undefined;
    this.searchText = "";
  }

  onLoadMoreClick() {
    this.pageSize = this.pageSize + 4;
    this.openFoldersAndFiles(this.fsb[this.fsb.length - 1]);
  }

  onEnterPress() {
    document.getElementById("saveFolderBtn").focus();
  }

  downloadProgress: number = 0;
  processingProgress: number = 0;
  isDownloadingProgressVisible: boolean = false;
  isProcessingProgressVisible: boolean = false;

  onDownloadCancel(): void {
    this.downloadProgress = 0;
    this.isDownloadingProgressVisible = false;
    if (this.timer != undefined)
      this.timer.unsubscribe();
  }

  cancel(): void { }
}
