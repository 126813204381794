import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { AssetCategory } from 'src/app/Models/AssetCategory';
import { ApiService } from 'src/app/Service/api.service';
import { AssetRegisterForAssetCategoryRegisterComponent } from '../asset-register-for-asset-category-register/asset-register-for-asset-category-register.component';

@Component({
  selector: 'app-asset-category-register',
  templateUrl: './asset-category-register.component.html',
  styleUrls: ['./asset-category-register.component.css']
})

export class AssetCategoryRegisterComponent implements OnInit {
  formTitle = "Asset Category Register";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  assetCategoryData = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  columns: string[][] = [["PARENT_NAME", "Parent Category"], ["NAME", "Category"], ["SHORT_CODE", "Short Code"], ["STATUS", "Status"]];

  constructor(private api: ApiService, private cookie: CookieService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.search(true);
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    console.log("search text : " + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND (";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
    }

    this.loadingRecords = true;
    this.api.getAllAssetCategory(this.pageIndex, this.pageSize, this.sortKey, sort, " AND IS_LAST=1" + likeQuery).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.assetCategoryData = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  drawerClose(): void {
    this.drawerVisible = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  onSearchBoxEnter() {
    document.getElementById("searchBtn").focus();
  }

  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: AssetCategory = new AssetCategory();
  @ViewChild(AssetRegisterForAssetCategoryRegisterComponent, { static: false }) AssetRegisterForAssetCategoryRegisterComponentVar: AssetRegisterForAssetCategoryRegisterComponent;

  viewAssetReport(data: AssetCategory) {
    console.log(data);
    this.drawerVisible = true;
    this.drawerTitle = "Asset Category > " + data.NAME;
    this.AssetRegisterForAssetCategoryRegisterComponentVar.assetRegisterReportSearchText = "";
    this.AssetRegisterForAssetCategoryRegisterComponentVar.ASSET_CATEGORY_ID_Filter = data.ID;
    this.AssetRegisterForAssetCategoryRegisterComponentVar.clearFilter();
  }
}
