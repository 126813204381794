import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { PayrollItemMaster } from 'src/app/Models/payrollitem';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-addpayrollitem',
  templateUrl: './addpayrollitem.component.html',
  styleUrls: ['./addpayrollitem.component.css']
})
export class AddpayrollitemComponent implements OnInit {

  @Input() drawerClose!: Function;
  @Input() data: PayrollItemMaster = new PayrollItemMaster;
  @Input() drawerVisible: boolean = false;
  isSpinning = false;
  isOk = true;
  emailpattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  namepatt = /[a-zA-Z][a-zA-Z ]+/;
  mobpattern = /^[6-9]\d{9}$/;

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit(): void { }

  alphaOnly(event: any) {
    event = (event) ? event : window.event;
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 32 && (charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
      return false;
    }

    return true;
  }

  close(payrollitemPage: NgForm): void {
    this.drawerClose();
    payrollitemPage.form.markAsPristine();
    payrollitemPage.form.markAsUntouched();
  }

  omit(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  resetDrawer(payrollitemPage: NgForm) {
    this.data = new PayrollItemMaster();
    payrollitemPage.form.markAsPristine();
    payrollitemPage.form.markAsUntouched();
  }

  setType(event: any) {
    console.log('event:', event);
  }

  totalAmount(event) {
    if (this.data.BASED_ON == undefined || this.data.BASED_ON == null) {
      this.message.error(' First Select The Based On, Then Enter Percentage.', '');

    } else {
      var amount = (event / 100) * this.data.BASED_ON
      console.log('amount', amount);
      this.data.AMOUNT = amount
    }
  }

  save(addNew: boolean, payrollitemPage: NgForm): void {
    this.isSpinning = false;
    this.isOk = true;

    if (this.data.PARENT_ID == undefined && this.data.NAME.trim() == ""
      && this.data.TYPE == undefined
      && this.data.AMOUNT == undefined) {
      this.isOk = false
      this.message.error(" Please Fill All Requird Fields", "");

    } else
      if (this.data.PARENT_ID == undefined || this.data.PARENT_ID == null) {
        this.isOk = false
        this.message.error(' Please Select Parent Name', '');

      } else
        if (this.data.NAME == null || this.data.NAME.trim() == '') {
          this.isOk = false
          this.message.error(' Please Enter Item Name', '');

        } else
          if (this.data.TYPE == undefined || this.data.TYPE == null) {
            this.isOk = false
            this.message.error('Please Select Type ', '');

          } else
            if (this.data.TYPE == 'B' && (this.data.BASED_ON == undefined || this.data.BASED_ON == null)) {
              this.isOk = false
              this.message.error('Please Select Based On Type ', '');

            } else
              if (this.data.TYPE == 'B' && (this.data.PERCENT == undefined || this.data.PERCENT == null)) {
                this.isOk = false
                this.message.error('Please Enter Percentage ', '');

              } else
                if (this.data.AMOUNT == undefined || this.data.AMOUNT == null) {
                  this.isOk = false
                  this.message.error('Please Enter Amount', '');
                }

    if (this.isOk) {
      this.isSpinning = true;

      if (this.data.ID) {
        this.api.updatePayrollItem(this.data).subscribe(successCode => {
          if (successCode.code == "200") {
            this.message.success(" Information Updated Successsfully...", "");

            if (!addNew)
              this.drawerClose();
            this.isSpinning = false;

          } else {
            this.message.error(" Failed To Update Information...", "");
            this.isSpinning = false;
          }
        });

      } else {
        this.api.createPayrollItem(this.data).subscribe(successCode => {
          if (successCode.code == "200") {
            this.message.success(" Information Save Successfully...", "");

            if (!addNew)
              this.drawerClose();

            else {
              this.data = new PayrollItemMaster();
              this.resetDrawer(payrollitemPage);

              // this.data.STATUS==true;
              // this.api.getAllPostMaster(1,1,'SEQ_NO','desc','').subscribe (data =>{
              //   if (data['count']==0){
              //     this.data.SEQ_NO=1;
              //   }else
              //   {
              //     this.data.SEQ_NO=data['data'][0]['SEQ_NO']+1;
              //   }
              // },err=>{
              //   console.log(err);
              // })
            }

            this.isSpinning = false;

          } else {
            this.message.error(" Failed To Save Information...", "");
            this.isSpinning = false;
          }
        });
      }
    }
  }
}
