import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ClusterMaster } from 'src/app/Models/clustermaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-addclustermaster',
  templateUrl: './addclustermaster.component.html',
  styleUrls: ['./addclustermaster.component.css'],
  providers: [DatePipe]
})

export class AddclustermasterComponent implements OnInit {
  @Input() drawerClose: Function;
  // @Input() data: ClusterMaster;
  @Input()
  data: ClusterMaster = new ClusterMaster();
  orgId = this.cookie.get('orgId')
  isSpinning = false
  @Input() listOfData: ClusterMaster[] = [];
  OPEN_TIME
  CLOSE_TIME
  listdata2 = []
  listdata1 = []
  // @Input() OPEN_TIME2
  // @Input() CLOSE_TIME2
  // fKey = ""
  acctype = []
  date = new Date();
  date1 = this.datePipe.transform(this.date, 'yyyyMMddHHmmss')
  // fileDataLOGO_URL: File = null
  // folderName = "departmentIcon"
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  @Input() DAYS = false;
  isOk = true;
  namepattern = /[a-zA-Z][a-zA-Z ]+/
  regexp = /^\S/
  namepatte = /^[A-Za-z ]*$/
  spatt = /^[a-zA-Z0-9 ]+$/
  // acctype:AccountTypeMaster[]=[];

  constructor(private api: ApiService, private cookie: CookieService, private datePipe: DatePipe, private message: NzNotificationService) {
  }

  ngOnInit() {
    this.getallorg1();

    this.api.getAllOrganizations(this.pageIndex, this.pageSize, this.sortKey, '', '').subscribe(data => {
      this.loadingRecords = false;
      this.acctype = data['data'];

    }, err => {
      console.log(err);
    });
  }

  close(accountMasterPage: NgForm) {
    this.drawerClose();
    this.resetDrawer(accountMasterPage);
  }

  resetDrawer(accountMasterPage: NgForm) {
    accountMasterPage.form.reset();
    this.add();
  }

  add(): void {
    this.api.getAllclusterMaster(1, 1, 'SEQUENCE_NO', 'desc', ' and STATUS=1   AND  ORG_ID =' + this.orgId).subscribe(data => {
      if (data['count'] == 0) {
        this.data.SEQUENCE_NO = 1;

      } else {
        this.data.SEQUENCE_NO = Number(data['data'][0]['SEQUENCE_NO']) + 1;
        this.data.STATUS = true;
      }
    }, err => {
      console.log(err);
    })
  }

  designation() {
    this.api.getAllclusterMaster(this.pageIndex, this.pageSize, this.sortKey, '', ' and STATUS=1 AND ORG_ID =' + this.orgId).subscribe(data => {
      console.log(data['data']);
      this.loadingRecords = false;
      this.dataList = data['data'];

    }, err => {
      console.log(err);
    });
  }

  getallorg1() {
    this.api.getAllclusterMaster(0, 0, 'ID', 'desc', ' AND ORG_ID =' + this.orgId).subscribe(data => {
      if (data['code'] == 200) {
        this.listdata1 = data['data'];
        console.log(this.listdata1);
      }

    }, err => {
      console.log(err);
    });
  }

  getallorg2(clusterID) {
    this.api.getAllclusterMaster(0, 0, 'ID', 'desc', ' AND ORG_ID =' + this.orgId + " AND ID!=" + clusterID).subscribe(data => {
      if (data['code'] == 200) {
        this.listdata2 = data['data'];
        console.log(this.listdata2);
      }

    }, err => {
      console.log(err);
    });
  }

  save(addNew: boolean, accountMasterPage: NgForm): void {
    this.isSpinning = false;
    this.data.ORG_ID = Number(this.cookie.get('orgId'))
    this.isOk = true;

    if (
      this.data.ORG_ID == 0 &&
      this.data.NAME.trim() == '' &&
      this.data.SHORT_CODE.trim() == '' &&
      this.data.SEQUENCE_NO == undefined
    ) {
      this.isOk = false;
      this.message.error('Please Fill All Required Information', '');

    } else if (this.data.ORG_ID == undefined || this.data.ORG_ID <= 0) {
      this.isOk = false;
      this.message.error('Please Enter Organization ID', '');

    } else if (this.data.NAME == undefined || this.data.NAME.trim() == '') {
      this.isOk = false;
      this.message.error("Please Enter Name", '');

    } else if (!this.namepatte.test(this.data.NAME.toString())) {
      this.isOk = false;
      this.message.error("Please Enter Valid Name", '');

    } else if (this.data.SHORT_CODE == null || this.data.SHORT_CODE.trim() == '') {
      this.isOk = false;
      this.message.error('Please Enter Short Code', '');

    } else if (!this.spatt.test(this.data.SHORT_CODE.toString())) {
      this.isOk = false;
      this.message.error("Please Enter Valid Short Code", '');

    } else if (this.isOk) {
      this.isSpinning = true;

      if (this.data.ID) {
        var emailData2 = this.listdata2.filter(obj => {
          return (obj.SHORT_CODE == this.data.SHORT_CODE);
        });

        if (emailData2.length == 0) {
          this.api.updateclusterMaster(this.data).subscribe(successCode => {
            if (successCode['code'] == 200) {
              this.message.success("Cluster Information Updated Successfully", "");

              if (!addNew)
                this.drawerClose();

              this.resetDrawer(accountMasterPage);
              this.isSpinning = false;

            } else {
              this.message.error("Cluster Information Updation Failed", "");
              this.isSpinning = false;
            }
          });

        } else {
          this.message.error("Shortcode Exist Please Enter Other Shortcode", "");
          this.isSpinning = false;
        }

      } else {
        var emailData = this.listdata1.filter(obj => {
          return obj.SHORT_CODE == this.data.SHORT_CODE;
        });

        if (emailData.length == 0) {
          this.api.createclusterMaster(this.data).subscribe(successCode => {
            if (successCode['code'] == 200) {
              this.isSpinning = false;
              this.message.success("Cluster Information Created Successfully", "");
              this.designation();

              if (!addNew) {
                this.drawerClose();
                this.resetDrawer(accountMasterPage);

              } else {
                this.data = new ClusterMaster();
                this.resetDrawer(accountMasterPage);
              }

            } else {
              this.message.error("Cluster Information Creation Failed", "");
              this.isSpinning = false;
            }
          });

        } else {
          this.message.error("Shortcode Exist Please Enter Other Shortcode", "");
          this.isSpinning = false;
        }
      }
    }
  }
}
