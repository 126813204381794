import { Component, OnInit, ViewChild } from '@angular/core';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { LeaveType } from 'src/app/Models/leave-type';
import { ApiService } from 'src/app/Service/api.service';
import { LeavetypeComponent } from '../leavetype/leavetype.component';

@Component({
  selector: 'app-leavetypes',
  templateUrl: './leavetypes.component.html',
  styleUrls: ['./leavetypes.component.css']
})

export class LeavetypesComponent implements OnInit {
  formTitle = "Manage LeaveTypes";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["NAME", "Name"], ["TITLE", "Notification Tiltle"], ["NOTIFICATION_DESCRIPTION", "Notification Description"], ["DESCRIPTION", "Description"], ["STATUS", "Status"]];
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: LeaveType = new LeaveType();

  constructor(private api: ApiService) { }

  ngOnInit() {
    this.search();
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    if (this.searchText != "") {
      var likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
    }

    this.api.getAllLeaveType(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  @ViewChild(LeavetypeComponent, { static: false }) LeavetypeComponentVar: LeavetypeComponent;

  add(): void {
    this.drawerTitle = "New Leave Type";
    this.drawerData = new LeaveType();
    this.drawerData.IS_ACTIVE = true;
    this.drawerVisible = true;
    this.LeavetypeComponentVar.setValue();
  }

  edit(data: LeaveType): void {
    this.drawerTitle = "Update Leave Type";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
    this.drawerData.LEAVE_APPROVAL_DAYS = String(this.drawerData.LEAVE_APPROVAL_DAYS);
    this.LeavetypeComponentVar.setValue();
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }
}
