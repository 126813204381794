export class Asset {
    ID: number
    CLIENT_ID: number
    NAME: string
    CATEGORY_ID: number
    ASSET_CODE: string
    EXPIRY_DATE: any
    STATUS: string
    DESCRIPTION: string
    PURCHASE_DATE: any
    ALLOCATED_EMPLOYEE_ID: any
    MAINTENANCE_FREQUENCY: string
    MAINTENANCE_FREQUENCY_TYPE: string
    LAST_MAINTENANCE_DATE: string;
    NEXT_MAINTENANCE_DATE: string
}