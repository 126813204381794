// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,

  firebase: {
    apiKey: "AIzaSyCDl0c57XoWrqOApt-GDjGbV-L1iD58lco",
    authDomain: "hrms-uvtechsoft.firebaseapp.com",
    projectId: "hrms-uvtechsoft",
    storageBucket: "hrms-uvtechsoft.appspot.com",
    messagingSenderId: "525311089274",
    appId: "1:525311089274:web:209ed23c37f61cdcbcb9c3",
    measurementId: "G-P8EET3NV79",
    appVersion1: require('../../package.json').version + '-dev',
    appVersion2: require('../../package.json').version,
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
